<template>
    <v-card class="ma-4" elevation="0">

        <v-toolbar color="primary" dark elevation="5">
            <v-btn icon class="mr-4">
                <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-toolbar-title>Export</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-excel-outline</v-icon>
                        Beiträge | EXCEL
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>
                        Export aller Beiträge für {{ project.name }} im CSV Format zur weiteren
                        Verwendung in Excel.
                    </p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_csv"
                           @click="downloadCsv"
                    >
                        <v-icon
                                left
                                dark
                        >
                            mdi-file-export-outline
                        </v-icon>
                        CSV Export
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-excel-outline</v-icon>
                        Beiträge Vollständig | EXCEL
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>
                        Export aller Beiträge, die dem Projekt {{ project.name }} ({{ project.year }}) zugordnet sind und alle Beiträge, die noch keinem Projekt zugeordnet sind.
                        Datei im CSV Format zur weiteren Verwendung in Excel.
                    </p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_csv_full"
                           @click="downloadFullCsv"
                    >
                        <v-icon
                                left
                                dark
                        >
                            mdi-file-export-outline
                        </v-icon>
                        CSV Export
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                        Stammdaten Autoren | CSV
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>Export aller Schreiber im CSV Format inklusive des Kürzels und der Anzahl
                        der beigetragenen Beiträge.</p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_csv_writer"
                           @click="downloadCsvWriters"
                    >
                        <v-icon left dark>
                            mdi-file-export-outline
                        </v-icon>
                        CSV - Schreiber
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                        Stammdaten Themen | CSV
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>Export aller Themenbereiche im CSV Format mit dem jeweiligen Datum im
                        Format 'TT.MM.' sowie mit ausgeschriebenem Monat.</p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_csv_topics"
                           @click="downloadCsvTopics"
                    >
                        <v-icon left dark>
                            mdi-file-export-outline
                        </v-icon>
                        CSV - Themen
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                        Stammdaten Bibelstellen | CSV
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>Export aller Bibelstellen im CSV Format mit dem jeweiligen Datum im
                        Format 'TT.MM.' sowie mit ausgeschriebenem Monat.</p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_csv_bible_refs"
                           @click="downloadCsvBibleRefs"
                    >
                        <v-icon
                                left
                                dark
                        >
                            mdi-file-export-outline
                        </v-icon>
                        CSV - Bibelstellen
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <div>
                        <v-icon color="grey darken-1" left>mdi-file-code-outline</v-icon>
                        Satzdaten | XML
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p>
                        Export der kompletten Satzdaten des ganzen Projektes '{{ project.name
                        }}' im XML Format.
                    </p>
                    <v-btn class="ma-2"
                           color="primary"
                           elevation="2"
                           raised
                           :loading="btn_loading_xml"
                           @click="downloadXml"
                    >
                        <v-icon
                                left
                                dark
                        >
                            mdi-file-export-outline
                        </v-icon>
                        XML Export
                    </v-btn>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    const ProjectsRepository = RepositoryFactory.get('projects');

    export default {
        name: "WidgetProjectDownload",
        props: {
            project: Object,
            currentProject: Number,
        },
        data() {
            return {
                btn_loading_xml: false,
                btn_loading_csv: false,
                btn_loading_csv_full: false,
                btn_loading_csv_writer: false,
                btn_loading_csv_topics: false,
                btn_loading_csv_bible_refs: false,
            }
        },
        methods: {
            downloadXml() {
                this.btn_loading_xml = true;
                ProjectsRepository.export_xml(this.currentProject).then((response) => {
                    this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Gesamt.xml');
                    this.btn_loading_xml = false;
                });
            },
            downloadCsv() {
                this.btn_loading_csv = true;
                ProjectsRepository.export_csv(this.currentProject)
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Beitraege.csv');
                        this.btn_loading_csv = false;
                    });
            },
            downloadFullCsv() {
                this.btn_loading_csv_full = true;
                ProjectsRepository.export_articles_project_and_unassigned(this.currentProject)
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Beitraege-Gesamt.csv');
                        this.btn_loading_csv_full = false;
                    });
            },
            downloadCsvWriters() {
                this.btn_loading_csv_writer = true;
                ProjectsRepository.export_csv_writers(this.currentProject)
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Index-Schreiber.csv');
                        this.btn_loading_csv_writer = false;
                    });
            },
            downloadCsvTopics() {
                this.btn_loading_csv_topics = true;
                ProjectsRepository.export_csv_topics(this.currentProject)
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Index-Themen.csv');
                        this.btn_loading_csv_topics = false;
                    });
            },
            downloadCsvBibleRefs() {
                this.btn_loading_csv_bible_refs = true;
                ProjectsRepository.export_csv_bible_refs(this.currentProject)
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-' + this.project.year + '-Index-Bibelstellen.csv');
                        this.btn_loading_csv_bible_refs = false;
                    });
            },
            downloadBlob(blob, filename) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([blob])
                );
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        }
    }
</script>

<style scoped>

</style>
