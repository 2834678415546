export default  {
    computed: {
        isLoggedIn: function () {
            return this.$store.getters.isLoggedIn
        },
        isAuthor: function () {
            return this.$store.getters.isAuthor
        },
        isEditor: function () {
            return this.$store.getters.isEditor
        },
        isAdmin: function () {
            return this.$store.getters.isAdmin
        }
    },
}