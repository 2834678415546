import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import store from '../store/index';
import AppSettings from '@/config/AppSettings'
import {RepositoryFactory} from '@/repositories/RepositoryFactory';
const ProfileRepository = RepositoryFactory.get('profile');
const UserRepository = RepositoryFactory.get('users');
const ProjectRepository = RepositoryFactory.get('projects');
const BibleRepository = RepositoryFactory.get('bible');

Vue.use(Vuex);

const currentProjectMeta = localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'currentProjectMeta')

export default new Vuex.Store({
    state: {
        status: '',
        access: localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access') || '',
        refresh: localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'refresh') || '',
        user: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'user')) || {},
        redirect: false,
        permission: {
            isAuthor: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAuthor')) || false,
            isEditor: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isEditor')) || false,
            isAdmin: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAdmin')) || false,
        },
        snackbar: {
            snack: '',
        },
        loginFlag: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'LoginFlag')) || true,
        currentProjectMeta: (currentProjectMeta !== 'undefined' && currentProjectMeta ) ? JSON.parse(currentProjectMeta) : false,
        tableSettings: {},
        lastArticleSearchResult: [],
        bibleBooks: JSON.parse(localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'bibleBooks')) || [],
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading';
        },
        auth_error(state) {
            state.status = 'error';
        },
        auth_success(state, payload) {
            state.status = 'success';
            state.access = payload.access;
            state.refresh = payload.refresh;
            state.loginFlag = true;
        },
        refresh_error(state) {
            state.status = 'error';
        },
        refresh_request(state) {
            state.status = 'loading';
        },
        refresh_success(state, access) {
            state.status = 'success';
            state.access = access;
        },
        user_success(state, data) {
            state.user = data;
        },
        permission_success(state, data) {
            state.permission.isAuthor = data.isAuthor;
            state.permission.isEditor = data.isEditor;
            state.permission.isAdmin = data.isAdmin;
            state.status = 'ready';
        },
        logout(state) {
            state.status = '';
            state.access = '';
            state.refresh = '';
            state.permission = {isAdmin: false, isEditor: false, isAuthor: false};
            state.user = {};
            state.currentProjectMeta = {};
            state.tableSettings = {};
        },
        setRedirect(state, url) {
            state.status = 'redirect_set';
            state.redirect = url;
        },
        setSnack (state, payload) {
            state.snackbar.snack = payload.message;
            state.snackbar.datetime = payload.datetime;
        },
        setProject (state, project) {
            state.user.current_project = project;
        },
        setProjectMeta (state, project) {
            state.currentProjectMeta = project;
        },
        resetProject (state) {
            state.user.current_project = null;
        },
        updateTheme (state, darkMode) {
            state.user.dark_mode = darkMode;
        },
        updateLoginFlag (state, value) {
            state.loginFlag = value;
        },
        updateTableSettings (state, value) {
            state.status = 'settings_stored';
            state.tableSettings = { ...state.tableSettings, [value.path]: value.settings };
        },
        setLastArticleSearchResult (state, value) {
            state.status = 'result_stored';
            state.lastArticleSearchResult = value;
        },
        storeBibleBooks (state, value) {
            state.status = 'bibleBooks_stored';
            state.bibleBooks = value;
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({url: process.env.VUE_APP_API_ENDPOINT + '/api/token/', data: user, method: 'POST'})
                    .then(resp => {
                        const access = resp.data.access;
                        const refresh = resp.data.refresh;
                        localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access', access);
                        localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'refresh', refresh);
                        axios.defaults.headers.common['Authorization'] = access;
                        commit('auth_success', {access: access, refresh: refresh});
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('auth_error');
                        localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access');
                        localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'refresh');
                        reject(err)
                    })
            })
        },
        getUserData({commit, dispatch}) {
            ProfileRepository.get()
                .then(resp => {
                    let userData = resp.data;
                    let permission = {};
                    permission.isAdmin = userData.groups.includes(1);
                    permission.isEditor = userData.groups.includes(2) || permission.isAdmin;
                    permission.isAuthor = userData.groups.includes(3) || permission.isEditor || permission.isAdmin;
                    localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'user', JSON.stringify(userData));
                    localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAdmin', permission.isAdmin);
                    localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isEditor', permission.isEditor);
                    localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAuthor', permission.isAuthor);
                    commit('user_success', userData);
                    commit('permission_success', {
                        isAdmin: permission.isAdmin,
                        isEditor: permission.isEditor,
                        isAuthor: permission.isAuthor,
                    });

                    if( permission.isAdmin || permission.isEditor) {
                        dispatch('setProjectMeta', userData.current_project, false);
                    }

                    dispatch('storeBibleData');

                    let d = new Date();
                    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
                    let expires = "expires=" + d.toUTCString();
                    document.cookie =
                        AppSettings.LOCAL_STORAGE_PREFIX + "_DarkMode=" + userData.dark_mode + ";" + expires + ";path=/";
                })
                .catch(err => {
                    localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'user');
                    localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'permission');
                    throw err;
                })
        },
        refresh({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                commit('refresh_request');
                let data = { refresh : store.state.refresh };
                axios({url: process.env.VUE_APP_API_ENDPOINT + '/api/token/refresh/', data, method: 'POST'})
                    .then(resp => {
                        const access = resp.data.access;
                        localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access', access);
                        commit('refresh_success', access);
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('refresh_error');
                        dispatch('clearLocalStorage');
                        reject(err)
                    })
            })
        },
        logout({commit, dispatch}) {
            return new Promise((resolve) => {
                commit('logout');
                dispatch('clearLocalStorage');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            })
        },
        setRedirect({commit}, url) {
            if(!store.state.redirect || url === false) {
                commit('setRedirect', url);
            }
        },
        clearLocalStorage() {
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'refresh');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'user');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'permission');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAdmin');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isAuthor');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'isEditor');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'loginFlag');
            localStorage.removeItem(AppSettings.LOCAL_STORAGE_PREFIX + 'currentProjectMeta');
        },
        setMessage({commit}, message) {
            let currentDate = new Date();
            commit('setSnack', {datetime: currentDate, message: message});
        },
        setProject({commit, dispatch}, project){
            let projectID = (project) ? project.id : null;
            UserRepository.updateProject(store.state.user.id, projectID)
                .then( response => {
                        commit('setProject', response.data.current_project);
                        dispatch('setProjectMeta', false, project);
                        dispatch('getUserData');

                    }
                );
        },
        setProjectMeta({commit}, projectID, project){
            if (!project && projectID) {
                ProjectRepository.getById(projectID)
                    .then( response => {
                            commit('setProjectMeta', response.data);
                            localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'currentProjectMeta', JSON.stringify(response.data));
                        }
                    );
            } else {
                commit('setProjectMeta', project);
                localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'currentProjectMeta', JSON.stringify(project));
            }

        },
        resetProject({commit}){
            UserRepository.updateProject(this.$store.state.user.id, null)
                .then(
                    commit('resetProject'),
                    commit('setProjectMeta', false),
                    this.dispatch('getUserData'),
                );
        },
        setLoginFlag({commit}, value){
            localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'LoginFlag', value);
            commit('updateLoginFlag', value);
        },
        updateTheme({commit}, darkMode){
            let d = new Date();
            d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
            let expires = "expires=" + d.toUTCString();
            document.cookie =
                AppSettings.LOCAL_STORAGE_PREFIX + "_DarkMode=" + darkMode + ";" + expires + ";path=/";
            commit('updateTheme', darkMode);
            this.dispatch('getUserData');
        },
        setTableSettings({commit}, values){
            commit('updateTableSettings', values);
        },
        lastArticleSearchResult({commit}, values){
            commit('setLastArticleSearchResult', values);
        },
        async storeBibleData({commit}){
            const {data} = await BibleRepository.getBooks();
            localStorage.setItem(AppSettings.LOCAL_STORAGE_PREFIX + 'bibleBooks', JSON.stringify(data));
            commit('storeBibleBooks', data);
        }
    }
    ,
    modules: {},
    getters: {
        isLoggedIn: state => !!state.access,
        isAuthor: state => !!state.permission.isAuthor,
        isEditor: state => !!state.permission.isEditor,
        isAdmin: state => !!state.permission.isAdmin,
        authStatus: state => state.status,
        lastMessage: state => state.snackbar.datetime,
        currentProject: state => state.user.current_project,
        currentProjectMeta: state => state.currentProjectMeta,
        loginFlag: state => state.loginFlag,
    },
})
