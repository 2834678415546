<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Bibelübersetzungen</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Übersetzung erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="translations"
                        :items-per-page="15"
                        :search="search"
                >
                    <template v-slot:top>
                        <DialogBibleTranslation
                                :dialog="dialog"
                                :editedItem="editedItem"
                                :formTitle="formTitle"
                                :editedIndex="editedIndex"
                                @saved="saved"
                                @close="close"
                        />
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-icon class="mr-2" @click="editItem(item)">
                            mdi-square-edit-outline
                        </v-icon>
<!--                        <v-icon @click="deleteItem(item)" >-->
<!--                            mdi-delete-outline-->
<!--                        </v-icon>-->

                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogBibleTranslation from "@/components/settings/DialogBibleTranslation";

    const BibleTranslationsRepository = RepositoryFactory.get('bible_translations');

    export default {
        name: "Churches",
        components: {
            MenuSetDefault,
            DialogBibleTranslation,
        },
        props: {
            source: String,
        },
        data() {
            return {
                isShowForm: false,
                name: '',
                name_full: '',
                search: '',
                translations: [],
                loading: true,
                headers: [
                    {text: 'Übersetzung', value: 'name'},
                    {text: 'Voller Name', value: 'name_full'},
                    {text: 'Aktionen', value: 'id', align: 'right'},
                ],
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    name: '',
                    name_full: '',
                },
                defaultItem: {
                    id: null,
                    name: '',
                    name_full: '',
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await BibleTranslationsRepository.get();
                this.translations = data;
                this.loading = false;
            },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.translations.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.translations[this.editedIndex], editedItem)
                } else {
                    this.translations.push(editedItem);
                }
                this.close()
            },
            deleteItem (item) {
                this.editedIndex = this.translations.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.translations.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Neue Bibelübersetzung anlegen' : 'Bibelübersetzung bearbeiten'
            },
        },
        created() {
            this.fetch();
        },
    }
</script>
