<template>
    <v-dialog v-model="dialogRemove" max-width="350px" @click:outside="closeDelete" @keydown.esc="closeDelete">
        <v-card v-if="dialogRemove">
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>
                Dieser Eintrag "{{ article.title }}" wird nach der Bestätigung aus dem aktuellen Projekt ( {{ project.year }} ) entfernt und kann neu zugeordnet werden.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="deleteItemConfirm">Entfernen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "DialogRemoveArticleProject",
        props: {
            dialogRemove: Boolean,
            article: Object,
            dialogTitle: String,
            project: Object,
        },
        methods: {
            async deleteItemConfirm () {
                if (this.article.assigned_date) {
                    await this.deleteDate();
                }
                const response = await ArticlesRepository.setProject(this.article.id, null);
                if (response.status === 200) {
                    await this.$store.dispatch('setMessage', 'Der Beitrag "' + this.article.title + '" wurde aus dem Projekt entfernt.');
                    this.$emit('removeArticleConfirm', response.data);
                } else {
                    await this.$store.dispatch('setMessage', 'Es ist ein Fehler aufgetreten.');
                    this.$emit('closeDelete');
                }
            },
            async deleteDate () {
                const response = await ArticlesRepository.setAssignedDate(this.article.id, null);
                return response.status === 200;
            },
            closeDelete () {
                this.$emit('closeDelete');
            }
        }
    }
</script>

<style scoped>

</style>
