<template>
    <div class="text-center">
        <v-progress-circular
                v-if="loading"
                class="my-10"
                :size="50"
                color="primary"
                indeterminate
        ></v-progress-circular>
        <div v-if="!loading">
        <v-select
                v-model="filter_selected"
                :items="filter_fields"
                menu-props="auto"
                label="Filter wählen"
                hide-details
                prepend-icon="mdi-filter"
                single-line
                solo
                dense
                class="mb-4 elevation-0"
        ></v-select>
        <v-data-table
                      :headers="headers"
                      :items="histories_filtered"
                      :items-per-page="15"
                      :search="search"
                      :single-expand="true"
                      :expanded.sync="expanded"
                      show-expand
        >

            <template v-slot:item.created_at="{ item }">
                <span class="text-no-wrap">{{ item.created_at | luxon("DD T")}}</span>
            </template>
            <template v-slot:item.user_data.last_name="{ item }">
                {{ item.user_data.last_name }}, {{item.user_data.first_name}}
            </template>
            <template v-slot:item.is_initial="{ item }">
                <v-icon @click:row="(item, slot) => slot.expand(!slot.isExpanded)" small v-if="item.is_initial"
                        color="green darken-1">mdi-check-circle
                </v-icon>
            </template>
            <template v-slot:item.output_field="{ item }">

                <div v-if="item.field === 'text' || item.field === 'votd'">

                    <span><i>{{ item.output_field }}</i>: </span>
                    <span v-if="item.content<8">{{ item.content }}</span>
                    <span v-else>{{ item.content.substring(0,100)+"..." }}</span>
<!--                    <v-card>-->
<!--                        <v-card-text v-html="item.content" class="text font-weight-medium"></v-card-text>-->
<!--                    </v-card>-->
                </div>
                <span v-else><i>{{ item.output_field }}</i> &rArr; <b>{{ item.output_value }}</b></span>
            </template>
            <template v-slot:expanded-item="{ headers, item }" class="elevation-0">
                <td :colspan="headers.length" class="elevation-0">
                    <ArticleHistoryDetail :article="article" :history="item"/>
                </td>
            </template>
            <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                <v-btn
                        v-if="!item.is_initial"
                        @click.stop="expand(!isExpanded)"
                        class="mx-2"
                        elevation="0"
                        primary
                        small>
                    Details
                    <v-icon
                            :class="['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]"

                    >$expand
                    </v-icon>
                </v-btn>

            </template>
        </v-data-table>
        </div>
    </div>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import ArticleHistoryDetail from '@/components/articles/HistoryDetail'

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticleHistory",
        components: {
            ArticleHistoryDetail,
        },
        props: {
            article: Object,
            source: String,
            dialog: Boolean,
        },
        data() {
            return {
                search: '',
                headers: [
                    {text: 'Datum', value: 'created_at', align: 'end', sortable: false, width: "120px"},
                    {text: 'Benutzer', value: 'user_data.last_name', sortable: false, width: "200px"},
                    {text: 'Anlage', value: 'is_initial', sortable: false},
                    {text: 'Ergebnis', value: 'output_field', sortable: false},
                    {text: 'Details', value: 'data-table-expand'},
                ],
                expanded: [],
                histories: [],
                histories_filtered: [],
                loading: true,
                filter_selected: false,
                filter_fields: [
                    {text: 'Ungefiltert', value: false},
                    {text: 'Status', value: "status"},
                    {text: 'Überschrift', value: "title"},
                    {text: 'Text', value: "text"},
                    {text: 'Frage', value: "question"},
                    {text: 'Tipp', value: "hint"},
                    {text: 'Autor', value: "fk_writer"},
                    {text: 'Themenbereich', value: "fk_topic"},
                    {text: 'Tagesvers', value: "votd"},
                    {text: 'Tagesvers Bibelstelle', value: "votd_reference"},
                    {text: 'Benutzerdefinierte Tagesvers Bibelstelle', value: "votd_reference_overwrite"},
                    {text: 'Tagesvers Bibelübersetzung', value: "fk_votd_bible_translation"},
                    {text: 'Bibellesestelle', value: "recommended_bible_reference"},
                ],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ArticlesRepository.getHistory(this.article.id);
                this.histories = data;
                this.filter_histories();
                this.loading = false;
            },
            filter_histories() {
                let self = this;
                if (this.filter_selected === false) this.histories_filtered = this.histories;
                else {
                    this.histories_filtered = this.histories.filter(function (item) {
                        return (item.field === self.filter_selected);
                    })
                }
            }
        },
        created() {
            this.fetch();
        },
        watch: {
            filter_selected: function () {
                this.filter_histories()
            },
            dialog: {
                handler(newValue){
                    if(newValue === true){
                        this.fetch();
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .text {
        white-space: pre-line;
    }
</style>

<style>
    .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
</style>
