<template>
  <div class="preview_container print-card">
    <div class="text-center">
      <v-progress-circular
          v-if="loading"
          class="my-15"
          :size="50"
          color="primary"
          indeterminate
      />
    </div>

    <div class="preview_content" v-if="!loading">
      <div v-if="show_ids" style="position: absolute; top: -30px;">
        <v-chip x-small>
          #{{ article.id }}
        </v-chip>
      </div>
      <div class="preview_date_box">
        <div v-if="article.assigned_date" class="preview_weekday">{{ article.assigned_date | luxon("EEEE") }}</div>
        <div v-else class="preview_weekday">WOCHENTAG</div>
        <div class="preview_date">
          <div v-if="article.assigned_date" class="preview_month">{{ article.assigned_date | luxon("MMMM") }}</div>
          <div v-else class="preview_month">MONAT</div>
          <div style="background-color: #333; width: 1px;"></div>
          <div v-if="article.assigned_date" class="preview_day">{{ article.assigned_date | luxon("dd") }}</div>
          <div v-else class="preview_day">00</div>
        </div>
      </div>
      <div class="preview_votd_box">
        <div class="preview_votd">
          <div>
            {{ this.hyphenated_votd }}
            <span class="preview_votd_ref">{{ votd_ref }}</span>
          </div>
        </div>
      </div>
      <h2 class="preview_title">{{ article.title }}</h2>
      <div class="preview_text preview_text_style">
        <img src="../../assets/article_preview/category_example.png" class="preview_topic_img">
        <div style="text-align:right; position:absolute; left: -28px; background-color:gold; padding: 0 2px;" v-if="show_lines" class="preview_text_style">
          1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10<br/>
          11<br/>12<br/>13<br/>14<br/>15<br/>16<br/>17<br/>18<br/>19<br/>20<br/>
          21<br/>22<br/>23<br/>24<br/>25<br/><span style="color: darkgreen">26<br/>27</span><br/><span style="color: darkorange">28</span><br/><span style="color: darkred">29<br/>30</span><br/>
        </div>
        <div class="preview_text_img_floater"></div>
        <div v-html="this.hyphenated_text" id="output_text"></div>
        <div class="preview_author_name" v-if="article.writer_data !== undefined">{{ article.writer_data.first_name }} {{ article.writer_data.last_name }}</div>
      </div>
      <div class="preview_bottom_grid">
        <img src="../../assets/article_preview/icons.svg#elem1">
        <div>{{ article.question }}</div>
        <img src="../../assets/article_preview/icons.svg#elem2">
        <div>{{ article.hint }}</div>
        <img src="../../assets/article_preview/icons.svg#elem3">
        <div>{{ article.recommended_bible_reference }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {hyphenate} from "hyphen/de";
import bibleRefMixin from '@/mixins/bibleRefMixin'

export default {
  name: "ArticlePrintLayout",
  props: {
    article: Object,
    show_lines: Boolean,
    show_ids: Boolean,
    loading: Boolean,
  },
  mixins: [bibleRefMixin],
  data() {
    return {
      hyphenated_text: '',
      hyphenated_votd: '',
      votd_ref: '',
    }
  },
  created() {
    if (!this.loading)
      this.prepareContent()
  },
  watch: {
    loading(loading) {
      if (!loading) {
        this.prepareContent()
      }
    }
  },
  methods: {
    prepareContent() {
      hyphenate(this.article.text, {minWordLength: 5}).then(result => {
        this.hyphenated_text = result;
      });
      hyphenate(this.article.votd, {minWordLength: 5}).then(result => {
        this.hyphenated_votd = result;
      });
      this.votd_ref = this.parseBibleRef(this.article.votd_reference, this.article.votd_reference_overwrite);
    },
  },
}
</script>

<style scoped>
.preview_container {
  width: 460px;
  height: 740px;
  background-color: white;
  box-shadow: black 1px 1px 4px;
  padding: 30px 46px 30px 39px;
  color: #333;
}

.preview_content {
  position: relative;
  height: 100%;
}

.preview_date_box {
  font-family: "Montserrat", sans-serif;
  /* display: grid;
  grid-template-columns: auto auto; */
  position: relative;
  height: 40px;
}

.preview_date {
  display: grid;
  grid-template-columns: min-content min-content min-content;
  position: absolute;
  right: 0;
  grid-gap: 7px;
}

.preview_weekday {
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8pt;
}

.preview_day {
  font-weight: 600;
  font-size: 15pt;
}

.preview_month {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 8pt;
}

.preview_votd_box {
  font-family: "Montserrat", sans-serif;
  /* border-style: dashed;
  border-color: #333;
  border-width: 1px 0;
  margin: 5px 0;
  padding: 5px 0;
  display: grid;
  grid-template-columns: 30px 1fr 30px; */
}

/* .preview_votd_img{
    height: 60px;
    margin-left: -12px;
} */
.preview_votd {
  font-size: 10pt;
  line-height: normal;
  font-weight: 600;
  white-space: pre-line;
}

.preview_votd_ref {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 6pt;
  display: block;
  margin-top: 5px;
}

.preview_title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11.3pt;
  letter-spacing: -0.15px;
  text-align: left;
  margin-top: 14px;
  font-weight: 800;
  margin-left: 57px;
  margin-bottom: 2px;
}

.preview_topic_img {
  position: absolute;
  width: 55px;
  left: 0px;
  top: -25px;
}

.preview_text_img_floater {
  float: left;
  width: 57px;
  height: 20px;
}

.preview_text {
  position: relative;
  white-space: pre-line;
}

.preview_text_style {
  font-family: "Milo", serif;
  /* font-size: 12.7px; Value that looks the same on all Browsers. Even Chrome on Windows!! :D */
  /* font-size: 0.877em; */
  font-size: 9.2pt;
  line-height: 11.3pt;
  letter-spacing: -0.15px;
}

.v-input--selection-controls {
  margin-top: 0;
}

#output_text {
  text-align: justify;
  /* letter-spacing: normal; */
}

.preview_author_name {
  font-family: "Milo-Italic", serif;
  text-align: right;
}

.preview_bottom_grid {
  font-family: "Montserrat", sans-serif;
  font-size: 8pt;
  line-height: 9.6pt;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 6px;
  position: absolute;
  bottom: -12px;
}

.preview_bottom_grid img {
  height: 15px;
  margin-left: auto;
  position: relative;
  top: 0;
}

.preview_bottom_grid div {
  height: 26px;
}

@media print {
  .print-card {
    margin-top: 75px;
    margin-bottom: 100px;
  }
}
</style>

<style>
   #output_text p {
   margin-bottom: 0;
 }

#output_text p:not(:first-child) {
  text-indent: 15px;
}

#output_text p:after {
  content: "";
  display: inline-block;
  width: 0;
}
</style>