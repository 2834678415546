<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-btn icon @click="$router.go(-1)">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>Projekt-Übersicht</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-container fluid class="px-5">
                    <v-row>
                        <v-col cols="12" md="6">
                            <WidgetProjectSummary :project="project" :progress="progress" :article_stats="article_stats" :stats="stats" :topics="topics"/>

                            <WidgetProjectDownload :project="project" :currentProject="currentProject"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <WidgetProjectStatistics :stats="stats"/>

                            <WidgetProjectAuthors :stats="stats" />

                            <WidgetProjectTopics :topics="topics"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import WidgetProjectTopics from "@/components/project/WidgetProjectTopics";
    import WidgetProjectDownload from "@/components/project/WidgetProjectDownload";
    import WidgetProjectSummary from "@/components/project/WidgetProjectSummary";
    import WidgetProjectStatistics from "@/components/project/WidgetProjectStatistics";
    import WidgetProjectAuthors from "@/components/project/WidgetProjectAuthors";

    const ProjectsRepository = RepositoryFactory.get('projects');
    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "Project",
        components: {
            MenuSetDefault,
            WidgetProjectTopics,
            WidgetProjectDownload,
            WidgetProjectSummary,
            WidgetProjectStatistics,
            WidgetProjectAuthors,
        },
        data() {
            return {
                project: {},
                progress: 0,
                stats: {topics: []},
                article_stats: {},
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ProjectsRepository.getById(this.currentProject);
                this.project = data;
                this.loading = false;
            },
            async fetchStats() {
                this.loading = true;
                const {data} = await ProjectsRepository.getStats(this.currentProject);
                this.progress = (data.project_article_finalized / data.project_day_count) * 100;
                this.stats = data;
                this.loading = false;
            },
            async fetchArticleStats() {
                this.loading = true;
                const {data} = await ArticlesRepository.getStats(this.currentProject);
                this.article_stats = data;
                this.loading = false;
            },
        },
        computed: {
            currentProject() {
                return this.$store.getters.currentProject;
            },
            topics() {
                let self = this;
                return self.stats.topics.sort((a, b) => (a.topic_count < b.topic_count) ? 1 : -1)
            },
        },
        created() {
            this.fetch();
            this.fetchStats();
            this.fetchArticleStats();
        },
    }
</script>
