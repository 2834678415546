import Repository from "./Repository";

const resource = "/user_profile";
const themeResource = "/set_dark_mode";
const changePasswordResource = "/password_change";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    setDarkMode(active){
        return Repository.get(`${themeResource}/?value=${active ? 1 : 0}`);
    },
    changePassword(old_password, new_password){
        let payload = {'old_password':old_password,'new_password': new_password};
        return Repository.put(`${changePasswordResource}/`, payload);
    },
}