<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <DialogChangePassword :dialog="dialogPassword" :user="null" @close="close" />

        <DialogUser
                v-if="profile.id !== undefined"
                :dialog="dialogUser"
                :editedItem="profile"
                formTitle="Profil bearbeiten"
                :editedIndex="profile.id"
                @close="close"
        />

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-btn icon @click="$router.go(-1)">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>Mein Profil</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon
                                   @click="changePassword()"
                            >
                                <v-icon>mdi-lock-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Passwort ändern</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon @click="changeUser()">
                                <v-icon>
                                    mdi-account-edit-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Profil bearbeiten</span>
                    </v-tooltip>
                </v-toolbar>
            </v-card>

            <UserProfile v-if="userId" :loading="loading" :myProfile="myProfile" :profile="profile" :userId="userId"/>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import UserProfile from "@/components/users/UserProfile";
    import DialogChangePassword from "@/components/users/DialogChangePassword";
    import DialogUser from "@/components/users/DialogUser";
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ProfileRepository = RepositoryFactory.get('profile');

    export default {
        name: "MyProfile",
        components: {
            MenuSetDefault,
            UserProfile,
            DialogChangePassword,
            DialogUser,
        },
        data() {
            return {
                profile: {},
                loading: true,
                myProfile: true,
                dialogPassword: false,
                dialogUser: false,
                userId: this.$store.state.user.id,
            }
        },
        methods: {
            changePassword() {
                this.dialogPassword = true
            },
            changeUser() {
                this.dialogUser = true
            },
            async fetch() {
                this.loading = true;
                const {data} = await ProfileRepository.get();
                this.userId = this.$store.state.user.id;
                this.profile = data;
                this.loading = false;
            },
            close () {
                this.fetch();
                this.dialogPassword = false;
                this.dialogUser = false;
            },
        },
        created() {
            if(this.$store.state.user.id !== undefined) {
                this.fetch();
            }
        },
        watch: {
            $route() {
                this.fetch();
            },
            '$store.state.user.id'() {
                this.fetch();
            },
        },
    }
</script>
