import Repository from "./Repository";

const resource = "/topics";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    add(name, img_b64){
        let payload = {'name':name, 'img_b64': img_b64}
        return Repository.post(`${resource}/`, payload);
    },
    update(id, name, img_b64){
        let payload = {'name': name, 'img_b64': img_b64};

        return Repository.put(`${resource}/${id}/`, payload);
    }
}