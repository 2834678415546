<template>
    <div>
        <MenuLeftDrawer :drawer="drawer" @updateDrawer="updateDrawer"/>

        <v-app-bar app clipped-left clipped-right color="primary" dense>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title class="mr-12 align-center">
                <span class="title">{{ AppSettings.APP_TITLE }} <span v-if="this.$store.getters.currentProjectMeta">({{ this.$store.getters.currentProjectMeta.year }})</span></span>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <MenuDropdown/>
        </v-app-bar>
    </div>
</template>

<script>
    import MenuDropdown from "./MenuDropdown";
    import MenuLeftDrawer from "./MenuLeftDrawer";
    import AppSettings from '@/config/AppSettings'

    export default {
        name: "Menues",
        components: {
            MenuLeftDrawer,
            MenuDropdown,
        },
        data() {
            return {
                AppSettings,
                drawer: null,
            }
        },
        methods: {
            updateDrawer(drawer) {
                this.drawer = drawer;
            },
        },
    }
</script>

<style scoped>
    .v-toolbar__content, .v-toolbar__title {
        color: white;
    }

    .v-toolbar__content .v-btn--outlined .v-btn__content .v-icon,
    .v-toolbar__content .v-btn--round .v-btn__content .v-icon {
        color: white;
    }
</style>
