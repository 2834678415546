import axios from "axios";
import store from '../store/index';

// const baseDomain = "https://localhost:8859";
// const baseDomain = "https://api.lim.clv.de";
const baseDomain = process.env.VUE_APP_API_ENDPOINT;
const baseURL = `${baseDomain}/`;

export default axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: {
            toString () {
                return `Bearer ${store.state.access}`
            }
        }
    },
});
