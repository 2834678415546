<template>
  <v-card class="print-card" elevation="0">
    <v-card-text>
      <ArticlePrintLayout
          :article="article"
          :show_ids="show_ids"
          :show_lines="show_lines"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ArticlePrintLayout from "./ArticlePrintLayout.vue";

export default {
  name: "ArticlePreviewFull",
  components: {ArticlePrintLayout},
  props: {
    article: Object,
    show_lines: Boolean,
    show_ids: Boolean,
  },
}
</script>
