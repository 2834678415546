<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>Benutzer</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   icon
                                   :loading="btnLoading"
                                   @click="download"
                            >
                                <v-icon>mdi-download</v-icon>
                            </v-btn>
                        </template>
                        <span>Download</span>
                    </v-tooltip>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   @click="editItem()"
                                   icon
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Benutzer erstellen</span>
                    </v-tooltip>

                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                        v-model="tableSettings.search"
                        append-icon="mdi-magnify"
                        label="Suchen"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :loading="loading" loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="users"
                        :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                        :items-per-page="25"
                        :search="tableSettings.search"
                        :sort-by="tableSettings.sortBy"
                        :sort-desc="tableSettings.sortDesc"
                        @update:sort-by="updateSortBy"
                        @update:sort-desc="updateSortDesc"
                >
                    <template v-slot:top>
                        <DialogUser
                            :dialog="dialog"
                            :editedItem="editedItem"
                            :formTitle="formTitle"
                            :editedIndex="editedIndex"
                            :users="users"
                            @saved="saved"
                            @close="close"
                        />
                    </template>

                    <template v-slot:item.full_name="{ item }">
                        <v-list-item-content>
                            <v-list-item-title>{{ item.full_name}}</v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.birthday | luxon }}
                                <span v-if="item.birthday && item.city"> | </span>
                                {{ item.city }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <v-icon>{{ stateIcons[item.status] }}</v-icon>
                    </template>

                    <template v-slot:item.count_articles="{ item }">
                        <v-chip>{{ item.count_articles_used }}/{{ item.count_articles }}</v-chip>
                    </template>

                    <template v-slot:item.id="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" @click="editItem(item)">
                                    <v-icon>
                                        mdi-square-edit-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Benutzer bearbeiten</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon :to="`/profile/` + item.id">
                                    <v-icon>
                                        mdi-account-box-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Profil öffnen</span>
                        </v-tooltip>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon :to="item.id === $store.state.user.id ? `/my-articles` : `/user-articles/${item.id}`">
                                    <v-icon>
                                        mdi-text-box-multiple-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Beiträge anzeigen</span>
                        </v-tooltip>
<!--                        <v-tooltip top>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-btn v-bind="attrs" v-on="on" icon>-->
<!--                                    <v-icon>-->
<!--                                        mdi-delete-outline-->
<!--                                    </v-icon>-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                            <span>Benutzer löschen</span>-->
<!--                        </v-tooltip>-->
                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogUser from "@/components/users/DialogUser";

    const UsersRepository = RepositoryFactory.get('users');

    export default {
        name: "Users",
        components: {
            MenuSetDefault,
            DialogUser,
        },
        data() {
            return {
                users: [],
                loading: true,
                btnLoading: false,
                headers: [
                    {text: 'Status', value: 'status'},
                    {text: 'Name', value: 'full_name'},
                    {text: 'E-Mail', value: 'email'},
                    {text: 'Kürzel', value: 'short'},
                    {text: 'Beiträge', value: 'count_articles'},
                    {text: 'Aktion', value: 'id', align: 'end', sortable: false},
                ],
                stateIcons: {
                    a: 'mdi-circle',
                    p: 'mdi-circle-outline',
                    d: 'mdi-christianity',
                },
                dialog: false,
                editedIndex: -1,
                editedItem: {},
                defaultItem: {
                    id: null,
                    username: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    short: null,
                    gender: null,
                    birthday: null,
                    street: null,
                    zip_code: null,
                    city: null,
                    tel_private: null,
                    tel_business: null,
                    church: null,
                    status: null,
                    demand_count: null,
                    comment: null,
                },
                tableSettings: {
                    sortBy: ['name'],
                    sortDesc: [false],
                    search: '',
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await UsersRepository.get();
                this.users = data;
                this.loading = false;
            },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.users.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.users[this.editedIndex], editedItem)
                } else {
                    this.users.push(editedItem);
                }
                this.close()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            updateSortBy(update) {
                this.tableSettings.sortBy = update;
            },
            updateSortDesc(update) {
                this.tableSettings.sortDesc = update;
            },
            download() {
                this.btnLoading = true;
                UsersRepository.getUserExport()
                    .then((response) => {
                        this.downloadBlob(response.data, 'LIM-Users.csv');
                        this.btnLoading = false;
                    });
            },
            downloadBlob(blob, filename) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([blob])
                );
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Neuen Benutzer anlegen' : 'Benutzer bearbeiten'
            },
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            next();
        },
        created() {
            if (this.$store.state.tableSettings[this.$route.path]) {
                this.tableSettings = this.$store.state.tableSettings[this.$route.path];
            }
            this.fetch();
            // username, first_name, last_name, email, password, short=false, gender=false, birthday=false, street=false, zip_code=false, city=false, tel_private=false, tel_business=false, church=false, status=false, demand_count=false, comment=false
            // UsersRepository.add("Egon","Egon","Gustavson","eg@home.de","kalle","Egi","m","1900-12-12","Homestreet","23843","B.O.","04531 / 8828292","04531 / 999 8844",1,"p",66,"Egon's Kommentar");
            // UsersRepository.resetPassword(19, 'kalle');
            // UsersRepository.addEditorGroup(19);
            // UsersRepository.removeEditorGroup(19);
            // UsersRepository.addAdminGroup(19);
            // UsersRepository.removeAdminGroup(19);
        },
    }
</script>
