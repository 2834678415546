<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0" :loading="loading">

                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>
                        Beitragssuche
                        <span v-if="tableSettings.term && articles.length > 0"> - {{ tableSettings.term }}</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn @click="articles.length > 0 ? clearSearch() : startSearch()"
                                   v-bind="attrs"
                                   v-on="on"
                                   absolute dark fab bottom right color="primary">
                                <v-icon v-if="articles.length > 0">mdi-close</v-icon>
                                <v-icon v-else>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="articles.length > 0">Suche Löschen</span>
                        <span v-else>Beiträge suchen</span>
                    </v-tooltip>
                </v-toolbar>

                <template slot="progress">
                    <v-progress-linear background-color="primary" color="white" indeterminate
                                       bottom></v-progress-linear>
                </template>

                <v-row v-if="!articles.length" align="center" justify="center">
                    <v-col cols="12" sm="8" md="4">
                        <v-card class="mx-auto mt-10" elevation="0" max-width="450px">
                            <v-card-text>
                                <v-text-field
                                        dense
                                        class="mr-2 no-radius"
                                        v-model="tableSettings.term"
                                        :append-outer-icon="'mdi-send'"
                                        filled
                                        required
                                        clear-icon="mdi-close"
                                        clearable
                                        hide-details
                                        label="Suchbegriff"
                                        v-on:keydown.enter="startSearch"
                                        @click:append-outer="startSearch"
                                        @click:clear="clearTerm"
                                ></v-text-field>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-card-title v-if="articles.length">
                    <v-text-field style="max-width: 250px"
                                  v-model="tableSettings.search"
                                  append-icon="mdi-magnify"
                                  label="Filtern"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        v-if="articles.length"
                        :headers="headers"
                        :items="articles"
                        :search="tableSettings.search"
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                        :options="tableSettings"
                        @update:options="updateTableSettings"
                >
                    <template v-slot:top>
                        <DialogPreviewFull
                                :dialog="preview_dialog"
                                :article_id="preview_id"
                                @close="close"
                        />
                    </template>

                    <template v-slot:item.event_day_name="{ item }">
                        {{ item.event_day_name }}
                        <span v-if="item.event_day_topic_name && item.event_day_name"> | </span>
                        {{ item.event_day_topic_name }}
                    </template>

                    <template v-slot:item.assigned_date="{ item }">
                        <span v-if="item.assigned_date">
                            {{ item.assigned_date | luxon}}
                        </span>
                        <span v-else>-</span>
                    </template>

                    <template v-slot:item.title="{ item }">
                        <router-link :to="'/articles/' + item.id">{{ item.title }}</router-link>
                    </template>

                    <template v-slot:item.action="{ item }">

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs}">
                                <v-btn icon
                                       @click="previewItem(item.id)"
                                       v-bind="attrs"
                                       v-on="on">
                                    <v-icon>
                                        mdi-eye-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Vorschau</span>
                        </v-tooltip>

                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs}">
                                <v-btn icon
                                       :to="'/articles/' + item.id"
                                       v-bind="attrs"
                                       v-on="on">
                                    <v-icon>
                                        mdi-square-edit-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Beitrag bearbeiten</span>
                        </v-tooltip>

                    </template>
                </v-data-table>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticlesSearch",
        components: {
            MenuSetDefault,
            DialogPreviewFull,
        },
        data() {
            return {
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Titel', value: 'title'},
                    {text: 'Thema', value: 'topic_name'},
                    {text: 'Anlass', value: 'event_day_name'},
                    {text: 'Anlass Thema', value: 'event_day_topic_name', align: ' d-none'},
                    {text: 'Autor', value: 'writer_name'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Projekt', value: 'project_name'},
                    {text: 'Datum', value: 'assigned_date'},
                    {text: 'Aktion', value: 'action', align: 'right'},
                ],
                articles: [],
                loading: false,
                preview_dialog: false,
                preview_id: null,
                tableSettings: {
                    sortBy: ['created_at'],
                    sortDesc: [true],
                    itemsPerPage: 25,
                    search: '',
                    term: '',
                },
            }
        },
        methods: {
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true
            },
            startSearch() {
                if (this.tableSettings.term !== '') {
                    this.loading = true;
                    ArticlesRepository.getArticlesBySearchTerm(this.tableSettings.term)
                        .then(response => {
                            if (response.data.length) {
                                this.articles = response.data;
                                if (this.$store.state.tableSettings[this.$route.path]) {
                                    this.tableSettings = this.$store.state.tableSettings[this.$route.path];
                                }
                            } else {
                                this.$store.dispatch('setMessage', 'Es wurden keine Beiträge gefunden.');
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.$store.dispatch('setMessage', 'Bitte geben Sie einen Suchbegriff ein.');
                }
            },
            clearTerm() {
                this.tableSettings.term = '';
            },
            clearSearch() {
                this.tableSettings.term = '';
                this.articles = [];
            },
            close() {
                this.preview_dialog = false;
                this.preview_id = null;
            },
            updateTableSettings(options) {
                this.tableSettings = options;
            },
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            if (this.articles.length > 0) {
                this.$store.dispatch('lastArticleSearchResult', this.articles);
            }
            next();
        },
        created() {
            if (this.$store.state.lastArticleSearchResult.length > 0) {
                this.articles = this.$store.state.lastArticleSearchResult;
            }
            if (this.$store.state.tableSettings[this.$route.path]) {
                this.tableSettings = this.$store.state.tableSettings[this.$route.path];
            }
        },
    }
</script>
