var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Meine Beiträge")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Beitrag erstellen")])]),_c('DialogArticleNew',{attrs:{"dialog":_vm.newArticleDialog},on:{"close":_vm.close,"saved":_vm.saved}})],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.tableSettings.search),callback:function ($$v) {_vm.$set(_vm.tableSettings, "search", $$v)},expression:"tableSettings.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.articles,"search":_vm.tableSettings.search,"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","footer-props":{
                    'items-per-page-options': [10, 25, 50, -1]},"options":_vm.tableSettings},on:{"update:options":_vm.updateTableSettings},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogPreviewFull',{attrs:{"dialog":_vm.preview_dialog,"article_id":_vm.preview_id},on:{"close":_vm.close}}),_c('DialogArticleEdit',{attrs:{"dialog":_vm.editDialog,"article_id":_vm.editId},on:{"edited":_vm.edited,"close":_vm.close,"intermediateUpdate":_vm.updateArticle}})]},proxy:true},{key:"item.title",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticClass:"link-indicator",on:{"click":function($event){return _vm.previewItem(item.id)}}},[_vm._v(_vm._s(item.title))]),(item.comment_author)?_c('span',{staticClass:"ml-2"},[(item.comment_author)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-message-text ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comment_author))])]):_vm._e()],1):_vm._e()]}},{key:"item.event_day_name",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_day_name)+" "),(item.event_day_topic_name && item.event_day_name)?_c('span',[_vm._v(" | ")]):_vm._e(),_vm._v(" "+_vm._s(item.event_day_topic_name)+" ")]}},{key:"item.assigned_date",fn:function(ref){
                    var item = ref.item;
return [(item.assigned_date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("luxon")(item.assigned_date))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.writer_data",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.writer_name)+" ")]}},{key:"item.action",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!(_vm.$store.getters.isEditor || _vm.$store.getters.isAdmin) && (item.status !== _vm.AppSettings.STATUS_SUBMITTED && item.status !== _vm.AppSettings.STATUS_DEFERRED),"to":_vm.$store.getters.isEditor ? '/articles/' + item.id : ''},on:{"click":function($event){return _vm.editItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Beitrag bearbeiten")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.previewItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vorschau")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":("/my-articles/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-text-box-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Details")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }