<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-account</v-icon>
            </v-btn>

            <v-toolbar-title>Autoren</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showMore = !showMore">
                <v-icon v-if="showMore">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>Autor</th>
                        <th>Gemeinde</th>
                        <th>Verwendet</th>
                        <th>Ungenutzt</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(writer, index) in stats.writer" :key="writer.writer__id">
                        <td v-if="index <= 4 || showMore">
                            {{ writer.writer__last_name}}, {{ writer.writer__first_name}}
                        </td>
                        <td v-if="index <= 4 || showMore">{{ writer.writer__church__name}}</td>
                        <td v-if="index <= 4 || showMore">
                            {{ writer.writer_article_count }}
                        </td>
                        <td v-if="index <= 4 || showMore">
                            {{ writer.writer_article_unused_count }}
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-btn block elevation="0" @click="showMore = !showMore">
                <v-icon left v-if="showMore">mdi-chevron-up</v-icon>
                <v-icon left v-else>mdi-chevron-down</v-icon>
                <span v-html="!showMore ? 'Alle Anzeigen' : 'Alle Ausblenden'"></span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "WidgetProjectAuthors",
        props: {
            stats: Object,
        },
        data() {
            return {
                showMore: false,
            }
        },
    }
</script>

<style scoped>

</style>
