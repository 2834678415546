<template>
    <v-dialog v-model="projectSelectDialog" max-width="500px" persistent>
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span>Bitte wählen Sie Ihr Projekt</span>
            </v-card-title>
            <v-card-text>
                <v-btn
                        v-for="(project, i) in projects"
                        :key="i"
                        block
                        class="mb-2"
                        elevation="0"
                        @click="save(project)"
                >
                    <v-spacer></v-spacer>
                    {{ project.name }} ({{ project.year}})
                    <v-spacer></v-spacer>
                    <v-icon right color="grey">mdi-chevron-right-circle</v-icon>
                </v-btn>
                <v-btn
                        v-if="currentProject"
                        block
                        class="mb-2"
                        elevation="0"
                        color="primary"
                        @click="save(currentProject)"
                >
                    <v-spacer></v-spacer>
                    Auf {{ currentProject.name }} ({{ currentProject.year}}) bleiben
                    <v-spacer></v-spacer>
                    <v-icon right>mdi-chevron-right-circle</v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ProjectsRepository = RepositoryFactory.get('projects');

    export default {
        name: "DialogProjectSelect",
        props: {
            projectSelectDialog: Boolean,
        },
        data() {
            return {
                loading: false,
                projects: [],
                currentProject: null,
                currentProjectMeta: {},
            }
        },
        methods: {
            fetch: async function () {
                this.loading = true;
                const {data} = await ProjectsRepository.getActiveProjects();
                this.projects = data;
                if (this.$store.getters.isEditor && this.$store.getters.currentProjectMeta !== undefined && this.$store.getters.currentProjectMeta !== null) {
                    let self = this;
                    let exists = this.projects.some(function (field) {
                        return field.id === self.$store.getters.currentProjectMeta.id
                    });
                    if (!exists) {
                        await this.getProject();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                }
                this.loading = false;
            },
            async getProject() {
                if (this.$store.getters.currentProject && this.$store.getters.currentProjectMeta.id !== this.$store.getters.currentProject) {
                    const {data} = await ProjectsRepository.getById(this.$store.getters.currentProject);
                    this.currentProject = data;
                } else {
                    this.currentProject = this.$store.getters.currentProjectMeta;
                }

            },
            save(project) {
                this.$store.dispatch('setProject', project);
                this.$store.dispatch('setMessage', 'Das Projekt "' + project.name + '" wurde übernommen.');
                this.close();
            },
            close() {
                this.$emit('close');
            },
        },
        created() {
            this.fetch();
        }
    }
</script>

<style scoped>

</style>
