export default  {
    data() {
        return {
            stateIcons: {
                a: 'mdi-circle',
                p: 'mdi-circle-outline',
                d: 'mdi-christianity',
            },
            genderIcons: {
                m: 'mdi-gender-male',
                w: 'mdi-gender-female',
                d: 'mdi-account',
            }
        }
    },
}
