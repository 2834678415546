export default  {
    methods: {
        replaceSpecialChars(text){
            let modifiedText = text;
            // replace starting tags
            modifiedText = modifiedText.replace(/(^|[\s>])["'„‚]/g, '$1»');
            // replace ending tags
            modifiedText = modifiedText.replace(/["“”']([\s<!?.,]|$)/g, "«$1");
            // replace single apostrophe
            modifiedText = modifiedText.replace(/([a-zA-ZäöüÄÖÜß])'([a-zA-ZäöüÄÖÜß])/g, "$1´$2");

            modifiedText = this.fixSpaces(modifiedText)

            return modifiedText;
        },
        fixSpaces(text){
            let modifiedText = text;
            // remove any amount of leading &nbsp; and spaces after start_of_text, whitespace and opening <p> tag
            modifiedText = modifiedText.replace(/(^|\s|p>)(&nbsp;| )+/g, "$1");
            // remove any amount of trailing &nbsp; and spaces before end_of_text, whitespace and closing <p> tag
            // modifiedText = modifiedText.replace(/(&nbsp;| )+($|[\s</p>])/g, "$2");
            modifiedText = modifiedText.replace(/(&nbsp;| )+($|\s|<\/p>)/g, "$2");
            // replace remaining &nbsp; with space
            modifiedText = modifiedText.replace(/&nbsp;/g, " ");
            // replace multiple spaces with a single space
            modifiedText = modifiedText.replace(/( {2,})/g, " ");

            return modifiedText;
        },
    }
}
