<template>
    <v-card class="flex flex-column my-3 mb-6">
        <v-toolbar color="primary" dark class="elevation-0">
            <v-btn icon class="mr-4">
                <v-icon>mdi-book-multiple-outline</v-icon>
            </v-btn>

            <v-toolbar-title>Projekte</v-toolbar-title>

            <DialogProjectSelect :projectSelectDialog="projectSelectDialog" @close="closeProjectSelect"/>

            <v-spacer></v-spacer>

        </v-toolbar>
        <v-skeleton-loader
                :loading="loading"
                type="table-heading, list-item-two-line, divider, list-item-two-line, divider, actions"
        >
        <v-card-text>
            <v-simple-table class="my-0" primary v-if="!loading">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Name
                        </th>
                        <th class="text-left">
                            Jahr
                        </th>
                        <th class="text-left">
                            Bearbeitbar
                        </th>
                        <th>
                            Finalisiert
                        </th>
                        <th>

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(project) in projects" :key="project.id">
                        <td class="text-left">{{ project.name }}</td>
                        <td class="text-left">{{ project.year }}</td>
                        <td class="text-left">
                            <v-icon v-if="project.editable" color="green darken-1">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-else color="primary">mdi-close-circle</v-icon>
                        </td>
                        <td class="text-left">
                            <v-icon v-if="project.finalized" color="green darken-1">
                                mdi-check-circle
                            </v-icon>
                            <v-icon v-else color="primary">mdi-close-circle</v-icon>
                        </td>
                        <td class="text-right">
                            <v-btn color="primary" block disabled>
                                <span v-if="!!currentProject && currentProject !== project.id">Inaktiv</span>
                                <span v-else>Aktiv</span>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="isAdmin" text to="/projects">
                Projektverwaltung
            </v-btn>
            <v-btn text @click="projectSelectDialog = true">
                Projekt wählen
            </v-btn>
        </v-card-actions>
        </v-skeleton-loader>
    </v-card>
</template>

<script>
    import DialogProjectSelect from "@/components/settings/DialogProjectSelect";
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ProjectsRepository = RepositoryFactory.get('projects');

    export default {
        name: "WidgetProjectSelect",
        props: {
            isAdmin: Boolean,
            currentProject: Number,
        },
        data() {
            return {
                projectSelectDialog: false,
                article_stats: {},
                projects: [],
                loading: true,
            }
        },
        components: {
            DialogProjectSelect,
        },
        methods: {
            closeProjectSelect(){
                this.projectSelectDialog = false;
            },
            async fetchActiveProjects() {
                this.loading = true;
                const {data} = await ProjectsRepository.getActiveProjects();
                this.projects = data;
                this.loading = false;
            },
        },
        created() {
            this.fetchActiveProjects();
        },
    }
</script>

<style scoped>

</style>
