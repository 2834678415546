<template>
    <v-card class="flex flex-column my-3 mb-6">
        <v-toolbar color="primary" dark class="elevation-0">
            <v-btn icon class="mr-4">
                <v-icon>mdi-flare</v-icon>
            </v-btn>

            <v-toolbar-title>Neueste Beiträge</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>

        <v-skeleton-loader
                :loading="loading"
                type="table-heading, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, divider, actions"
        >

        <v-card-text>

            <v-simple-table class="my-0" primary v-if="!loading">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Datum
                        </th>
                        <th class="text-left">
                            Titel
                        </th>
                        <th class="text-left">
                            Autor
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(article) in articleStats.latest" :key="article.id">
                        <td class="text-left">{{ article.created_at | luxon }}</td>
                        <td class="text-left"><router-link :to="'/articles/' + article.id">{{ article.title }}</router-link></td>
                        <td class="text-left">{{ article.writer_name }}</td>
                        <td class="text-left">{{ article.status_name }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text to="/unassigned-articles">
                Offenen Beiträge anzeigen
            </v-btn>
        </v-card-actions>
        </v-skeleton-loader>
    </v-card>
</template>

<script>
    export default {
        name: "WidgetLatestArticles",
        props: {
            articleStats: Object,
            loading: Boolean,
        },
    }
</script>

<style scoped>

</style>
