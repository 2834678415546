<template>
    <v-menu offset-y v-if="isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="light" dark v-bind="attrs" v-on="on" text>
                <span class="hidden-xs-only">{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}</span>
                <v-icon light right>mdi-account-circle</v-icon>
            </v-btn>
        </template>
        <v-list dens class="py-0">
            <v-list-item v-if="isLoggedIn" to="/me">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-account-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Profil</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="isLoggedIn" to="/my-articles">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-text-box-multiple-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Meine Beiträge</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-if="isLoggedIn">
                <v-list-item-action>
                    <v-switch class="ml-1" v-model="darkTheme"></v-switch>
                </v-list-item-action>
                <v-list-item-title @click="darkTheme = !darkTheme">
                    Dark Mode
                </v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-if="isLoggedIn" @click="logout">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-lock</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Abmelden</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import { RepositoryFactory } from "@/repositories/RepositoryFactory";

    const ProfileRepository = RepositoryFactory.get('profile');

    export default {
        name: "MenuDropdown",
        data() {
            return {
                darkTheme: this.$vuetify.theme.dark,
            }
        },
        computed: {
            isLoggedIn: function () {
                return this.$store.getters.isLoggedIn
            },
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login');
                        this.$store.dispatch('setMessage', 'Sie wurden erfolgreich abgemeldet.');
                    })
            },
        },
        watch: {
            darkTheme(val) {
                this.$vuetify.theme.dark = val;
                let self = this;
                ProfileRepository.setDarkMode(val)
                    .then( response => {
                        console.log(response);
                        self.$store.dispatch('updateTheme', val)
                    }
                );
            },
        },
    }
</script>

<style scoped>

</style>
