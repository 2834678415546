<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Projekte</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Projekt erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Suchen"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>


                <v-data-table
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="projects"
                        :items-per-page="15"
                        :search="search"
                        :sort-by="['year']"
                        :sort-desc="[true]"
                >
                    <template v-slot:top>
                        <DialogProject
                                :dialog="dialog"
                                :editedItem="editedItem"
                                :formTitle="formTitle"
                                :editedIndex="editedIndex"
                                @saved="saved"
                                @close="close"
                        />
                    </template>
                    <template v-slot:item.editable="{ item }">
                        <v-icon v-if="item.editable" color="grey">mdi-check-circle</v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </template>
                    <template v-slot:item.finalized="{ item }">
                        <v-icon v-if="item.finalized" color="grey">mdi-check-circle</v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:item.active="{ item }">
                        <v-btn color="primary" @click="switchToProject(item)" v-if="currentProject !== item.id">
                            <span>Aktivieren</span>
                            <v-icon right>mdi-toggle-switch-off-outline</v-icon>
                        </v-btn>
                        <v-btn color="primary" disabled elevation="0" v-else>
                            <span>ist Aktiv</span>
                            <v-icon right >mdi-toggle-switch</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:item.id="{ item }">
                        <v-icon class="mr-2" @click="editItem(item)">
                            mdi-square-edit-outline
                        </v-icon>
<!--                        <v-icon @click="deleteItem(item)" >-->
<!--                            mdi-delete-outline-->
<!--                        </v-icon>-->
                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogProject from "@/components/settings/DialogProject";

    const ProjectsRepository = RepositoryFactory.get('projects');

    export default {
        name: "Projects",
        components: {
            MenuSetDefault,
            DialogProject,
        },
        data() {
            return {
                isShowForm: false,
                name: '',
                year: '',
                introduction: '',
                conclusion: '',
                editable: '',
                finalized: '',
                search: '',
                projects: [],
                loading: true,
                headers: [
                    {text: 'Projekt', value: 'name'},
                    {text: 'Jahr', value: 'year'},
                    {text: 'Bearbeitbar', value: 'editable'},
                    {text: 'Finalisiert', value: 'finalized'},
                    {text: 'Aktiv', value: 'active', align: 'right'},
                    {text: 'Aktionen', value: 'id', align: 'right'},
                ],
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    name: '',
                    year: '',
                    introduction: '',
                    conclusion: '',
                    editable: '',
                    finalized: '',
                },
                defaultItem: {
                    id: null,
                    name: '',
                    year: '',
                    introduction: '',
                    conclusion: '',
                    editable: true,
                    finalized: false,
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ProjectsRepository.get();
                this.projects = data;
                this.loading = false;
            },
            // createProject: function () {
            //     let formData = {name: this.name};
            //     ProjectsRepository.create(formData)
            //         .then(response => {
            //             this.projects.push(response.data);
            //             this.name = '';
            //             this.showForm = false;
            //         })
            //         .catch(err => console.log(err))
            // },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.projects.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.projects[this.editedIndex], editedItem)
                } else {
                    this.projects.push(editedItem);
                }
                this.close()
            },
            deleteItem (item) {
                this.editedIndex = this.projects.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.projects.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            switchToProject (item) {
                this.$store.dispatch('setProject', item).then(() => {
                    this.$store.dispatch('setMessage', "Auf Projekt \""+item.name+"\" gewechselt");
                })
            }
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Neues Projekt anlegen' : 'Projekt bearbeiten'
            },
            currentProject () {
                return this.$store.getters.currentProject
            }
        },
        created() {
            this.fetch();
        },
        watch: {
            $route() {
                if (this.$route.query.noActiveProject === 'true') {
                    this.dialog = true;
                    this.$router.push('/projects');
                }
            }
        },
    }
</script>
