var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Zugeordnete Beiträge")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"90px"},attrs:{"to":"/project/articles-preview","absolute":"","dark":"","fab":"","bottom":"","right":"","fixed":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer")])],1)]}}])},[_c('span',[_vm._v("Beiträge drucken")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.newItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Beitrag erstellen")])]),_c('DialogArticleNew',{attrs:{"dialog":_vm.newArticleDialog},on:{"close":_vm.close,"saved":_vm.saved}})],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.tableSettings.search),callback:function ($$v) {_vm.$set(_vm.tableSettings, "search", $$v)},expression:"tableSettings.search"}})],1),_c('v-data-table',{attrs:{"value":"id","headers":_vm.headers,"items":_vm.articles,"search":_vm.tableSettings.search,"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","footer-props":{'items-per-page-options': [10, 25, 50, -1]},"options":_vm.tableSettings,"show-select":""},on:{"update:options":_vm.updateTableSettings,"current-items":_vm.updateFilteredItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogPreviewFull',{attrs:{"dialog":_vm.preview_dialog,"article_id":_vm.preview_id},on:{"close":_vm.close}}),_c('DialogArticleDelete',{attrs:{"dialogDelete":_vm.dialogDelete,"article":_vm.articleToDelete,"index":_vm.indexToDelete,"dialogTitle":"Beitrag wirklich entfernen?"},on:{"deleteArticleConfirm":_vm.deleteArticleConfirm,"closeDelete":_vm.close}})]},proxy:true},{key:"item.event_day_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_day_name)+" "),(item.event_day_topic_name && item.event_day_name)?_c('span',[_vm._v(" | ")]):_vm._e(),_vm._v(" "+_vm._s(item.event_day_topic_name)+" ")]}},{key:"item.latestActivityDate",fn:function(ref){
var item = ref.item;
return [(item.latestActivity.length > 0)?_c('small',[_vm._v(" "+_vm._s(item.latestActivity[0].user.match(/[A-Z]/g).join(''))+" "+_vm._s(_vm._f("luxon")(item.latestActivity[0].datetime,"relative"))+" ")]):_vm._e()]}},{key:"item.assigned_date",fn:function(ref){
var item = ref.item;
return [(item.assigned_date)?_c('span',[_vm._v(" "+_vm._s(_vm._f("luxon")(item.assigned_date))+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/articles/' + item.id}},[_vm._v(_vm._s(item.title))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.previewItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Vorschau")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":'/articles/' + item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Beitrag bearbeiten")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Beitrag löschen")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }