import Repository from "./Repository";

const resource = "/projects";

export default {
    get() {
        return Repository.get(`${resource}/`);
    },
    getById(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    getActiveProjects() {
        return Repository.get(`${resource}/?editable=1`);
    },
    add(name, year, editable = true, finalized = false, introduction = false, conclusion = false) {
        let payload = {'name': name, 'year': year};

        payload.editable = editable;
        payload.finalized = finalized;

        if (introduction) payload.introduction = introduction;
        if (conclusion) payload.conclusion = conclusion;
        return Repository.post(`${resource}/`, payload);
    },
    update(id, name, year, editable = true, finalized = false, introduction = false, conclusion = false) {
        let payload = {'name': name, 'year': year};

        payload.editable = editable;
        payload.finalized = finalized;

        if (introduction) payload.introduction = introduction;
        else payload.introduction = "";

        if (conclusion) payload.conclusion = conclusion;
        else payload.conclusion = "";

        return Repository.put(`${resource}/${id}/`, payload);
    },
    getStats(id) {
        return Repository.get(`${resource}/${id}/stats/`);
    },
    export_xml(id) {
        return Repository.get(`${resource}/${id}/export_xml/`, {responseType: 'blob'});
    },
    export_articles_project_and_unassigned(id) {
        return Repository.get(`${resource}/${id}/export_full_csv/`, {responseType: 'blob'});
    },
    export_csv(id) {
        return Repository.get(`${resource}/${id}/export_csv/`, {responseType: 'blob'});
    },
    export_csv_writers(id) {
        return Repository.get(`${resource}/${id}/export_csv_writer/`, {responseType: 'blob'});
    },
    export_csv_topics(id) {
        return Repository.get(`${resource}/${id}/export_csv_topics/`, {responseType: 'blob'});
    },
    export_csv_bible_refs(id) {
        return Repository.get(`${resource}/${id}/export_csv_bible_refs/`, {responseType: 'blob'});
    },
}
