<template>
    <v-dialog v-model="dialogEditDate" max-width="350px" @click:outside="closeDialog" @keydown.esc="closeDialog">
        <v-card>
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>
                <p>
                    Ordnen Sie dem Beitrag bitte ein Datum zu.
                </p>

                <v-btn v-if="!!article.event_day && eventDayDate !== date && !!eventDayDate" block
                       @click="adoptDate(eventDayDate)" class="mb-3" elevation="0">
                    {{ eventDayDate | luxon("DD") }} - übernehmen
                </v-btn>

                <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="dateFormatted"
                                @blur="date = parseDate(dateFormatted)"
                                label="Datum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            ref="picker"
                            :show-current="false"
                            :allowed-dates="getAllowedDates"
                            :picker-date.sync="calendarStart"
                            v-model="date"
                            :min="projectStart"
                            :max="projectEnd"
                            @change="closeCalendar"
                    ></v-date-picker>
                </v-menu>

                <div v-if="!!article.event_day && date && eventDayDate === false">
                    <p>
                        Es ist das Ereignis {{eventDayString}} hinterlegt. Dieses flexible Ereignis hat aber noch
                        kein Datum für dieses Jahr zugeordnet. Soll das oben stehende Datum für dieses Ereignis
                        übernommen werden?
                    </p>
                    <v-btn block @click="addYearSpecific(date)" class="mb-3" elevation="0">
                        {{ date | luxon("DD") }} - übernehmen
                    </v-btn>
                </div>
            </v-card-text>
            <!--            <v-card-text-->
            <!--                <p>-->
            <!--                    Es ist das Ereignis {{eventDayString}} hinterlegt, aber dem Beitrag ist noch kein entsprechendes Datum zugeordnet.-->
            <!--                </p>-->
            <!--                <v-btn block @click="closeDialog" class="mb-3" elevation="0">Ereignis übernehmen</v-btn>-->
            <!--                <v-btn block @click="closeDialog" class="mb-3" elevation="0">Nur Datum übernehmen</v-btn>-->
            <!--                <v-btn block @click="closeDialog" class="mb-3" elevation="0">Ereignis löschen</v-btn>-->

            <!--            </v-card-text>-->
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="editDateConfirm(true)" :disabled="!article.assigned_date">Löschen</v-btn>
                <v-btn color="primary" text @click="closeDialog">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="editDateConfirm(false)">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DateTime from 'luxon/src/datetime.js'

    const ArticlesRepository = RepositoryFactory.get('articles');
    const ProjectsRepository = RepositoryFactory.get('projects');
    const EventsRepository = RepositoryFactory.get('events');

    export default {
        name: "DialogEditArticleDate",
        props: {
            dialogEditDate: Boolean,
            article: Object,
            projectId: Number,
            dialogTitle: String,
        },
        data() {
            return {
                sendMail: false,
                menu: false,
                projectStart: '',
                calendarStart: '',
                projectEnd: '',
                project: {},
                articles: [],
                newDate: null,
                dateFormatted: null,
            }
        },
        created() {
            this.dateFormatted = this.formatDate(this.article.assigned_date);
        },
        computed: {
            date: {
                get: function () {
                    if (this.newDate === null) {
                        return this.article.assigned_date;
                    } else {
                        return this.newDate;
                    }
                },
                set: function (newValue) {
                    this.newDate = newValue;
                }
            },
            eventDayDate: {
                get: function () {
                    if (this.article.event_day && this.article.event_day_data.fix) {
                        return this.article.project_data.year + '-' + this.article.event_day_data.month.toString().padStart(2, '0') + '-' + this.article.event_day_data.day.toString().padStart(2, '0');
                    } else if (this.article.event_day && !this.article.event_day_data.fix) {
                        let flexEvent = this.article.event_day_data.year_specific.filter(e => e.year === this.project.year);
                        if (flexEvent.length <= 0) {
                            return false;
                        } else {
                            return flexEvent[0].year + '-' + flexEvent[0].month.toString().padStart(2, '0') + '-' + flexEvent[0].day.toString().padStart(2, '0');
                        }
                    } else {
                        return false;
                    }
                }
            },
            eventDayString: {
                get: function () {
                    let eventDayString = '';
                    if (this.article.event_day_topic) {
                        eventDayString = eventDayString.concat(this.article.event_day_topic_data.name + ' - ')
                    }
                    eventDayString = eventDayString.concat(this.article.event_day_data.name + ' ');
                    if (this.eventDayDate) {
                        eventDayString = eventDayString.concat('(' + this.eventDayDate + ')');
                    }
                    return eventDayString;
                }
            },
        },
        methods: {
            async editDateConfirm(deleteDate) {
                if (!deleteDate && this.article.assigned_date === this.date) {
                    this.closeDialog();
                    await this.$store.dispatch('setMessage', 'Es wurde keine Änderung vorgenommen.');
                } else {
                    const response = await ArticlesRepository.setAssignedDate(this.article.id, deleteDate ? null : this.date);
                    if (response.status === 200) {
                        this.$emit('editDateConfirm', response.data);
                        this.dateFormatted = null;
                        this.newDate = null;
                        let action = deleteDate ? 'entfernt' : 'angepasst';
                        await this.$store.dispatch('setMessage', 'Das Datum wurde ' + action + '.');
                    } else {
                        await this.$store.dispatch('setMessage', 'Es ist ein Problem aufgetreten.');
                    }
                }
            },
            async currentProject() {
                const {data} = await ProjectsRepository.getById(this.projectId);
                this.project = data;
                this.projectStart = this.project.year + '-01-01';
                this.calendarStart = this.projectStart;
                this.projectEnd = this.project.year + '-12-31';
            },
            async fetchArticles() {
                this.loading = true;
                await this.currentProject();
                const {data} = await ArticlesRepository.getFinalsByProject(this.projectId);
                this.articles = data;
                this.loading = false;
            },
            adoptDate(date) {
                this.date = date;
            },
            addYearSpecific(date) {
                EventsRepository.addYearSpecific(this.article.event_day, DateTime.fromJSDate(new Date(date)).toFormat('yyyy'), DateTime.fromJSDate(new Date(date)).toFormat('d'), DateTime.fromJSDate(new Date(date)).toFormat('M'))
                    .then( response => {
                        console.log(response);
                    });
            },
            closeDialog() {
                this.dateFormatted = null;
                this.newDate = null;
                this.$emit('closeDialog', this.checkToAdd);
            },
            closeCalendar(date) {
                this.$refs.menu.save(date)
            },
            formatDate(date) {
                if (!date) return null;
                const [year, month, day] = date.split('-');
                return `${day}.${month}.${year}`;
            },
            parseDate(date) {
                if (!date) return null;

                const [day, month, year] = date.split('.');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            },
            getAllowedDates(val) {
                if (val === this.article.assigned_date) {
                    return true;
                } else {
                    return this.articles.filter(e => e.assigned_date === val).length <= 0;
                }
            },
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'MONTH'));
            },
            date() {
                this.dateFormatted = this.formatDate(this.date);
            },
            dialogEditDate(val) {
                if (val === true && this.articles.length === 0) {
                    this.fetchArticles();
                }
                if (val === true) {
                    this.dateFormatted = this.formatDate(this.article.assigned_date);
                }
            },
        },
    }
</script>

<style scoped>

</style>
