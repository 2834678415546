export default  {
    data() {
        return {
            states: {
                10: 'Eingereicht',
                70: 'Zurückgestellt',
                20: 'Überarbeitet',
                30: 'Freigegeben',
                40: 'Final',
                90: 'Verworfen',
            },
        }
    },
}
