var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","tile":""}},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Benutzer")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.btnLoading},on:{"click":_vm.download}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Benutzer erstellen")])])],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.tableSettings.search),callback:function ($$v) {_vm.$set(_vm.tableSettings, "search", $$v)},expression:"tableSettings.search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","headers":_vm.headers,"items":_vm.users,"footer-props":{'items-per-page-options': [10, 25, 50, -1]},"items-per-page":25,"search":_vm.tableSettings.search,"sort-by":_vm.tableSettings.sortBy,"sort-desc":_vm.tableSettings.sortDesc},on:{"update:sort-by":_vm.updateSortBy,"update:sort-desc":_vm.updateSortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogUser',{attrs:{"dialog":_vm.dialog,"editedItem":_vm.editedItem,"formTitle":_vm.formTitle,"editedIndex":_vm.editedIndex,"users":_vm.users},on:{"saved":_vm.saved,"close":_vm.close}})]},proxy:true},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.full_name))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm._f("luxon")(item.birthday))+" "),(item.birthday && item.city)?_c('span',[_vm._v(" | ")]):_vm._e(),_vm._v(" "+_vm._s(item.city))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(_vm.stateIcons[item.status]))])]}},{key:"item.count_articles",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.count_articles_used)+"/"+_vm._s(item.count_articles))])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Benutzer bearbeiten")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":"/profile/" + item.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-box-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Profil öffnen")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":item.id === _vm.$store.state.user.id ? "/my-articles" : ("/user-articles/" + (item.id))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-text-box-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Beiträge anzeigen")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }