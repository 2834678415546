<template>
    <v-dialog v-model="dialog" max-width="500px" @click:outside="close" @keydown.esc="close">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span class="headline">{{ editedItem.name }} bearbeiten</span>
            </v-card-title>
            <v-card-subtitle>{{ editedItem.date_rule }}</v-card-subtitle>
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>

                <v-card-text>
                    <v-container>
                        <v-simple-table dense class="my-0" primary v-if="!loading">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Jahr
                                    </th>
                                    <th class="text-left">
                                        Tag/Monat
                                    </th>
                                    <th class="text-left">
                                        Datum
                                    </th>
                                    <th class="text-right">
                                        Aktion
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item, index) in editedItem.year_specific" :key="index">
                                    <td class="text-left">{{ item.year }}</td>
                                    <td class="text-left">{{ String(item.day).padStart(2, '0') }}.{{ String(item.month).padStart(2, '0') }}.</td>
                                    <td class="text-left">{{ getDateOfItem(item) | luxon("EEE DD") }}</td>
                                    <td class="text-right">
                                        <v-icon  @click="remove(item, index)">mdi-delete</v-icon>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

<!--                        <v-list dense width="200px" class="mx-auto">-->
<!--                            <v-list-item-->
<!--                            v-for="(item, index) in editedItem.year_specific"-->
<!--                            :key="index"-->
<!--                            >-->
<!--                            <v-list-item-content>-->
<!--                                {{ item.year }}-->
<!--                            </v-list-item-content>-->
<!--                            <v-list-item-content>-->
<!--                                {{ item.day }}.{{ item.month }}.-->
<!--                            </v-list-item-content>-->
<!--                            <v-list-item-action>-->
<!--                            <v-btn icon>-->
<!--                                <v-icon color="primary" @click="remove(item, index)">mdi-delete</v-icon>-->
<!--                            </v-btn>-->
<!--                            </v-list-item-action>-->
<!--                            </v-list-item>-->
<!--                        </v-list>-->
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model.number="newEntry.year"
                                    label="Jahr"
                                    :rules="[
                                        v => !!v && v >= 1984 && v <= 2100 || 'Bitte ein gültiges Jahr angeben.',
                                        v => !!v && this.computedUsedYears.indexOf(v) == -1 || 'Jahr bereits eingetragen.',
                                        ]"
                                    filled
                                    append-outer-icon="mdi-plus"
                                    @click:append-outer="incrementYear()"
                                    prepend-icon="mdi-minus"
                                    @click:prepend="decrementYear()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model.number="newEntry.day"
                                    label="Tag"
                                    :rules="[
                                        v => !!v && v >= 1 && v <= 31 || 'Bitte einen gültigen Tag angeben.',
                                        ]"
                                    filled
                                    append-outer-icon="mdi-plus"
                                    @click:append-outer="incrementDay()"
                                    prepend-icon="mdi-minus"
                                    @click:prepend="decrementDay()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="newEntry.month"
                                    label="Monat"
                                    :items="months"
                                    item-value="id"
                                    item-text="name"
                                    :rules="[
                                        v => !!v || 'Bitte einen Monat angeben.',
                                        ]"
                                    filled
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Schließen</v-btn>
                    <v-btn color="primary" elevation="0" @click="save">Hinzufügen</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const EventsRepository = RepositoryFactory.get('events');

    export default {
        name: "DialogEventYearSpecific",
        props: {
            dialog: Boolean,
            editedItem: Object,
            editedIndex: Number,
        },
        data() {
            return {
                loading: false,
                valid: true,
                months: [
                    {'id':1, 'name':'Januar'},
                    {'id':2, 'name':'Februar'},
                    {'id':3, 'name':'März'},
                    {'id':4, 'name':'April'},
                    {'id':5, 'name':'Mai'},
                    {'id':6, 'name':'Juni'},
                    {'id':7, 'name':'Juli'},
                    {'id':8, 'name':'August'},
                    {'id':9, 'name':'September'},
                    {'id':10, 'name':'Oktober'},
                    {'id':11, 'name':'November'},
                    {'id':12, 'name':'Dezember'},
                ],
                used_years: [],
                newEntry:{'year': (new Date()).getFullYear()}
            }
        },
        methods: {
            async save() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    EventsRepository.addYearSpecific(this.editedItem.id, this.newEntry.year, this.newEntry.day, this.newEntry.month)
                        .then(response => {
                            this.editedItem.year_specific.push(response.data);
                            this.sortYearSpecifics();
                            this.saved(this.editedItem);
                            this.loading = false;
                            this.$store.dispatch('setMessage', 'Zu dem Ereignis "' + this.editedItem.name + '" wurde erfolgreich das Datum für das Jahr "' + response.data.year + '" gespeichert.');
                        })
                        .catch(err => {
                                this.close();
                                this.loading = false;
                                console.log(err);
                                this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                            }
                        );
                }
            },
            async remove(item, index){
                this.loading = true;
                let self = this;
                EventsRepository.deleteYearSpecific(item.id)
                    .then(function() {
                        self.editedItem.year_specific.splice(index, 1);
                        self.sortYearSpecifics();
                        self.saved(self.editedItem);
                        self.loading = false;
                        self.$store.dispatch('setMessage', 'Aus dem Ereignis "' + self.editedItem.name + '" wurde das Datum für das Jahr "' + item.year + '" gelöscht.');
                    })
                    .catch(err => {
                            this.close();
                            this.loading = false;
                            console.log(err);
                            this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                        }
                    );
            },
            getDateOfItem(item) {
                return item.year + '-' + String(item.month).padStart(2, '0') + '-' + String(item.day).padStart(2, '0');
            },
            saved(item) {
                this.$emit('savedYearSpecific', item);
            },
            close() {
                this.$emit('close', this.editedItem);
            },
            incrementYear () {
                this.newEntry.year = parseInt(this.newEntry.year,10) + 1
            },
            decrementYear () {
                this.newEntry.year = parseInt(this.newEntry.year,10) - 1
            },
            incrementDay () {
                this.newEntry.day = parseInt(this.newEntry.day,10) + 1
            },
            decrementDay () {
                this.newEntry.day = parseInt(this.newEntry.day,10) - 1
            },
            sortYearSpecifics(){
                this.editedItem.year_specific.sort((a, b) => {
                    return a.year - b.year;
                });
            }
        },
        computed: {
            computedUsedYears() {
                return this.editedItem.year_specific.map(year_specific => {return year_specific.year})
            },
        },
    }
</script>

<style scoped>

</style>
