// import {RepositoryFactory} from "@/repositories/RepositoryFactory";

// const BibleRepository = RepositoryFactory.get('bible');

export default  {
    methods: {
        parseBibleRef(refString, overwrite=""){ // todo: wenn Psalmen als Stelle angezeigt werden darf es nur in Einzahl geschrieben werden.
            if(overwrite !== ""){
                return overwrite
            }
            if(!this.checkBibleRefFormat(refString)) return "[ParsingError]";
            let votd_split = refString.split(';');
            if (this.$store.state.bibleBooks.length === 0) {
                this.$store.dispatch('storeBibleData');
            } else {
                let votd_ref = this.$store.state.bibleBooks.find(b => b.number === Number(votd_split[0])).name + ' ' + votd_split[1] + ',' + votd_split[2];
                if(typeof votd_split[3] !== 'undefined' && votd_split[3] !== "") {
                    votd_ref += '-'+votd_split[3];
                }
                return votd_ref;
            }

        },
        checkBibleRefFormat(refString){
            const regex = /[0-9]+;[0-9]+;[0-9]+;/;
            if ((regex.exec(refString)) !== null) {
                return true;
            }else{
                return false;
            }
        }
    }
}
