<template>
    <v-dialog v-model="dialogChangeStatus" max-width="350px" @click:outside="closeDialog" @keydown.esc="closeDialog">
        <v-card>
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>
                Möchten Sie den Status des Beitrages jetzt von "{{ statusOptions[article.status] }}" auf "{{ statusOptions[statusToSet] }}" ändern?
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="changeStatusConfirm">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "DialogChangeArticleStatus",
        props: {
            dialogChangeStatus: Boolean,
            statusToSet: String,
            article: Object,
            dialogTitle: String,
            statusOptions: Object,
        },
        data() {
            return {
                sendMail: false,
            }
        },
        methods: {
            changeStatusConfirm() {
                ArticlesRepository.setStatus(this.article.id, this.statusToSet)
                .then(response => {
                    this.$emit('changeStatusConfirm', response.data);
                })
                .catch( error => {
                    console.log(error);
                        this.$emit('changeStatusConfirm', false)
                    }
                );
            },
            closeDialog () {
                this.$emit('closeDialog', this.checkToAdd);
            }
        }
    }
</script>

<style scoped>

</style>
