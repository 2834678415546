<template>
    <div>
        <v-row class="my-0">
            <v-col cols="12" sm="6" md="6" class="d-flex flex-column">
                <v-card color="accent" class="flex d-flex flex-column">
                    <v-card-title class="headline">
                        <v-icon left>mdi-plus-circle-outline</v-icon>
                        Beitrag einreichen
                    </v-card-title>

                    <v-card-subtitle>
                        Reichen Sie Ihren eigenen Beitrag ein. Dieser wird dann von den Lektoren gesichtet und überarbeitet.
                    </v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="light" text
                               :to="{path: '/my-articles', query: {openDialog: true}}">
                            Jetzt verfassen
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="d-flex flex-column">
                <v-card color="accent" class="flex d-flex flex-column">
                    <v-card-title class="headline">
                        <v-icon left>mdi-text-box-multiple-outline</v-icon>
                        Meine Beiträge
                    </v-card-title>

                    <v-card-subtitle>
                        Hier finden Sie alle Ihre aktuellen und vergangenen "Leben ist mehr" Beiträge.
                    </v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="light" text to="/my-articles">
                            Anzeigen
                        </v-btn>

                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="6" class="d-flex flex-column">
                <v-card color="accent" class="flex d-flex flex-column">
                    <v-card-title class="headline">
                        <v-icon left>mdi-account-circle</v-icon>
                        Profil
                    </v-card-title>
                    <v-card-subtitle>
                        Ändern Sie hier die Angaben zu Ihrer Person. Auch Ihr Passwort können Sie hier ändern.
                    </v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="light" text to="/me">
                            Öffnen
                        </v-btn>

                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="d-flex flex-column">
                <v-card color="accent" class="flex d-flex flex-column">
                    <v-card-title class="headline">
                        <v-icon left>mdi-information-outline</v-icon>
                        Über die Anwendung
                    </v-card-title>
                    <v-card-subtitle>
                        Information zu Versionsverlauf, Schnittstelle und Entwicklung der LIM Redaktions-App.
                    </v-card-subtitle>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="light" text @click="aboutDialog = true">
                            Anzeigen
                        </v-btn>

                        <DialogAbout :dialog="aboutDialog" @closeDialog="closeDialog"/>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import DialogAbout from "../services/DialogAbout";

    export default {
        name: "WidgetDashboardButtons",
        components: {
            DialogAbout,
        },
        data() {
            return {
                article_stats: {},
                project: [],
                aboutDialog: false,
            }
        },
        methods: {
            closeDialog() {
                this.aboutDialog = false;
            },
        }
    }
</script>

<style scoped>

</style>
