<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-chart-pie</v-icon>
            </v-btn>

            <v-toolbar-title>Statistik</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Geschlecht</th>
                            <th>Beiträge</th>
                            <th>Autoren</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="gender in stats.genders" :key="gender.writer__gender">
                        <td>
                            <v-icon left color="primary">
                                {{ genderIcons[gender.writer__gender] }}
                            </v-icon>
                            <span v-if="gender.writer__gender === 'm'">Männlich</span>
                            <span v-else-if="gender.writer__gender === 'w'">Weiblich</span>
                            <span v-else>Keine Angabe</span>
                        </td>
                        <td>
                            {{ gender.writer_gender_count }}
                            ({{ ((gender.writer_gender_count/stats.project_article_count)* 100).toFixed(1) }}%)
                        </td>
                        <td>
                            {{ stats.writer.filter((obj) => obj.writer__gender === gender.writer__gender).length }}
                            ({{ ((stats.writer.filter((obj) => obj.writer__gender === gender.writer__gender).length/stats.writer.length) * 100).toFixed(1) }}%)
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <v-simple-table dense class="mt-4">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>Gemeinden</th>
                        <th>Beiträge</th>
                        <th>Autoren</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="church in stats.churches" :key="church.writer__church__name">
                        <td>
                            <span v-if="church.writer__church__name">{{ church.writer__church__name }}</span>
                            <span v-else>Keine Angabe</span>
                        </td>
                        <td>
                            {{ church.writer_church_count }}
                            ({{ ((church.writer_church_count/stats.project_article_count)* 100).toFixed(1) }}%)
                        </td>
                        <td>
                            {{ stats.writer.filter((obj) => obj.writer__church__name === church.writer__church__name).length }}
                            ({{ ((stats.writer.filter((obj) => obj.writer__church__name === church.writer__church__name).length/stats.writer.length) * 100).toFixed(1) }}%)
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>

    </v-card>
</template>

<script>
    import iconMixin from '@/mixins/iconMixin'

    export default {
        name: "WidgetProjectStatistics",
        props: {
            stats: Object,
        },
        mixins: [iconMixin],
    }
</script>

<style scoped>

</style>
