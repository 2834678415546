<template>
    <v-dialog v-model="dialogAddCheck" max-width="350px" @click:outside="closeDialog" @keydown.esc="closeDialog">
        <v-card>
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>
                Möchten Sie den Check "{{ checkToAdd.check_name }}" jetzt hinzufügen? Diese Aktion kann nicht mehr rückgängig gemacht werden.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="addCheckConfirm">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "DialogAddArticleCheck",
        props: {
            dialogAddCheck: Boolean,
            checkToAdd: Object,
            articleId: Number,
            dialogTitle: String,
        },
        data() {
            return {
                sendMail: false,
            }
        },
        methods: {
            addCheckConfirm() {
                ArticlesRepository.addCheck(this.articleId, this.checkToAdd.check_type)
                .then(response => {
                    this.$emit('addCheckConfirm', response.data);
                })
                .catch( error => {
                    console.log(error);
                        this.$emit('addCheckConfirm', false)
                    }
                );
            },
            closeDialog () {
                this.$emit('closeDialog', this.checkToAdd);
            }
        }
    }
</script>

<style scoped>

</style>
