var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Themenbereiche")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){return _vm.editItem()}}},'v-btn',tooltipAttrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Themenbereich erstellen")])])],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","headers":_vm.headers,"items":_vm.topics,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('DialogTopic',{attrs:{"dialog":_vm.dialog,"editedItem":_vm.editedItem,"formTitle":_vm.formTitle,"editedIndex":_vm.editedIndex},on:{"saved":_vm.saved,"close":_vm.close}})]},proxy:true},{key:"item.img",fn:function(ref){
var item = ref.item;
return [(item.img_b64 != null)?_c('v-img',{attrs:{"contain":"","max-height":"50","max-width":"100","src":item.img_b64}}):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-square-edit-outline ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }