import Repository from "./Repository";

const resource = "/users";
const resetPasswordResource = "/password_reset";
const updateGroupResource = "/update_user_group";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    getUser(id){
        return Repository.get(`${resource}/${id}/`);
    },
    getArticles(id){
        return Repository.get(`${resource}/${id}/articles/`);
    },
    getArticleCounts(id){
        return Repository.get(`${resource}/${id}/article_counts/`);
    },
    add(username, first_name, last_name, email, password, short=false, gender=false, birthday=false, street=false, zip_code=false, city=false, tel_private=false, tel_business=false, church=false, status=false, demand_count=false, comment=false){
        let payload = {'username':username,'first_name': first_name, 'last_name': last_name, 'email': email, 'password':password};
        if(short) payload.short = short;
        if(gender) payload.gender = gender;
        if(birthday) payload.birthday = birthday;
        if(street) payload.street = street;
        if(zip_code) payload.zip_code = zip_code;
        if(city) payload.city = city;
        if(tel_private) payload.tel_private = tel_private;
        if(tel_business) payload.tel_business = tel_business;
        if(church) payload.church = church;
        if(status) payload.status = status;
        if(demand_count) payload.demand_count = demand_count;
        if(comment) payload.comment = comment;
        return Repository.post(`${resource}/`, payload);
    },
    update(id, username, first_name, last_name, email, short, gender, birthday, street, zip_code, city, tel_private, tel_business, church, status, demand_count, comment){
        let payload = {
            'username':username,
            'first_name': first_name,
            'last_name': last_name,
            'email': email,
            'short': short,
            'gender': gender,
            'birthday': birthday,
            'street': street,
            'zip_code': zip_code,
            'city': city,
            'tel_private': tel_private,
            'tel_business': tel_business,
            'status': status,
            'demand_count': demand_count,
            'comment': comment,
        };
        if(church == undefined) payload.church = null;
        else payload.church = church;

        if(demand_count == "") payload.demand_count = null;
        else payload.demand_count = demand_count;

        return Repository.put(`${resource}/${id}/`, payload);
    },
    updateProject(id, projectId){
        let payload = {
            'current_project': projectId,
        };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    updateStatus(id, status){
        let payload = {
            'status': status,
        };
        return Repository.patch(`${resource}/${id}/`, payload);
    },
    resetPassword(user_id, new_password){
        let payload = {'user_id':user_id,'new_password': new_password};
        return Repository.put(`${resetPasswordResource}/`, payload);
    },
    addEditorGroup(user_id){
        let params = { params: {'user_id':user_id,'group_name': 'Editor', 'add':true}};
        return Repository.get(`${updateGroupResource}/`, params);
    },
    removeEditorGroup(user_id){
        let params = { params: {'user_id':user_id,'group_name': 'Editor', 'remove':true}};
        return Repository.get(`${updateGroupResource}/`, params);
    },
    addAdminGroup(user_id){
        let params = { params: {'user_id':user_id,'group_name': 'Admin', 'add':true}};
        return Repository.get(`${updateGroupResource}/`, params);
    },
    removeAdminGroup(user_id){
        let params = { params: {'user_id':user_id,'group_name': 'Admin', 'remove':true}};
        return Repository.get(`${updateGroupResource}/`, params);
    },
    getGenderOptions(){
        return Repository.get(`${resource}/genders/`);
    },
    getStatusOptions(){
        return Repository.get(`${resource}/status/`);
    },
    getUserExport() {
        return Repository.get(`${resource}/export_csv/`, {responseType: 'blob'});
    },
    sendMail(id, message) {
        let payload = { 'message' :message };
        return Repository.post(`${resource}/${id}/send_mail/`, payload);
    }
}
