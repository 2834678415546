<template>
    <v-main>
        <v-card flat tile class="elevation-0">
            <v-toolbar dense color="accent" class="elevation-0">
                <v-toolbar-title>Beitragsvorschau</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-switch
                        v-model="show_ids"
                        label="ID's"
                        dense
                        hide-details
                        class="mr-4"
                ></v-switch>

                <v-switch
                        v-model="show_lines"
                        label="Zeilennummern"
                        dense
                        hide-details
                ></v-switch>
            </v-toolbar>

            {{ articles.filter((a) => filteredItems.includes(a.id)) }}

            <div id="printarea">
                <div v-for="article in articles.filter((a) => filteredItems.includes(a.id) || filteredItems.length === 0)" :key="article.id">
                    <ArticlePreviewFull :article="article" :show_ids="show_ids" :show_lines="show_lines"/>
                <div class="pagebreak"> </div>
                </div>
<!--                    <div class="pagebreak"> </div>-->
<!--                    <ArticlePreviewFull v-if="articles[0]" :article="articles[0]" :show_ids="show_ids" :show_lines="show_lines"/>-->
<!--                    <div class="pagebreak"> </div>-->
<!--                    <ArticlePreviewFull v-if="articles[1]" :article="articles[1]" :show_ids="show_ids" :show_lines="show_lines"/>-->
<!--                    <div class="pagebreak"> </div>-->
<!--                    <ArticlePreviewFull v-if="articles[2]" :article="articles[2]" :show_ids="show_ids" :show_lines="show_lines"/>-->

            </div>

            <v-tooltip top>
                <template v-slot:activator="{ on, attrs}">
                    <v-btn
                            v-bind="attrs"
                            v-on="on"
                            absolute dark fab bottom right fixed color="primary" style="bottom: 25px; right: 25px;">
                        <v-icon>mdi-printer</v-icon>
                    </v-btn>
                </template>
                <span>Beiträge drucken</span>
            </v-tooltip>

        </v-card>
    </v-main>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import ArticlePreviewFull from "../../components/articles/ArticlePreviewFull";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "Articles",
        components: {
            ArticlePreviewFull,
        },
        data() {
            return {
                articles: [],
                loading: true,
                show_ids: true,
                show_lines: false,
                filteredItems: [],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                let {data} = await ArticlesRepository.getFullArticlesByProject(this.$store.getters.currentProject);
                this.articles = data;
                this.loading = false;
            },
        },
        async created() {
            if (this.$store.state.tableSettings['/articles']) {
                this.filteredItems = this.$store.state.tableSettings['/articles'].filteredItems;
            }
            await this.fetch();
        },
    }
</script>

<style>
    @media print {
        body * {
            visibility: hidden;
            float: none;
            height: auto;
        }
        main {
            margin: auto;
            padding: 0 !important;
        }
        .scroll-y {
            height: auto;
            overflow: visible;
        }
        #printarea, #printarea * {
            visibility: visible;
        }
        #printarea {
            margin: auto;
            position: absolute;
            left: 0;
            top: 0;
        }
        .pagebreak {
            page-break-after: always;
        }
    }
    /*@page {*/
    /*    size: A4;*/
    /*    margin: 0;*/
    /*}*/
</style>
