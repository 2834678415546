<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>{{ article.title }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn icon @click="previewItem"
                                   v-bind="attrs"
                                   v-on="on">
                                <v-icon>mdi-text-box-search-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Vorschau</span>
                    </v-tooltip>

                    <DialogPreviewFull
                            :dialog="preview_dialog"
                            :article_id="article.id"
                            @close="close"
                    />
                </v-toolbar>

                <v-container>

                    <v-card class="text-center">
                        <v-toolbar color="primary" dark class="elevation-0">
                            <v-spacer></v-spacer>
                            <v-toolbar-title v-if="article.assigned_date">{{ article.assigned_date | luxon("EEE DD") }}</v-toolbar-title>
                            <v-toolbar-title v-else>Noch kein Datum vergeben</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="loading"
                                    class="my-16"
                                    :size="50"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </div>

                        <div v-if="!loading">
                            <v-sheet color="accent">
                                <v-card-text class="pa-5">
                                    <h1>{{ article.title }}</h1>
                                    <br>
                                    <div class="font-italic">
                                        {{ article.votd }}
                                    </div>
                                    <small>{{ parseBibleRef(article.votd_reference, article.votd_reference_overwrite) }}</small>
                                </v-card-text>
                            </v-sheet>
                            <v-card-text class="px-10 pt-10 text-left">
                                <div class="text-left" style="white-space: pre-wrap;">
                                    <span v-html="article.text">{{ article.text }}</span>
                                    <small>{{ article.writer_name }}</small></div>
                                <div class="mt-5" v-if="!!article.question">
                                    <div class="font-weight-bold font-italic">
                                        <v-btn
                                                color="primary"
                                                fab
                                                x-small
                                                elevation="0"
                                                dark
                                                class="mb-1 mr-2"
                                        >
                                            <v-icon>mdi-help</v-icon>
                                        </v-btn>
                                        {{ article.question }}
                                    </div>

                                    <div class="font-weight-bold font-italic my-2">
                                        <v-btn
                                                color="primary"
                                                fab
                                                x-small
                                                elevation="0"
                                                dark
                                                class="mb-1 mr-2"
                                        >
                                            <v-icon>mdi-exclamation-thick</v-icon>
                                        </v-btn>
                                        {{ article.hint }}
                                    </div>

                                    <div class="font-weight-bold font-italic">
                                        <v-btn
                                                color="primary"
                                                fab
                                                x-small
                                                elevation="0"
                                                dark
                                                class="mr-2"
                                        >
                                            <v-icon>mdi-book</v-icon>
                                        </v-btn>
                                        {{ article.recommended_bible_reference }}
                                    </div>
                                </div>
                            </v-card-text>
                        </div>
                    </v-card>

                </v-container>


            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
    import bibleRefMixin from '@/mixins/bibleRefMixin'

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "MyArticle",
        mixins: [bibleRefMixin],
        components: {
            MenuSetDefault,
            DialogPreviewFull,
        },
        data() {
            return {
                article: {},
                loading: false,
                preview_dialog: false,
            }
        },
        created() {
            this.fetch();
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ArticlesRepository.getArticle(this.$route.params.articleId);
                this.article = data;
                this.loading = false;
            },
            close() {
                this.preview_dialog = false;
            },
            previewItem() {
                this.preview_dialog = true;
            },
        },
    }
</script>
