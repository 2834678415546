<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-chart-bar</v-icon>
            </v-btn>

            <v-toolbar-title>Statistik</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-skeleton-loader
                :loading="loading"
                type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, list-item-two-line, actions"
        >
        <v-list two-line>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="primary">mdi-update</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        <span v-if="profile.last_login">{{ profile.last_login | luxon("relative") }}</span>
                        <span v-else>Noch Nie</span>

                    </v-list-item-title>
                    <v-list-item-subtitle>Letzter Login</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="primary">mdi-calendar-clock</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ profile.created_at | luxon }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Registrierungsdatum</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon class="mt-2" color="primary">mdi-text-box-multiple-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ counts.written }} Beiträge</v-list-item-title>
                    <v-list-item-subtitle>eingereichte Beiträge</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon :to="myProfile ? `/my-articles` : `/user-articles/${userId}`">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>Beiträge ansehen</span>
                    </v-tooltip>
                </v-list-item-icon>
            </v-list-item>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon class="mt-2" color="primary">mdi-text-box-multiple</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ counts.used }}  Beiträge</v-list-item-title>
                    <v-list-item-subtitle>verwendete Beiträge</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-spacer></v-spacer>

        <v-card-actions block class="pa-4">
            <v-btn block :to="myProfile ? `/my-articles` : `/user-articles/${userId}`" elevation="0">
                Beiträge ansehen
            </v-btn>
        </v-card-actions>
        </v-skeleton-loader>
    </v-card>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const UserRepository = RepositoryFactory.get('users');

    export default {
        name: "UserStatisticCard",
        props: {
            profile: Object,
            userId: Number,
            loading: Boolean,
            myProfile: Boolean,
        },
        data() {
            return {
                counts: {}
            }
        },
        methods: {
            async fetch() {
                const {data} = await UserRepository.getArticleCounts(this.userId);
                this.counts = data;
            },
        },
        created() {
            this.fetch();
        },
    }
</script>

<style scoped>

</style>
