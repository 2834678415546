<template>
    <v-dialog v-model="dialogDelete" max-width="350px" @click:outside="closeDelete" @keydown.esc="closeDelete">
        <v-card>
            <v-card-title>Gemeinde wirklich entfernen?</v-card-title>
            <v-card-text>
                Der Eintrag wird dabei nicht wirklich gelöscht, sondern nur deaktiviert. Das ist notwendig um historische Daten auch weiterhin korrekt zu erhalten.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="deleteItemConfirm">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "DialogChurchDelete",
        props: {
            dialogDelete: Boolean,
            editedIndex: Number,
            dialogTitle: String,
        },
        methods: {
            deleteItemConfirm () {
                this.$emit('deleteItemConfirm', this.editedIndex);
            },
            closeDelete () {
                this.$emit('closeDelete', this.editedIndex);
            }
        }
    }
</script>

<style scoped>

</style>
