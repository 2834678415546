<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-chart-bar</v-icon>
            </v-btn>

            <v-toolbar-title>Themenbereiche</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showMore = !showMore">
                <v-icon v-if="showMore">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text v-if="topics.length === 0 || (topics.length === 1 && topics[0].topic_count === 0)">
            <p>Es wurde noch kein Themenbereich zugeordnet.</p>
        </v-card-text>
        <v-card-text v-else>
        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>Thema</th>
                    <th>Beiträge im Projekt</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(topic, index) in topics" :key="topic.topic__id" >
                    <td v-if="index <= 4 || showMore">{{ topic.topic__name }}</td>
                    <td v-if="index <= 4 || showMore">{{ topic.topic_count }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-btn block elevation="0" @click="showMore = !showMore">
                <v-icon left v-if="showMore">mdi-chevron-up</v-icon>
                <v-icon left v-else>mdi-chevron-down</v-icon>
                <span v-html="!showMore ? 'Alle Anzeigen' : 'Alle Ausblenden'"></span>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "WidgetProjectTopics",
        props: {
            topics: Array,
        },
        data() {
            return {
                showMore: false,
            }
        }
    }
</script>

<style scoped>

</style>
