<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>

            <div class="text-left">
                <v-sheet color="accent" class="py-5 px-5"><h3>Export</h3></v-sheet>
            </div>

            <v-card class="elevation-0 px-5">

                <v-card-title>
                </v-card-title>


                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon color="grey darken-1" left>mdi-file-excel-outline</v-icon>
                                Beitrag | EXCEL
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                        <div>
                            <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                            Stammdaten Autoren | CSV
                        </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                                Stammdaten Themen | CSV
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon color="grey darken-1" left>mdi-file-delimited-outline</v-icon>
                                Stammdaten Bibelstellen | CSV
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <v-icon color="grey darken-1" left>mdi-file-code-outline</v-icon>
                                Satzdaten | XML
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                            laboris nisi ut aliquip ex ea commodo consequat.
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'

    export default {
        name: "Export",
        components: {
            MenuSetDefault,
        },
    }
</script>