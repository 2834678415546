<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-btn icon @click="$router.go(-1)">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-toolbar-title>Beiträge von {{ user.full_name }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs}">
                            <v-btn @click="newItem"
                                   v-bind="attrs"
                                   v-on="on"
                                   absolute dark fab bottom right color="primary"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Beitrag erstellen</span>
                    </v-tooltip>

                    <DialogArticleNew :dialog="newArticleDialog" @close="close" @saved="saved"/>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="tableSettings.search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :headers="headers"
                        :items="articles"
                        :search="tableSettings.search"
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :footer-props="{
                        'items-per-page-options': [10, 25, 50, -1]}"
                        :options="tableSettings"
                        @update:options="updateTableSettings"
                >
                    <template v-slot:top>
                        <DialogPreviewFull
                                :dialog="preview_dialog"
                                :article_id="preview_id"
                                @close="close"
                        />
                        <DialogArticleEdit
                                :dialog="editDialog"
                                :article_id="editId"
                                @edited="edited"
                                @close="close"
                                @intermediateUpdate="updateArticle"
                        />
                    </template>

                    <template v-slot:item.assigned_date="{ item }">
                        <span v-if="item.assigned_date">
                            {{ item.assigned_date | luxon}}
                        </span>
                        <span v-else>-</span>
                    </template>

                    <template v-slot:item.writer_data="{ item }">
                        {{ item.writer_name }}
                    </template>
                    <template v-slot:item.title="{ item }">
                        <b>{{ item.title }}</b>
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs}">
                                <v-icon :disabled="!(item.status === AppSettings.STATUS_SUBMITTED || item.status === AppSettings.STATUS_DEFERRED)"
                                        class="mr-2"
                                        @click="editItem(item.id)"
                                        v-bind="attrs"
                                        v-on="on">
                                    mdi-square-edit-outline
                                </v-icon>
                            </template>
                            <span>Beitrag bearbeiten</span>
                        </v-tooltip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs}">
                                <v-icon :disabled="!(item.status === AppSettings.STATUS_FINAL)"
                                        class="mr-2"
                                        @click="previewItem(item.id)"
                                        v-bind="attrs"
                                        v-on="on">
                                    mdi-eye
                                </v-icon>
                            </template>
                            <span>Vorschau</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogArticleNew from "@/components/articles/DialogArticleNew";
    import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
    import DialogArticleEdit from "@/components/articles/DialogArticleEdit";
    import AppSettings from '@/config/AppSettings';

    const UsersRepository = RepositoryFactory.get('users');

    export default {
        name: "UserArticles",
        components: {
            MenuSetDefault,
            DialogArticleNew,
            DialogPreviewFull,
            DialogArticleEdit,
        },
        data() {
            return {
                AppSettings,
                headers: [
                    {text: 'Titel', value: 'title'},
                    {text: 'Status', value: 'status_name'},
                    {text: 'Projekt', value: 'project_name'},
                    {text: 'Datum', value: 'assigned_date'},
                    {text: 'Aktion', value: 'id', align: 'right'},
                ],
                articles: [],
                loading: true,
                preview_dialog: false,
                preview_id: null,
                editDialog: false,
                newArticleDialog: false,
                editId: null,
                userId: this.$route.params.userId,
                user: [],
                tableSettings: {
                    sortBy: ['created_at'],
                    sortDesc: [false],
                    search: '',
                    itemsPerPage: 10,
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await UsersRepository.getArticles(this.userId);
                this.articles = data;
                this.loading = false;
            },
            async getUser() {
                const {data} = await UsersRepository.getUser(this.userId);
                this.user = data;
            },
            previewItem(itemId) {
                this.preview_id = itemId;
                this.preview_dialog = true;
            },
            editItem(itemId) {
                this.editId = itemId;
                this.editDialog = true;
            },
            newItem() {
                this.newArticleDialog = true;
            },
            saved(item) {
                this.articles.push(item);
                this.close();
            },
            edited(editedItem) {
                const index = this.articles.findIndex( a => a.id === this.editId);
                Object.assign(this.articles[index], editedItem);
                this.close();
            },
            updateArticle(editedItem) {
                const index = this.articles.findIndex( a => a.id === this.editId);
                Object.assign(this.articles[index], editedItem);
            },
            close() {
                this.preview_dialog = false;
                this.editDialog = false;
                this.newArticleDialog = false;
                this.$nextTick(() => {
                    this.preview_id = null;
                    this.editId = null;
                })
            },
            updateTableSettings(options) {
                this.tableSettings = options;
            },
        },
        beforeRouteLeave(to, from, next) {
            this.$store.dispatch('setTableSettings', { path: this.$route.path, settings: this.tableSettings });
            next();
        },
        async created() {
            this.getUser();
            await this.fetch();
            if (this.$store.state.tableSettings[this.$route.path]) {
                this.tableSettings = this.$store.state.tableSettings[this.$route.path];
            }
        },
        watch: {
            '$route.params.userId': function () {
                this.fetch();
            }
        },
    }
</script>
