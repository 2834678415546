<template>
    <v-dialog v-model="dialog" persistent max-width="400px" :fullscreen="$vuetify.breakpoint.xsOnly"
              @click:outside="closeDialog" @keydown.esc="closeDialog">
        <v-card>
            <v-card-title>
                <span class="headline">Berechtigungen bearbeiten</span>
            </v-card-title>
            <v-card-text>
                <p>
                    Setzen Sie hier die Berechtigungen für diesen Benutzer. Nur Administratoren können diese Einstellungen verändern.
                </p>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <span v-for="group in calculatedGroups" :key="group.id">
                            <v-checkbox
                                    v-model="group.active"
                                    :label="group.name"
                                    :disabled="group.disabled"
                                    :messages="group.text"
                                    @click="updateGroup(group.name, group.active)"
                            ></v-checkbox>
<!--                                <small>{{ group.text }}</small>-->
                                </span>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog">Schließen</v-btn>
                <v-btn color="primary" elevation="0" @click="closeDialog">Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const GroupsRepository = RepositoryFactory.get('groups');
    const UsersRepository = RepositoryFactory.get('users');

    export default {
        name: "UserGroupDialog",
        props: {
            assignedGroups: Array,
            dialog: Boolean,
            userId: Number,
        },
        data() {
            return {
                groups: [],
                loading: false,
                selectedGroups: [],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await GroupsRepository.get();
                this.groups = data;
                this.groups.forEach(function (entry, index) {
                    this.groups[index].active = this.assignedGroups.some(el => el.id === entry.id) || false;
                    this.selectedGroups[entry.id] = this.assignedGroups.some(el => el.id === entry.id);
                }, this);
                this.loading = false;
            },
            closeDialog() {
                this.$emit('closeDialog');
            },
            updateGroup(groupName, value) {
                let functionString = (value ? 'add' : 'remove') + groupName + 'Group';
                UsersRepository[functionString](this.userId)
                    .then(response => {
                        this.$store.dispatch('getUserData');
                        this.closeDialog();
                        this.$store.dispatch('setMessage', 'Die Rolle "' + groupName + '" wurde ' + (value ? 'hinzugefügt' : 'entfernt') + '.');
                        console.log(response);
                    })
            },
        },
        created() {
            this.fetch();
        },
        computed: {
            calculatedGroups() {
                let isAdmin = this.assignedGroups.filter(group => group.name === 'Admin').length > 0;
                let isEditor = this.assignedGroups.filter(group => group.name === 'Editor').length > 0;
                return this.groups.map(item => {
                    let disabled = true;
                    let text = '';
                    if (item.name === 'Admin') {
                        if (isEditor) { disabled = false; }
                        text = 'Projekte, Benutzer und Einstellungen verwalten.';
                    }
                    if (item.name === 'Editor') {
                        if (!isAdmin) { disabled = false; }
                        text = 'Beiträge lektorieren und Projekten zuordnen.';
                    }
                    if (item.name === 'Author') {
                        text = 'Beiträge verfassen und eigene Angaben bearbeiten. ';
                    }
                    return {
                        ...item,
                        active: this.assignedGroups.some(el => el.id === item.id) || false,
                        disabled: disabled,
                        text: text,
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
