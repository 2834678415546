import Repository from "./Repository";

const resource = "/bible";

export default {
    getBooks(){
        return Repository.get(`${resource}/books/?lang=de-DE`);
    },
    getChaptersByBook(book_id){
        return Repository.get(`${resource}/books/${book_id}/chapters/`);
    },
    getBookName(book_id){
        return Repository.get(`${resource}/books/${book_id}/?lang=de-DE`);
    }
}