<template>
    <v-dialog v-model="dialog" max-width="1600px" :fullscreen="$vuetify.breakpoint.xsOnly"  @click:outside="close" @keydown.esc="close">
        <v-card>
            <v-card-title>
                <span class="headline">Beitragsverlauf</span>
            </v-card-title>

            <v-card-text>
                <template v-if="!!article">
                    <ArticleHistory :article="article" :dialog="dialog"/>
                </template>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>

    import ArticleHistory from '@/components/articles/History'

    export default {
        name: "DialogArticleHistory",
        props: {
            dialog: Boolean,
            article: Object,
        },
        components: {
            ArticleHistory,
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
    }
</script>

<style scoped>

</style>
