import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify';
import Axios from 'axios'
import VueLuxon from "vue-luxon";
import AppSettings from '@/config/AppSettings'
import titleMixin from './mixins/titleMixin'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
// import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;
const access = localStorage.getItem(AppSettings.LOCAL_STORAGE_PREFIX + 'access');
if (access) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = access
}

Vue.mixin(titleMixin);

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
});

Vue.use(VueLuxon,{
    output: {
        locale: "de-DE",
        format: {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        },
    },
});

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app');
