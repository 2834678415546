<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <div v-if="loading" class="text-center">
                <v-progress-circular
                        class="my-16"
                        :size="50"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </div>
            <v-card v-else flat tile class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title>Beitragsvorschau
                        <span v-if="filteredItems.length > 0">
                            {{ filteredItems.length }}/{{ articles.length }}
                        </span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                            v-if="filteredItems.length > 0"
                            text
                            class="mr-4"
                            @click="filteredItems = []"
                    >
                        Alle anzeigen
                    </v-btn>

                    <v-switch
                            v-model="show_ids"
                            label="ID's"
                            dense
                            hide-details
                            class="mr-4"
                    ></v-switch>

                    <v-switch
                            v-model="show_lines"
                            label="Zeilennummern"
                            dense
                            hide-details
                    ></v-switch>
                </v-toolbar>

                <v-card class="mx-auto" style="max-width: 500px;" elevation="0">
                    <div id="printarea">
                        <div v-for="article in articles.filter((a) => filteredItems.includes(a.id) || filteredItems.length === 0)" :key="article.id">
                            <ArticlePreviewFull :article="article" :show_ids="show_ids" :show_lines="show_lines"/>
                        <div class="pagebreak"> </div>
                        </div>
    <!--                    <div class="pagebreak"> </div>-->
    <!--                    <ArticlePreviewFull v-if="articles[0]" :article="articles[0]" :show_ids="show_ids" :show_lines="show_lines"/>-->
    <!--                    <div class="pagebreak"> </div>-->
    <!--                    <ArticlePreviewFull v-if="articles[1]" :article="articles[1]" :show_ids="show_ids" :show_lines="show_lines"/>-->
    <!--                    <div class="pagebreak"> </div>-->
    <!--                    <ArticlePreviewFull v-if="articles[2]" :article="articles[2]" :show_ids="show_ids" :show_lines="show_lines"/>-->

                    </div>
                </v-card>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs}">
                        <v-btn
                                @click="printNow"
                                v-bind="attrs"
                                v-on="on"
                                absolute dark fab bottom right fixed color="primary" style="bottom: 25px; right: 25px;">
                            <v-icon>mdi-printer</v-icon>
                        </v-btn>
                    </template>
                    <span>Beiträge drucken</span>
                </v-tooltip>

            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import ArticlePreviewFull from "../../components/articles/ArticlePreviewFull";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "Articles",
        components: {
            MenuSetDefault,
            ArticlePreviewFull,
        },
        data() {
            return {
                articles: [],
                loading: true,
                show_ids: true,
                show_lines: false,
              filteredItems: [],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                let {data} = await ArticlesRepository.getFullArticlesByProject(this.$store.getters.currentProject);
                this.articles = data;
                this.loading = false;
            },
            printNow(){
              window.print();
            }
        },
        async created() {
            if (this.$store.state.tableSettings['/articles']) {
              if (this.$store.state.tableSettings['/articles'].selected && this.$store.state.tableSettings['/articles'].selected.length > 0)
                this.filteredItems = this.$store.state.tableSettings['/articles'].selected.map( a => a.id);
              else
                this.filteredItems = this.$store.state.tableSettings['/articles'].filteredItems;
            }
            await this.fetch();
        },

    }
</script>

<style>
    @media print {
        body * {
            visibility: hidden;
            float: none;
            /*overflow: auto;*/
            height: auto;
        }
        main {
            margin: auto;
            padding: 0 !important;
        }
        .scroll-y {
            height: auto;
            overflow: visible;
        }
        #printarea, #printarea * {
            visibility: visible;
        }
        #printarea {
            position: absolute;
            left: 0;
            top: 0;
        }
        .pagebreak {
            page-break-after: always;
        }
    }
    /*@page {*/
    /*    size: A4;*/
    /*    margin: 0;*/
    /*}*/
</style>
