import Repository from "./Repository";

const eventsResource = "/event_days";
const topicsResource = "/event_day_topics";
const yearSpecificResource = "/event_day_year_specific";

export default {
    get(){
        return Repository.get(`${eventsResource}/`);
    },
    getMonths() {
        return Repository.get(`${eventsResource}/months/`);
    },
    getEventsPerMonth(year, month){
        return Repository.get(`${eventsResource}/calendar/?year=${year}&month=${month}`);
    },
    getEventTopics() {
        return Repository.get(`${topicsResource}/`);
    },
    add(name, fix=true, day=false, month=false, topics=false, date_rule=false, description=false){
        let payload = {'name': name};

        if(fix){
            payload.fix = true;
            payload.day = day;
            payload.month = month;
        }
        else {
            payload.fix = false;
            payload.date_rule = date_rule;
        }

        if(topics) payload.topics = topics;
        if(description) payload.description = description;

        return Repository.post(`${eventsResource}/`, payload);
    },
    update(id, name, fix=true, day=false, month=false, topics=false, date_rule=false, description=false){
        let payload = {'name': name};

        if(fix){
            payload.fix = true;
            payload.day = day;
            payload.month = month;
            payload.date_rule = '';
        }
        else{
            payload.fix = false;
            payload.day = null;
            payload.month = null;
            payload.date_rule = date_rule;
        }

        if(topics) payload.topics = topics;
        if(description) payload.description = description;

        return Repository.put(`${eventsResource}/${id}/`, payload);
    },
    addTopic(name) {
        let payload = {'name': name};
        return Repository.post(`${topicsResource}/`, payload);
    },
    addYearSpecific(event_day_id, year, day, month) {
        let payload = {
            'event_day': event_day_id,
            'year': year,
            'day': day,
            'month': month
        };
        return Repository.post(`${yearSpecificResource}/`, payload);
    },
    deleteYearSpecific(id) {
        return Repository.delete(`${yearSpecificResource}/${id}/`);
    },
    getDays(topic_id){
        return Repository.get(`${topicsResource}/${topic_id}/days/`);
    }
}
