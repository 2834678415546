<template>
    <v-card class="text-center">
        <v-toolbar color="primary" dark class="elevation-0">
            <v-btn fab elevation="0" icon>
                <v-icon color="white" large @click="prev">mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ current | luxon("EEE DD") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab icon>
                <v-icon color="white" large @click="next">mdi-chevron-right</v-icon>
            </v-btn>
        </v-toolbar>
        <div class="text-center">
            <v-progress-circular
                    v-if="loading"
                    class="my-16"
                    :size="50"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>
        <div v-if="!loading">
            <v-sheet color="accent">
                <v-card-text class="pa-5">
                    <h1>{{ article.title }}</h1>
                    <br>
                    <div class="font-italic">
                        {{ article.votd }}
                    </div>
                    <small>{{ article.votd_reference }}</small>
                </v-card-text>
            </v-sheet>
            <v-card-text class="px-10 pt-10 text-left">
                <div class="text-left article-text-preview">
                    <span v-html="article.text">{{ article.text }}</span>
                    <small>{{ article.writer__first_name }} {{ article.writer__last_name }}</small></div>
                <div class="mt-5" v-if="!!article.question">
                    <div class="font-weight-bold font-italic">
                        <v-btn
                                color="primary"
                                fab
                                x-small
                                elevation="0"
                                dark
                                class="mb-1 mr-2"
                        >
                            <v-icon>mdi-help</v-icon>
                        </v-btn>
                        {{ article.question }}
                    </div>

                    <div class="font-weight-bold font-italic my-2">
                        <v-btn
                                color="primary"
                                fab
                                x-small
                                elevation="0"
                                dark
                                class="mb-1 mr-2"
                        >
                            <v-icon>mdi-exclamation-thick</v-icon>
                        </v-btn>
                        {{ article.hint }}
                    </div>

                    <div class="font-weight-bold font-italic">
                        <v-btn
                                color="primary"
                                fab
                                x-small
                                elevation="0"
                                dark
                                class="mr-2"
                        >
                            <v-icon>mdi-book</v-icon>
                        </v-btn>
                        {{ article.recommended_bible_reference }}
                    </div>
                </div>
            </v-card-text>
        </div>
        <v-card-actions>
            <v-btn fab elevation="0" icon>
                <v-icon color="primary" large @click="prev">mdi-chevron-left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn fab icon>
                <v-icon color="primary" large @click="next">mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import DateTime from 'luxon/src/datetime.js'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "WidgetDailyArticle",
        data() {
            return {
                loading: true,
                article: {},
                votd_ref: '',
                current: DateTime.local().toFormat("yyyy-LL-dd"),
            }
        },
        created() {
            this.getArticle();
        },
        methods: {
            async getArticle() {
                this.loading = true;
                const {data} = await ArticlesRepository.getByDate(this.current);
                this.article = data;
                this.loading = false;
            },
            next() {
                this.current = DateTime.fromJSDate(new Date(this.current)).plus({days: 1}).toFormat("yyyy-LL-dd");
                this.getArticle();
            },
            prev() {
                this.current = DateTime.fromJSDate(new Date(this.current)).minus({days: 1}).toFormat("yyyy-LL-dd");
                this.getArticle();
            },
        },
    }
</script>

<style scoped>

</style>
