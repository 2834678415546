<template>
    <v-dialog v-model="dialog" max-width="500px" @click:outside="close" @keydown.esc="close">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedItem.name"
                                    label="Name*"
                                    :rules="[v => !!v || 'Name ist ein Pflichtfeld']"
                                    required
                                    filled
                                ></v-text-field>
                            <v-col cols="12">
                            </v-col>
                                <v-text-field
                                    v-model.number="editedItem.year"
                                    label="Jahr*"
                                    :rules="[
                                        v => !!v || 'Jahr ist ein Pflichtfeld',
                                        v => !!v && v >= 1984 || 'Bitte ein gültiges 4-stelliges Jahr angeben.',
                                        v => !!v && v <= 2100 || 'Bitte ein gültiges 4-stelliges Jahr angeben.',
                                        ]"
                                    required
                                    filled
                                    append-outer-icon="mdi-plus"
                                    @click:append-outer="increment()"
                                    prepend-icon="mdi-minus"
                                    @click:prepend="decrement()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="editedItem.introduction"
                                    label="Einführung"
                                    rows="5"
                                    auto-grow
                                    filled
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="editedItem.conclusion"
                                    label="Abschluss"
                                    rows="5"
                                    auto-grow
                                    filled
                                ></v-textarea>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-checkbox
                                    v-model="editedItem.editable"
                                    label="bearbeitbar"
                                    ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-checkbox
                                    v-model="editedItem.finalized"
                                    label="finalisiert"
                                    ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Schließen</v-btn>
                    <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ProjectRepository = RepositoryFactory.get('projects');

    export default {
        name: "DialogProject",
        props: {
            dialog: Boolean,
            editedItem: Object,
            editedIndex: Number,
            formTitle: String,
        },
        data() {
            return {
                loading: false,
                valid: true,
            }
        },
        methods: {
            async save() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    if (this.editedIndex === -1) {
                        ProjectRepository.add(this.editedItem.name, this.editedItem.year, this.editedItem.editable, this.editedItem.finalized, this.editedItem.introduction, this.editedItem.conclusion)
                            .then(response => {
                                this.saved(response.data);
                                this.loading = false;
                                this.$store.dispatch('setMessage', 'Der Themenbereich "' + this.editedItem.name + '" wurde erfolgreich gespeichert.');
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    console.log(err);
                                    this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                }
                            );
                    } else {
                        ProjectRepository.update(this.editedItem.id, this.editedItem.name, this.editedItem.year, this.editedItem.editable, this.editedItem.finalized, this.editedItem.introduction, this.editedItem.conclusion)
                            .then(response => {
                                this.saved(response.data);
                                this.loading = false;
                                this.$store.dispatch('setMessage', 'Der Themenbereich "' + this.editedItem.name + '" wurde erfolgreich geändert.');
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    console.log(err);
                                    this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                }
                            );
                    }

                }
            },
            saved(item) {
                this.$emit('saved', item);
            },
            close() {
                this.$emit('close', this.editedItem);
            },
            reset() {
                this.$refs.form.reset()
            },
            increment () {
                this.editedItem.year = parseInt(this.editedItem.year,10) + 1
            },
            decrement () {
                this.editedItem.year = parseInt(this.editedItem.year,10) - 1
            }
        }
    }
</script>

<style scoped>

</style>
