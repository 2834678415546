<template>
    <v-snackbar v-model="show" :timeout="timeout" top :multi-line="multiLine" rounded="pill">
        <div v-html="message"></div>
        <template v-slot:action="{ attrs }">
            <v-btn color="primary" text v-bind="attrs" @click="show = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {
        name: "MessageService",
        data() {
            return {
                timeout: 5000,
                show: false,
                message: '',
                multiLine: false,
            }
        },
        created() {
            this.$store.watch(state => state.snackbar.snack, () => {
                const msg = this.$store.state.snackbar.snack;
                if (msg !== '') {
                    this.show = true;
                    this.message = this.$store.state.snackbar.snack;
                    this.$store.dispatch('setMessage', '');
                }
            })
        }
    }
</script>

<style scoped>

</style>