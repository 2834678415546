<template>
    <v-dialog v-model="dialog" max-width="700px" persistent
              :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span v-if="recipient !== undefined" class="headline">Nachricht an {{ recipient.full_name }}  senden</span>
                <span v-if="article !== undefined" class="headline">Nachricht an den Autor senden</span>
            </v-card-title>
            <v-card-text>
                <div v-if="article !== undefined && article.writer_data !== undefined" class="mb-4">
                    Autor: {{ article.writer_data.first_name }} {{ article.writer_data.last_name }}<br>
                    Beitrag: {{ article.title }}
                </div>
                    <tiptap-vuetify
                            @init="onInitTiptap"
                            :card-props="{ flat: true, color: '#d8dad7', 'min-height': 300 }"
                            :toolbar-attributes="{ color: 'primary', dark: true }"
                            v-model="message"
                            :extensions="extensions"
                    />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Schließen</v-btn>
                <v-btn color="primary" elevation="0" @click="send">Email Senden</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import { TiptapVuetify, Heading, Bold, Italic, Underline, BulletList, OrderedList, ListItem, Link, Paragraph } from 'tiptap-vuetify'

    const UsersRepository = RepositoryFactory.get('users');
    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "DialogSendMail",
        props: {
            dialog: Boolean,
            recipient: Object,
            article: Object,
        },
        components: { TiptapVuetify },
        data() {
            return {
                loading: false,
                valid: true,
                message: '',
                tiptapEditor: null,
                tiptapIsValid: false,
                tiptapText: '',
                tiptapIsEmpty: false,
                extensions: [
                    Bold,
                    Italic,
                    Underline,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    ListItem,
                    BulletList,
                    OrderedList,
                    Link,
                    Paragraph,
                    ]
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            async send() {
                this.tiptapText = this.htmlToText(this.tiptapEditor.getHTML())

                this.tiptapIsEmpty = this.tiptapText === '';
                this.tiptapIsValid = !this.tiptapIsEmpty;

                if (this.tiptapIsValid) {
                    this.loading = true;
                    let response = {};

                    if (this.article !== undefined){
                        response = await ArticlesRepository.sendMailToAuthor(this.article.id, this.message);
                    } else {
                        response = await UsersRepository.sendMail(this.recipient.id, this.message);
                    }
                    if (response.status === 200) {
                        await this.$store.dispatch('setMessage', 'Die Nachricht wurde per Mail verschickt.');
                        this.close();
                        this.loading = false;
                        this.message = '';
                    } else {
                        await this.$store.dispatch('setMessage', 'Beim Versenden der Nachricht ist ein Fehler aufgetreten.');
                    }
                }
            },
            onInitTiptap(e){
                this.tiptapEditor = e.editor
            },
            htmlToText (html) {
                return html.replace(/<[^>]*>?/gm, '')
            }
        },
    }
</script>

<style scoped>

</style>
