var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('MenuSetDefault'),_c('v-main',[_c('v-card',{staticClass:"elevation-0"},[_c('v-toolbar',{staticClass:"elevation-0",attrs:{"dense":"","color":"accent"}},[_c('v-toolbar-title',[_vm._v("Ereignisse")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"absolute":"","dark":"","fab":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){return _vm.editItem()}}},'v-btn',tooltipAttrs,false),tooltip),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Ereignis erstellen")])])],1),_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Suchen","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Daten werden geladen... Bitte warten","headers":_vm.headers,"items":_vm.computedEventDays,"search":_vm.search,"footer-props":{
                            'items-per-page-options': [10, 25, 50, -1]
                        },"items-per-page":25},scopedSlots:_vm._u([{key:"item.fix",fn:function(ref){
                        var item = ref.item;
return [(item.fix)?_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" fix ")]):_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" flex ")])]}},{key:"item.sort_date",fn:function(ref){
                        var item = ref.item;
return [(item.fix)?[_vm._v(" "+_vm._s(String(item.day).padStart(2, '0'))+"."+_vm._s(String(item.month).padStart(2, '0'))+". ")]:[(item.year_specific.length > 0)?_c('v-btn',{attrs:{"small":"","elevation":"0"},on:{"click":function($event){return _vm.showDates(item.id)}}},[_vm._v(" "+_vm._s(item.year_specific.length)+" Termine hinterlegt "),(_vm.datesToShow.includes(item.id))?_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-up")]):_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"small":"","elevation":"0","disabled":""}},[_vm._v(" keine Termine hinterlegt "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-close")])],1),(_vm.datesToShow.includes(item.id))?_c('dl',_vm._l((item.year_specific),function(element,index){return _c('dt',{key:index},[_vm._v(" "+_vm._s(element.year)+" ⇒ "+_vm._s(String(element.day).padStart(2, '0'))+"."+_vm._s(String(element.month).padStart(2, '0'))+". ")])}),0):_vm._e()]]}},{key:"item.exists",fn:function(ref){
                        var item = ref.item;
return [(_vm.getEventDateOfYear(item.year_specific, _vm.yearToCheck) || item.fix)?_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":"green darken-1"}},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.yearToCheck)+" ")],1):_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_c('v-icon',{attrs:{"left":"","small":"","color":"red darken-1"}},[_vm._v("mdi-close-circle")]),_vm._v(" "+_vm._s(_vm.yearToCheck)+" ")],1)]}},{key:"item.topics_list",fn:function(ref){
                        var item = ref.item;
return _vm._l((item.topics_data),function(element,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(element.name))])})}},{key:"top",fn:function(){return [_c('DialogEvent',{attrs:{"dialog":_vm.dialog,"editedItem":_vm.editedItem,"formTitle":_vm.formTitle,"editedIndex":_vm.editedIndex},on:{"saved":_vm.saved,"close":_vm.close}}),_c('DialogEventYearSpecific',{attrs:{"dialog":_vm.dialogYearSpecific,"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex},on:{"savedYearSpecific":_vm.savedYearSpecific,"close":_vm.closeYearSpecific}})]},proxy:true},{key:"item.id",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var tooltip = ref.on;
                        var tooltipAttrs = ref.attrs;
return [(item.fix === false)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editItemYearSpecific(item)}}},'v-icon',tooltipAttrs,false),tooltip),[_vm._v(" mdi-calendar-month-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("variable Ereignisse konfigurieren")])]),_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-square-edit-outline ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }