function getTitle (vm) {
    const { pageTitle } = vm.$options;
    if (pageTitle) {
        return typeof pageTitle === 'function'
            ? pageTitle.call(vm)
            : pageTitle
    }
}

export default {
    created () {
        const pageTitle = getTitle(this);
        if (pageTitle) {
            document.title = pageTitle
        }
    },
    methods: {
        setPageTitle(title) {
            document.title = title;
        }
    }
}