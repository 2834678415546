<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Themenbereiche</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Themenbereich erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field style="max-width: 250px"
                                  v-model="search"
                                  append-icon="mdi-magnify"
                                  label="Suchen"
                                  single-line
                                  hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                        :loading="loading" loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="topics"
                        :items-per-page="10"
                        :search="search"
                >
                    <template v-slot:top>
                        <DialogTopic
                                :dialog="dialog"
                                :editedItem="editedItem"
                                :formTitle="formTitle"
                                :editedIndex="editedIndex"
                                @saved="saved"
                                @close="close"
                        />
                    </template>
                    <template v-slot:item.img="{ item }">
                        <v-img
                        v-if="item.img_b64 != null"
                        contain
                        max-height="50"
                        max-width="100"
                        :src="item.img_b64"
                        ></v-img>
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-icon class="mr-2" @click="editItem(item)">
                            mdi-square-edit-outline
                        </v-icon>
<!--                        <v-icon @click="deleteItem(item)" >-->
<!--                            mdi-delete-outline-->
<!--                        </v-icon>-->
                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    import DialogTopic from "@/components/settings/DialogTopic";

    const TopicsRepository = RepositoryFactory.get('topics');

    export default {
        name: "Topics",
        components: {
            MenuSetDefault,
            DialogTopic,
        },
        props: {
            source: String,
        },
        data() {
            return {
                isShowForm: false,
                name: '',
                search: '',
                topics: [],
                loading: true,
                headers: [
                    {text: 'Symbol', value: 'img', width: 100},
                    {text: 'Themenbereich', value: 'name'},
                    {text: 'Aktionen', value: 'id', align: 'right'},

                ],
                dialog: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    name: '',
                },
                defaultItem: {
                    id: null,
                    name: '',
                    img_b64: null,
                },
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await TopicsRepository.get();
                this.topics = data;
                this.loading = false;
            },
            // createTopic: function () {
            //     TopicsRepository.create(this.name)
            //         .then(response => {
            //             this.topics.push(response.data);
            //             this.name = '';
            //             this.isShowForm = false;
            //         })
            //         .catch(err => console.log(err))
            // },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.topics.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.topics[this.editedIndex], editedItem)
                } else {
                    this.topics.push(editedItem);
                }
                this.close()
            },
            deleteItem (item) {
                this.editedIndex = this.topics.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.topics.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? 'Neuen Themenbereich anlegen' : 'Themenbereich bearbeiten'
            },
        },
        created() {
            this.fetch();
        },
    }
</script>
