
import articlesRepository from "./articlesRepository"
import bibleRepository from "./bibleRepository"
import bibleTranslationsRepository from "./bibleTranslationsRepository"
import churchesRepository from "./churchesRepository"
import configsRepository from "./configsRepository"
import eventsRepository from "./eventsRepository"
import groupsRepository from "./groupsRepository";
import profileRepository from "./profileRepository";
import projectsRepository from "./projectsRepository"
import topicsRepository from "./topicsRepository"
import usersRepository from "./usersRepository"

const repositories = {
    articles: articlesRepository,
    bible: bibleRepository,
    bible_translations: bibleTranslationsRepository,
    churches: churchesRepository,
    configs: configsRepository,
    events: eventsRepository,
    groups: groupsRepository,
    profile: profileRepository,
    projects: projectsRepository,
    topics: topicsRepository,
    users: usersRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};