import Repository from "./Repository";

const resource = "/bible_translations";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    add(name, name_full=false){
        let payload = {'name': name}
        if (name_full) payload.name_full = name_full;
        return Repository.post(`${resource}/`, payload);
    },
    update(id, name, name_full=false){
        let payload = {'name': name};

        if(name_full) payload.name_full = name_full;
        else payload.name_full = "";
        
        return Repository.put(`${resource}/${id}/`, payload);
    }
}