import Repository from "./Repository";

const resource = "/churches";

export default {
    get(){
        return Repository.get(`${resource}/`);
    },
    add(name, name_full=false, website=false){
        let payload = {'name': name};
        if(name_full) payload.name_full = name_full;
        if(website) payload.website = website;
        return Repository.post(`${resource}/`, payload);
    },
    update(id, name, name_full=false, website=false){
        let payload = {'name': name};

        if(name_full) payload.name_full = name_full;
        else payload.name_full = "";

        if(website) payload.website = website;
        else payload.website = "";
        
        return Repository.put(`${resource}/${id}/`, payload);
    }
}