<template>
    <v-navigation-drawer v-model="menuDrawer" app clipped class="menu-drawer">
        <v-list dens class="py-0">
            <v-list-group
                    v-for="item in visibleMenuItems"
                    v-model="item.active"
                    v-bind:key="item.title"
                    :prepend-icon="item.icon"
                    :append-icon="!item.items ? '' : undefined"
                    :to="item.path"
                    no-action
            >
                <template v-slot:activator>
                    <v-list-item-content @click="item.path ? $router.push({path: `${item.path}`}) : undefined">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item v-for="subItem in item.items" v-bind:key="subItem.title" :to="subItem.path">
                    <v-list-item-content>
                        <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list-group>
        </v-list>

        <template v-slot:append>
            <v-list dens class="py-0">
                <v-list-item link :to="{path: '/my-articles', query: {openDialog: true}}">
                    <v-list-item-action>
                        <v-icon color="grey darken-1">mdi-plus-circle-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-title class="grey--text text--darken-1">Neuer Beitrag</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item v-if="isLoggedIn" @click="logout" link>
                    <v-list-item-icon>
                        <v-icon color="grey darken-1">mdi-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="grey--text text--darken-1">Abmelden</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>

    export default {
        name: "MenuLeftDrawer",
        props: {
            drawer: Boolean,
        },
        data() {
            return {
                menuItems: [
                    {
                        icon: 'mdi-view-dashboard',
                        title: 'Übersicht',
                        path: '/',
                        display: this.$store.getters.isLoggedIn,
                        items: false,
                    },
                    {
                        icon: 'mdi-text-box-multiple',
                        title: 'Beiträge',
                        path: '/articles',
                        display: this.$store.getters.isEditor,
                        items: [
                            {
                                icon: 'mdi-text-box-multiple',
                                title: 'Zugeordnet',
                                path: '/articles',
                                items: false,
                                display: this.$store.getters.isEditor,
                            },
                            {
                                icon: 'mdi-text-box-multiple',
                                title: 'Offen',
                                path: '/unassigned-articles',
                                items: false,
                                display: this.$store.getters.isEditor,
                            },
                            {
                                icon: 'mdi-calendar',
                                title: 'Kalender',
                                path: '/calendar',
                                items: false,
                                display: this.$store.getters.isEditor,
                            },
                            {
                                icon: 'mdi-text-box-search-outline',
                                title: 'Suche',
                                path: '/article-search',
                                items: false,
                                display: this.$store.getters.isEditor,
                            },
                            {
                              icon: 'mdi-archive-outline',
                              title: 'Archiv',
                              path: '/article-archive',
                              items: false,
                              display: this.$store.getters.isEditor,
                            },
                        ],
                    },
                    {
                        icon: 'mdi-account-box-multiple',
                        title: 'Benutzer',
                        path: '/users',
                        display: this.$store.getters.isAdmin,
                        items: false,
                    },
                    {
                        icon: 'mdi-text-box-multiple-outline',
                        title: 'Meine Beiträge',
                        path: '/my-articles',
                        display: this.$store.getters.isLoggedIn,
                        items: false,
                    },
                    {
                        icon: 'mdi-account-circle',
                        title: 'Mein Profil',
                        path: '/me',
                        display: this.$store.getters.isLoggedIn && !this.$store.getters.isEditor,
                        items: false,
                    },
                    {
                        icon: 'mdi-book-cog-outline',
                        title: 'Projekt',
                        path: '/project',
                        items: false,
                        display: this.$store.getters.isAdmin,
                    },
                    {
                        icon: 'mdi-cog',
                        title: 'Einstellungen',
                        display: this.$store.getters.isAdmin,
                        items: [
                            {
                                title: 'Projekte',
                                icon: 'mdi-folder-open',
                                path: '/projects',
                                parent: 'Einstellungen',
                                display: this.$store.getters.isAdmin
                            },
                            {
                                title: 'Gemeinden',
                                icon: 'mdi-church',
                                path: '/churches',
                                parent: 'Einstellungen',
                                display: this.$store.getters.isAdmin
                            },
                            {
                                title: 'Ereignisse',
                                icon: 'mdi-calendar-star',
                                path: '/events',
                                parent: 'Einstellungen',
                                display: this.$store.getters.isAdmin
                            },
                            {
                                title: 'Übersetzungen',
                                icon: 'mdi-book-cross',
                                path: '/translations',
                                parent: 'Einstellungen',
                                display: this.$store.getters.isAdmin
                            },
                            {
                                title: 'Themen',
                                icon: 'mdi-shape',
                                path: '/topics',
                                parent: 'Einstellungen',
                                display: this.$store.getters.isAdmin},
                        ],
                    },
                ],
            }
        },
        computed: {
            visibleMenuItems() {
                return this.menuItems.filter(function (el) {
                    return el.display === true
                });
            },
            isLoggedIn: function () {
                return this.$store.getters.isLoggedIn
            },
            isAuthor: function () {
                return this.$store.getters.isAuthor
            },
            isEditor: function () {
                return this.$store.getters.isEditor
            },
            isAdmin: function () {
                return this.$store.getters.isAdmin
            },
            menuDrawer: {
                get: function () {
                    return this.drawer;
                },
                set: function (newValue) {
                    this.$emit('updateDrawer', newValue);
                }
            },
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push('/login');
                        this.$store.dispatch('setMessage', 'Sie wurden erfolgreich abgemeldet.');
                    })
            },
            activateParent: function () {
                this.menuItems.map(
                    (item, index) => {
                        if (item.items) {
                            item.items.map((subItem) => {
                                if (subItem.path === this.$route.path) {
                                    this.menuItems[index].active = true;
                                }
                            });
                        } else {
                            if (item.path === this.$route.path) {
                                this.menuItems[index].active = true;
                            }
                        }
                    })
            }
        },
        created() {
            this.activateParent();
        }
    }
</script>

<style scoped>
    .v-toolbar__content, .v-toolbar__title {
        color: white;
    }

    .v-toolbar__content .v-btn--outlined .v-btn__content .v-icon,
    .v-toolbar__content .v-btn--round .v-btn__content .v-icon {
        color: white;
    }
</style>
