<template>
    <v-navigation-drawer
            v-model="drawerOpen"
            @input="onInput"
            width="350px"
            class="d-flex comment-drawer"
            app clipped right
    >
        <div v-if="comment_author">
            <v-card class="elevation-2 mx-4 mt-5" color="primary" dark shaped>
                <v-card-text>
                    <div class="comment-text" style="white-space: pre-wrap;">{{ comment_author }}</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="comment-author mr-3">
                        <small>{{ author.first_name }} {{ author.last_name }}</small>
                    </div>
                </v-card-actions>
            </v-card>
            <div class="mx-4 comment-date">
                <small>{{ created_at | luxon("DD T") }}</small>
            </div>
        </div>
        <div v-for="comment in comments" :key="comment.id">
            <v-card class="elevation-2 mx-4 mt-5" color="accent" shaped>
                <v-card-text>
                    <div class="comment-text" style="white-space: pre-wrap;">{{ comment.text }}</div>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="comment-author mr-3">
                        <small>{{ comment.author_data.first_name }} {{comment.author_data.last_name }}</small>
                    </div>
                </v-card-actions>
            </v-card>
            <div class="mx-4 comment-date">
                <small>{{ comment.created_at | luxon("DD T") }}</small>
            </div>
        </div>

        <div v-if="comments.length === 0 && !comment_author" class="text-center my-10">
            <v-icon size="150">mdi-comment-search-outline</v-icon>
            <div>Noch keine Kommentare vorhanden.</div>
        </div>

        <template v-slot:append>
            <v-divider></v-divider>
            <v-textarea
                    dense
                    class="mr-2 no-radius"
                    v-model="message"
                    :append-outer-icon="'mdi-send'"
                    filled
                    required
                    clear-icon="mdi-close"
                    clearable
                    hide-details
                    label="Nachricht"
                    rows="1"
                    auto-grow
                    v-on:keydown.meta.enter.capture.prevent.stop="sendMessage"
                    v-on:keydown.ctrl.enter="sendMessage"
                    @click:append-outer="sendMessage"
                    @click:clear="clearMessage"
            ></v-textarea>
        </template>

    </v-navigation-drawer>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "ArticleComments",
        props: {
            open: {
                type: Boolean,
                default: false,
            },
            articleId: Number,
            comment_author: String,
            author: Object,
            created_at: String,
        },
        data() {
            return {
                drawerOpen: this.open,
                comments: {},
                message: '',
            }
        },
        watch: {
            open(newVal) {
                this.drawerOpen = newVal
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await ArticlesRepository.getComments(this.articleId);
                this.comments = data;
                this.$emit('commentCount', this.comments.length);
                this.loading = false;
            },
            onInput(isOpen) {
                this.$emit('drawerOpened', isOpen)
            },
            sendMessage() {
                if (this.message !== '') {
                    ArticlesRepository.addComment(this.articleId, this.message)
                        .then(response => {
                            this.comments.unshift(response.data);
                            this.$store.dispatch('setMessage', 'Der Kommentar wurde gespeichert.');
                            this.$emit('commentCount', this.comments.length);
                        })
                        .catch(err => {
                            console.log(err);
                            this.$store.dispatch('setMessage', 'Es ist ein Fehler aufgetreten.');
                        });
                } else {
                    this.$store.dispatch('setMessage', 'Bitte geben Sie einen Kommentar ein.');
                }
                this.clearMessage();
            },
            clearMessage() {
                this.message = '';
            },

        },
        created() {
            this.fetch();
        },
    }
</script>

<style scoped>
    .comment-drawer >>> .no-radius {
        border-radius: 0;
    }
    .comment-date {
        color: gray;
    }
</style>
