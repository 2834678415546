var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[(_vm.loading)?_c('v-progress-circular',{staticClass:"my-10",attrs:{"size":50,"color":"primary","indeterminate":""}}):_vm._e(),(!_vm.loading)?_c('div',[_c('v-select',{staticClass:"mb-4 elevation-0",attrs:{"items":_vm.filter_fields,"menu-props":"auto","label":"Filter wählen","hide-details":"","prepend-icon":"mdi-filter","single-line":"","solo":"","dense":""},model:{value:(_vm.filter_selected),callback:function ($$v) {_vm.filter_selected=$$v},expression:"filter_selected"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.histories_filtered,"items-per-page":15,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("luxon")(item.created_at,"DD T")))])]}},{key:"item.user_data.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_data.last_name)+", "+_vm._s(item.user_data.first_name)+" ")]}},{key:"item.is_initial",fn:function(ref){
var item = ref.item;
return [(item.is_initial)?_c('v-icon',{attrs:{"small":"","color":"green darken-1"},on:{"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); }}},[_vm._v("mdi-check-circle ")]):_vm._e()]}},{key:"item.output_field",fn:function(ref){
var item = ref.item;
return [(item.field === 'text' || item.field === 'votd')?_c('div',[_c('span',[_c('i',[_vm._v(_vm._s(item.output_field))]),_vm._v(": ")]),(item.content<8)?_c('span',[_vm._v(_vm._s(item.content))]):_c('span',[_vm._v(_vm._s(item.content.substring(0,100)+"..."))])]):_c('span',[_c('i',[_vm._v(_vm._s(item.output_field))]),_vm._v(" ⇒ "),_c('b',[_vm._v(_vm._s(item.output_value))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"elevation-0",attrs:{"colspan":headers.length}},[_c('ArticleHistoryDetail',{attrs:{"article":_vm.article,"history":item}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(!item.is_initial)?_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","primary":"","small":""},on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v(" Details "),_c('v-icon',{class:['v-data-table__expand-icon', { 'v-data-table__expand-icon--active': isExpanded }]},[_vm._v("$expand ")])],1):_vm._e()]}}],null,false,2990741011)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }