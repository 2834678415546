<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-toolbar-title>Zusammenfassung</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-progress-linear
                v-model="progress"
                color="accent darken-1"
                height="25"
        >
            <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}% Abgeschlossen</strong>
            </template>
        </v-progress-linear>

        <v-card-text>
        <v-simple-table>
            <template v-slot:default>
                <tbody>
                <tr>
                    <td>Name</td>
                    <td>{{ project.name }}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Jahr</td>
                    <td>{{ project.year }}</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Bearbeitbar</td>
                    <td></td>
                    <td>
                        <v-icon v-if="project.editable" color="green darken-1">
                            mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </td>
                </tr>
                <tr>
                    <td>Abgeschlossen</td>
                    <td></td>
                    <td>
                        <v-icon v-if="project.finalized" color="green darken-1">
                            mdi-check-circle
                        </v-icon>
                        <v-icon v-else color="primary">mdi-close-circle</v-icon>
                    </td>
                </tr>
                <tr>
                    <td>Beiträge im Projekt</td>
                    <td>
                        {{ stats.project_article_count }}

                    </td>
                    <td>
                        <span v-if="stats.project_article_count >= stats.project_day_count">
                            <v-icon color="green darken-1">
                                mdi-check-circle
                            </v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_count }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Status "Überarbeitet"</td>
                    <td>
                        {{ stats.project_article_revised }}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Status "Freigegeben"</td>
                    <td>
                        {{ stats.project_article_approved }}
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Status "Finalisiert"</td>
                    <td>
                        {{ stats.project_article_finalized }}
                    </td>
                    <td>
                        <span v-if="stats.project_article_finalized >= stats.project_day_count">
                            <v-icon color="green darken-1">mdi-check-circle</v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_finalized }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Beiträge mit Datum</td>
                    <td>
                        {{ stats.project_article_date_assigned }}
                    </td>
                    <td>
                        <span v-if="stats.project_article_date_assigned === stats.project_day_count">
                            <v-icon color="green darken-1">mdi-check-circle</v-icon>
                        </span>
                        <span v-else>({{ stats.project_day_count - stats.project_article_date_assigned }} fehlen)</span>
                    </td>
                </tr>
                <tr>
                    <td>Themenbereiche</td>
                    <td>
                        <span v-if="topics.length === 0 || (topics.length === 1 && topics[0].topic_count === 0)">
                            0
                        </span>
                        <span v-else>{{ stats.topics.length }}</span>
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Nicht zugeordnete Beiträge</td>
                    <td>
                        {{ article_stats.count_unused }}
                    </td>
                    <td></td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "WidgetProjectSummary",
        props: {
            project: Object,
            topics: Array,
            progress: Number,
            stats: Object,
            article_stats: Object,
        },
    }
</script>

<style scoped>

</style>
