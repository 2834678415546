<template>
    <v-dialog v-model="dialog" max-width="500px" @click:outside="close" @keydown.esc="close">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editedItem.name"
                                    label="Name*"
                                    :rules="[v => !!v || 'Name ist ein Pflichtfeld']"
                                    required
                                    filled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="editedItem.fix"
                                    label="festes Datum"
                                    ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="editedItem.fix">
                                <v-text-field
                                    v-model.number="editedItem.day"
                                    label="Tag"
                                    :rules="[
                                        v => editedItem.fix && (!!v && v >= 1) || 'Bitte einen gültigen Tag angeben.',
                                        v => editedItem.fix && (!!v && v <= 31) || 'Bitte einen gültigen Tag angeben.',
                                        ]"
                                    filled
                                    min = 1
                                    max = 31
                                    append-outer-icon="mdi-plus"
                                    @click:append-outer="increment()"
                                    prepend-icon="mdi-minus"
                                    @click:prepend="decrement()"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="editedItem.fix">
                                <v-select
                                    v-model="editedItem.month"
                                    label="Monat"
                                    :items="months"
                                    item-value="id"
                                    item-text="name"
                                    :rules="[
                                        v => editedItem.fix && !!v || 'Bitte einen Monat angeben.',
                                        ]"
                                    filled
                                ></v-select>
                            </v-col>
                            <v-col cols="12" v-if="!editedItem.fix">
                                <v-text-field
                                        v-model="editedItem.date_rule"
                                        label="Datumsregel"
                                        filled
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-combobox
                                    v-model="editedItem.topics_data"
                                    :items="eventTopics"
                                    item-value="id"
                                    item-text="name"
                                    label="Themen"
                                    filled
                                    multiple
                                    small-chips
                                ></v-combobox>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                        v-model="editedItem.description"
                                        label="Beschreibung"
                                        auto-grow
                                        filled
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Schließen</v-btn>
                    <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const EventsRepository = RepositoryFactory.get('events');

    export default {
        name: "DialogEvent",
        props: {
            dialog: Boolean,
            editedItem: Object,
            editedIndex: Number,
            formTitle: String,
        },
        data() {
            return {
                loading: false,
                valid: true,
                months: [
                    {'id':1, 'name':'Januar'},
                    {'id':2, 'name':'Februar'},
                    {'id':3, 'name':'März'},
                    {'id':4, 'name':'April'},
                    {'id':5, 'name':'Mai'},
                    {'id':6, 'name':'Juni'},
                    {'id':7, 'name':'Juli'},
                    {'id':8, 'name':'August'},
                    {'id':9, 'name':'September'},
                    {'id':10, 'name':'Oktober'},
                    {'id':11, 'name':'November'},
                    {'id':12, 'name':'Dezember'},
                ],
                eventTopics: [],
            }
        },
        methods: {
            async save() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    const promises = [];
                    let topics_for_save = [];

                    if (this.editedItem.fix || this.editedItem.date_rule === null) {
                        this.editedItem.date_rule = '';
                    }

                    this.editedItem.topics_data.forEach(topic => {
                        if (typeof topic === 'string' || topic instanceof String){
                            promises.push(EventsRepository.addTopic(topic));
                        }else{
                            topics_for_save.push(topic.id)
                        }
                    });

                    Promise.all(promises)
                        .then((results) => {
                            results.forEach(result =>{
                                topics_for_save.push(result.data.id)
                            })

                            if (this.editedIndex === -1) {
                                EventsRepository.add(this.editedItem.name, this.editedItem.fix, this.editedItem.day, this.editedItem.month, topics_for_save, this.editedItem.date_rule, this.editedItem.description)
                                    .then(response => {
                                        this.saved(response.data);
                                        this.loading = false;
                                        this.$store.dispatch('setMessage', 'Das Ereignis "' + this.editedItem.name + '" wurde erfolgreich gespeichert.');
                                    })
                                    .catch(err => {
                                            this.close();
                                            this.loading = false;
                                            console.log(err);
                                            this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                        }
                                    );
                            } else {
                                EventsRepository.update(this.editedItem.id, this.editedItem.name, this.editedItem.fix, this.editedItem.day, this.editedItem.month, topics_for_save, this.editedItem.date_rule, this.editedItem.description)
                                    .then(response => {
                                        this.saved(response.data);
                                        this.loading = false;
                                        this.$store.dispatch('setMessage', 'Das Ereignis "' + this.editedItem.name + '" wurde erfolgreich geändert.');
                                    })
                                    .catch(err => {
                                            this.close();
                                            this.loading = false;
                                            console.log(err);
                                            this.$store.dispatch('setMessage', 'Es ist leider ein Fehler aufgetreten.');
                                        }
                                    );
                            }
                        })
                }
            },
            saved(item) {
                this.$emit('saved', item);
            },
            close() {
                this.$emit('close', this.editedItem);
            },
            reset() {
                this.$refs.form.reset()
            },
            increment () {
                this.editedItem.day = parseInt(this.editedItem.day,10) + 1
            },
            decrement () {
                this.editedItem.day = parseInt(this.editedItem.day,10) - 1
            },
            async fetchEventTopics() {
                this.loading = true;
                const {data} = await EventsRepository.getEventTopics();
                this.eventTopics = data;
                this.loading = false;
            },
        },
        created() {
            this.fetchEventTopics();
        },
    }
</script>

<style scoped>

</style>
