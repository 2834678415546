import Repository from "./Repository";

const resource = "/configs";

export default {
    get(key){
        return Repository.get(`${resource}/${key}/`);
    },
    getAll(){
        return Repository.get(`${resource}/`);
    },
}