<template>
    <v-dialog v-model="dialogDelete" max-width="350px" @click:outside="closeDelete" @keydown.esc="closeDelete">
        <v-card v-if="dialogDelete">
            <v-card-title>{{ dialogTitle }}</v-card-title>
            <v-card-text>
                Dieser Eintrag "{{ article.title }}" von {{ article.writer_name }} wird mit dem Klicken auf "Löschen" unwiederbringbar gelöscht. Auch die gesamte Historie zu diesem Beitrag wird entfernt.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="primary" elevation="0" @click="deleteItemConfirm">Löschen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";
    const ArticlesRepository = RepositoryFactory.get('articles');

    export default {
        name: "DialogArticleDelete",
        props: {
            dialogDelete: Boolean,
            index: Number,
            article: Object,
            dialogTitle: String,
        },
        methods: {
            async deleteItemConfirm () {
                const response = await ArticlesRepository.delete(this.article.id);
                if (response.status === 204) {
                    await this.$store.dispatch('setMessage', 'Der Beitrag "' + this.article.title + '" wurde gelöscht.');
                    this.$emit('deleteArticleConfirm', this.index);
                } else {
                    await this.$store.dispatch('setMessage', 'Es ist ein Fehler aufgetreten.');
                    this.$emit('closeDelete', this.index);
                }
            },
            closeDelete () {
                this.$emit('closeDelete', this.index);
            }
        }
    }
</script>

<style scoped>

</style>
