<template>
    <v-card class="flex flex-column my-3 mb-6">
        <v-toolbar color="primary" dark class="elevation-0">
            <v-btn icon class="mr-4">
                <v-icon>mdi-book-cross</v-icon>
            </v-btn>

            <v-toolbar-title>Aktuelles Projekt</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>

        <v-progress-linear
                v-model="progress"
                color="accent darken-1"
                height="25"
        >
            <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}% Abgeschlossen</strong>
            </template>
        </v-progress-linear>

        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>Projekt</td>
                        <td>{{ project.name }} ({{ project.year }})</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Beiträge im Projekt</td>
                        <td>
                            {{ stats.project_article_count }}

                        </td>
                        <td>
                            <span v-if="stats.project_article_count >= stats.project_day_count">
                                <v-icon color="green darken-1">
                                    mdi-check-circle
                                </v-icon>
                            </span>
                            <span v-else>({{ stats.project_day_count - stats.project_article_count }} fehlen)</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Status "Überarbeitet"</td>
                        <td>
                            {{ stats.project_article_revised }}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status "Freigegeben"</td>
                        <td>
                            {{ stats.project_article_approved }}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Status "Finalisiert"</td>
                        <td>
                            {{ stats.project_article_finalized }}
                        </td>
                        <td>
                        <span v-if="stats.project_article_finalized >= stats.project_day_count">
                            <v-icon color="green darken-1">mdi-check-circle</v-icon>
                        </span>
                            <span v-else>({{ stats.project_day_count - stats.project_article_finalized }} fehlen)</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Beiträge mit Datum</td>
                        <td>
                            {{ stats.project_article_date_assigned }}
                        </td>
                        <td>
                            <span v-if="stats.project_article_date_assigned === stats.project_day_count">
                                <v-icon color="green darken-1">mdi-check-circle</v-icon>
                            </span>
                            <span v-else>({{ stats.project_day_count - stats.project_article_date_assigned }} fehlen)</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Themenbereiche</td>
                        <td>
                            <span v-if="topics.length === 0 || (topics.length === 1 && topics[0].topic_count === 0)">
                                0
                            </span>
                            <span v-else>{{ stats.topics.length }}</span>
                        </td>
                        <td>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="isAdmin" text elevation="0" to="/project">
                Mehr Informationen
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "WidgetCurrentProject",
        props: {
            articleStats: Object,
            stats: Object,
            loading: Boolean,
            progress: Number,
            project: Object,
            topics: Array,
            isAdmin: Boolean,
        },
    }
</script>

<style scoped>

</style>
