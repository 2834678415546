<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card class="elevation-0">
                <v-toolbar dense color="accent" class="elevation-0">

                    <v-toolbar-title>Ereignisse</v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                            <v-btn v-bind="tooltipAttrs"
                                   v-on="tooltip"
                                   @click="editItem()"
                                   absolute dark fab bottom right color="primary">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Ereignis erstellen</span>
                    </v-tooltip>
                </v-toolbar>

                <v-card-title>
                    <v-text-field
                        style="max-width: 250px"
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Suchen"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>

                <v-data-table
                        :loading="loading"
                        loading-text="Daten werden geladen... Bitte warten"
                        :headers="headers"
                        :items="computedEventDays"
                        :search="search"
                        :footer-props="{
                            'items-per-page-options': [10, 25, 50, -1]
                        }"
                        :items-per-page="25"
                >
                    <template v-slot:item.fix="{ item }">
                        <v-chip small class="mr-1" v-if="item.fix">
                            fix
                        </v-chip>
                        <v-chip small class="mr-1" v-else>
                            flex
                        </v-chip>
                    </template>

                    <template v-slot:item.sort_date="{ item }">
                        <template v-if="item.fix">
                            {{ String(item.day).padStart(2, '0') }}.{{ String(item.month).padStart(2, '0') }}.
                        </template>
                        <template v-else>
                            <v-btn v-if="item.year_specific.length > 0" small elevation="0" @click="showDates(item.id)">
                                {{ item.year_specific.length }} Termine hinterlegt
                                <v-icon right v-if="datesToShow.includes(item.id)">mdi-chevron-up</v-icon>
                                <v-icon right v-else>mdi-chevron-down</v-icon>
                            </v-btn>
                            <v-btn v-else small elevation="0" disabled>
                                keine Termine hinterlegt
                                <v-icon right>mdi-close</v-icon>
                            </v-btn>
                            <dl v-if="datesToShow.includes(item.id)">
                                <dt v-for="(element, index) in item.year_specific" :key="index">
                                    {{ element.year }} &rArr; {{ String(element.day).padStart(2, '0') }}.{{ String(element.month).padStart(2, '0') }}.
                                </dt>
                            </dl>
                        </template>
                    </template>

                    <template v-slot:item.exists="{ item }">
                        <v-chip small class="mr-1" v-if="getEventDateOfYear(item.year_specific, yearToCheck) || item.fix">
                            <v-icon left small color="green darken-1">mdi-check-circle</v-icon>
                            {{ yearToCheck }}
                        </v-chip>
                        <v-chip small class="mr-1" v-else>
                            <v-icon left small color="red darken-1">mdi-close-circle</v-icon>
                            {{ yearToCheck }}
                        </v-chip>
                    </template>

                    <template v-slot:item.topics_list="{ item }">
                        <v-chip v-for="(element, index) in item.topics_data" :key="index" small class="mr-1">{{ element.name }}</v-chip>
                    </template>
                    <template v-slot:top>
                        <DialogEvent
                                :dialog="dialog"
                                :editedItem="editedItem"
                                :formTitle="formTitle"
                                :editedIndex="editedIndex"
                                @saved="saved"
                                @close="close"
                        />
                        <DialogEventYearSpecific
                                :dialog="dialogYearSpecific"
                                :editedItem="editedItem"
                                :editedIndex="editedIndex"
                                @savedYearSpecific="savedYearSpecific"
                                @close="closeYearSpecific"
                        />
                    </template>
                    <template v-slot:item.id="{ item }">
                        <v-tooltip left>
                            <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
                                <v-icon v-bind="tooltipAttrs"
                                        v-on="tooltip"
                                        class="mr-2"
                                        @click="editItemYearSpecific(item)"
                                        v-if="item.fix === false">
                                    mdi-calendar-month-outline
                                </v-icon>
                            </template>
                        <span>variable Ereignisse konfigurieren</span>
                        </v-tooltip>
                        <v-icon class="mr-2" @click="editItem(item)">
                            mdi-square-edit-outline
                        </v-icon>
<!--                        <v-icon @click="deleteItem(item)" >-->
<!--                            mdi-delete-outline-->
<!--                        </v-icon>-->
                    </template>
                </v-data-table>
            </v-card>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import { RepositoryFactory } from "@/repositories/RepositoryFactory";
    import DialogEvent from "@/components/settings/DialogEvent";
    import DialogEventYearSpecific from "@/components/settings/DialogEventYearSpecific";

    const EventsRepository = RepositoryFactory.get('events');

    export default {
        name: "Events",
        components: {
            MenuSetDefault,
            DialogEvent,
            DialogEventYearSpecific,
        },
        props: {
            source: String,
        },
        data() {
            return {
                name: '',
                fix: '',
                day: '',
                month: '',
                topics: '',
                search: '',
                eventDays: [],
                loading: true,
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        value: 'name',
                    },
                    {text: 'Festes Datum', value: 'fix'},
                    {text: 'Datum', value: 'sort_date'},
                    {text: 'Vorhanden', value: 'exists'},
                    {text: 'Themen', value: 'topics_list'},
                    {text: 'Aktionen', value: 'id', align: 'right'},
                ],
                dialog: false,
                dialogYearSpecific: false,
                editedIndex: -1,
                editedItem: {
                    id: null,
                    fix: '',
                    day: '',
                    month: '',
                    topics: '',
                    topics_data: [],
                },
                defaultItem: {
                    id: null,
                    name: '',
                    fix: true,
                    day: '',
                    month: '',
                    topics: '',
                    topics_data: [],
                },
                datesToShow: [],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await EventsRepository.get();
                this.eventDays = data;
                this.loading = false;
            },
            editItem (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.computedEventDays.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialog = true
            },
            editItemYearSpecific (item) {
                if (item === undefined) {
                    this.editedItem = Object.assign({}, this.defaultItem)
                } else {
                    this.editedIndex = this.computedEventDays.indexOf(item);
                    this.editedItem = Object.assign({}, item)
                }
                this.dialogYearSpecific = true
            },
            saved (editedItem) {
                if (this.editedIndex > -1) {
                    Object.assign(this.eventDays[this.editedIndex], editedItem)
                } else {
                    this.eventDays.push(editedItem);
                }
                this.close()
            },
            savedYearSpecific (editedItem) {
                Object.assign(this.eventDays[this.editedIndex], editedItem)
            },
            deleteItem (item) {
                this.editedIndex = this.computedEventDays.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true
            },
            deleteItemConfirm (editedIndex) {
                this.eventDays.splice(editedIndex, 1);
                this.closeDelete()
            },
            close () {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeYearSpecific () {
                this.dialogYearSpecific = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            closeDelete () {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                })
            },
            getEventDateOfYear(eventDays, year) {
                return eventDays.filter(date => date.year === year).length > 0;
            },
            showDates(id) {
                if (this.datesToShow.includes(id)) {
                    this.$delete(this.datesToShow, this.datesToShow.indexOf(id))
                } else {
                    this.datesToShow.push(id);
                }
            },
        },
        created() {
            this.fetch();
        },
        computed: {
            computedEventDays() {
                return this.eventDays.map(item => {
                    let topics_list = item.topics_data.map(topic => {return topic.name});
                    return {
                        ...item,
                        sort_date: item.month+'-'+item.day,
                        topics_list: topics_list
                    }
                })
            },
            formTitle () {
                return this.editedIndex === -1 ? 'Neues Ereignis anlegen' : 'Ereignis bearbeiten'
            },
            yearToCheck() {
                return this.$store.state.currentProjectMeta ? this.$store.state.currentProjectMeta.year : new Date().getFullYear();
            }
        },
    }
</script>
