import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import de from 'vuetify/lib/locale/de'

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#c01500',
                secondary: '#b0bec5',
                accent: '#f4f4f4',
                error: '#b71c1c',
            },
            dark: {
                primary: '#c01500',
                secondary: '#b0bec5',
                accent: '#363636',
                error: '#b71c1c',
            },
        },
    },
});
