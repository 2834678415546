<template>
  <v-dialog v-model="dialog" persistent max-width="850px" :fullscreen="$vuetify.breakpoint.xsOnly"
            @click:outside="closeDialog" @keydown.esc="closeDialog">
    <v-card>
      <v-card-title>
        <span class="headline">Über diese Anwendung</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <h3>Allgemein</h3>
          <p>
            Die "Leben ist mehr" Redaktions-App ist eine Single Page Application (SPA), die über eine REST API mit
            einem Backend verbunden ist.
            Das erlaubt ein sehr flexible Weiterentwicklung und Möglichkeiten zur Anbindung von anderen
            Anwendungen.
          </p>

          <h3>REST API Dokumentation</h3>
          <p>
            Die Dokumentation der LIM Schnittstelle ist unter
            <a target="_blank" href="https://api.lim.clv.de/redoc/">https://api.lim.de/redoc/</a> zu finden.
            Für die Dokumentation mit Swagger rufen Sie bitte <a target="_blank"
                                                                 href="https://api.lim.clv.de/swagger/">https://api.lim.clv.de/swagger/</a>
            auf. Um die Dokumentationen aufrufen zu können, müssen Sie Ihre Zugangsdaten erneut eingeben.
          </p>

          <h3>Git Repository zur Versionsverwaltung</h3>
          <p>
            Die Entwicklung und Versionsverwaltung wird über ein privates GitLab Repository organisiert.
            <a target="_blank" href="https://gitlab.com/einz/clv-lim-redaktion/">https://gitlab.com/einz/clv-lim-redaktion/</a>.
            Das ermöglicht das gemeinsame Entwickeln durch mehrere Entwickler, Dokumentiert die Entwicklung
            der Anwendung, und führt zu mehr Übersicht und einer besseren Qualität des Quellcodes durch Code-Reviews.
          </p>

          <h3>Changelog</h3>
          <v-divider></v-divider>

          <h4 class="mt-2 mb-1">v1.0.0 - 01.02.2021</h4>
          <ul>
            <li>
              Initiale Version der "Leben ist mehr" Redaktion App mit dem Basisumfang der
              Vorgänger-Version.
            </li>
            <li>Bereitstellung der Rest API</li>
            <li>Erstellen der Schnittstellen-Dokumentation</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.1 - 09.02.2021</h4>
          <ul>
            <li>Liste von Beiträgen eines Autors</li>
            <li>Vervollständigung der Dokumentation der API</li>
            <li>Anzeige der Projektauswahl auf dem Dashboard</li>
            <li>Letzte Beiträge Widget auf Dashboard</li>
            <li>Änderbarkeit der Nutzer-Rollen durch Administratoren</li>
            <li>Optimierung der Ereignis-Tabelle</li>
            <li>Behebung kleinerer Fehler</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.2 - 26.02.2021</h4>
          <ul>
            <li>Autorenzuordnung im Dialog bei Erstellung durch Lektoren/Admins</li>
            <li>Prüfung der Kürzel auf Einmaligkeit bei Erstellung/Änderung von Nutzern</li>
            <li>Erweiterte Auswertungen in der Projektübericht</li>
            <li>Zusätzliche Exports für Benutzer und Artikel</li>
            <li>Beiträge sind nun durch Lektoren/Admins dauerhaft löschbar</li>
            <li>Optimierung der Kalenderansicht</li>
            <li>Anzahl der Beiträge in Benutzerliste</li>
            <li>Speichern von Tabellenoptionen (Filter/Sortierung/Suche)</li>
            <li>Einführung Status "Zurückgestellt"</li>
            <li>Beitrags-Status ist ohne Einschränkungen änderbar</li>
            <li>Implementierung Beitragslisten nach Autor</li>
            <li>Listenansichten optimiert</li>
            <li>Behebung eines Fehlers in der Verlaufsüberwachung</li>
            <li>Suche nach Autoren ermöglicht</li>
            <li>Optimierung Excel Export</li>
            <li>Entfernung der Datumzuordnung von Beiträgen ermöglicht</li>
            <li>WYSIWYG Editor für Beitrags Texte</li>
            <li>Automatische Zeichenersetzung</li>
            <li>Ereignis-Beschreibung als Text Area</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.3 - 01.03.2021</h4>
          <ul>
            <li>Bibelübersetzungen für Lektoren Optional</li>
            <li>Meldung über fehlende Daten bei Beitragsanlage</li>
            <li>Import der Bibelübersetzungen</li>
            <li>Fehlerbehebung bei der Ereigniszuordnung</li>
            <li>Beiträge können von Projekten gelöst werden</li>
            <li>Fehlerbehebung in der Zeichenberechnung von Beiträgen</li>
            <li>Änderung der Standardsortierung (Änderungsdatum)</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.4 - 15.03.2021</h4>
          <ul>
            <li>SMTP Relay über redaktion@lim.clv.de</li>
            <li>Zeilenanzahl in der Vorschau im Standard einblenden</li>
            <li>Erweiterung des Encodings für Excel Exports</li>
            <li>Erstellung Artikelansicht für Autoren</li>
            <li>Funktion zum Einfügen von Sonderzeichen</li>
            <li>Sonderzeichen-Ersetzung in allen Textfeldern</li>
            <li>Ermöglichen der Bearbeitung durch Autoren</li>
            <li>Platzhalter-Vers aus Vorschau entfernt</li>
            <li>Pflichtfelder sind bei der Beitragserstellung nun besser sichtbar</li>
            <li>Bibelserver Vergleich für Bibellese-Stelle</li>
            <li>Erweiterung der Jahreszahlen im Geburtstags-Feld</li>
            <li>Entfernung eines Leerzeichens im Bibelstellen-Parsers</li>
            <li>Behebung eines Fehlers beim Speichern von Ereignissen</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.5 - 17.03.2021</h4>
          <ul>
            <li>Target URL redirect nach Login</li>
            <li>Anzeige der letzten Aktivität in Beitragslisten</li>
            <li>Anpassung der API</li>
            <li>Überprüfung des Kürzels im Formular</li>
            <li>Möglichkeit zum Zwischenspeichern von Beiträgen</li>
            <li>Behebung kleiner Fehler</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.6 - 05.05.2021</h4>
          <ul>
            <li>Behebung von Schreibfehlern</li>
            <li>Automatisieren der Weiterleitung nach Login</li>
            <li>Verbesserung der Darstellung der Artikel-Vorschau</li>
            <li>Beheben eines Fehlers beim Speichern von festen Ereignissen</li>
            <li>Implementierung des "Zurückgestellt" Status</li>
            <li>Zeichenersetzung in allen Feldern der Artikelbearbeitung</li>
            <li>In der Bearbeitungs-Ansicht sind die Kommentare immer sofort sichtbar</li>
            <li>Leerzeilen werden nun auch in der Vorschau entsprechend dargestellt</li>
            <li>Benutzerdefinierte Angaben von Tagesvers Stellen sind nun möglich</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.7 - 06.05.2021</h4>
          <ul>
            <li>Autoren können Kommentare für Lektoren hinzufügen</li>
            <li>Es wurde ein Fehler bei der automatischen Zeichenersetzung behoben</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.8 - 21.05.2021</h4>
          <ul>
            <li>Anlass und Ereignis werden in den Listen und der Beitragsbearbeitung angezeigt</li>
            <li>Autoren können ihren Kommentar ansehen und ändern</li>
            <li>Zeichenanzahl in Listen anzeigen</li>
            <li>Vorschau aller Beiträge eines Jahres/Projektes und Druck/PDF</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.10 - 26.05.2021</h4>
          <ul>
            <li>Die Projektstatistik wurde um zwei Statusfelder erweitert</li>
            <li>Beiträge können über die Kalenderansicht einem Datum zugeordnet werden</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.11 - 17.09.2021</h4>
          <ul>
            <li>Erweiterung des Autorenexports um die Anschrift zur Vereinfachung des Belegversandes</li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.12 - 31.05.2022</h4>
          <ul>
            <li>Artikelauswahl für Druckvorschau (Einzeln, Alle oder Mehrere mit
              <v-chip small>Shift</v-chip>
              +
              <v-chip small>Klick</v-chip>
              )
            </li>
            <li>Sortierung der Druckvorschau nach ID wenn kein Datum vorhanden</li>
            <li>Beitragsbearbeitung aus Kalendervorschau heraus</li>
            <li>Direkte Monatsauswahl im Kalender zusätzlich zur Pfeil-Navigation</li>
            <li>Update
              <v-chip small>Vuetify ^2.6.6</v-chip>
            </li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.13 - 04.05.2023</h4>
          <ul>
            <li>Implementierung des neuen Drucklayout für den Vorschau-Modus</li>
            <li>Behebung eines Weiterleitungs-Fehlers nach dem Login</li>
            <li>Anzeige der Bibelübersetzungsnamen im Auswahlfeld</li>
            <li>Vorschlag der Bibelserver URL nur bei existierenden Übersetzungen</li>
            <li>Behebung eines Fehlers beim Versenden von Mails an Autoren</li>
            <li>Update
              <v-chip small class="mr-1">Vuetify ^2.6.15</v-chip>
              <v-chip small class="mr-1">sass ^1.62.1</v-chip>
              <v-chip small class="mr-1">core-js ^3.30.1</v-chip>
              <v-chip small class="mr-1">uvm.</v-chip>
            </li>
          </ul>

          <h4 class="mt-2 mb-1">v1.0.14 - t.b.d</h4>
          <ul>
            <li>Bibel Daten in verschiedenen Übersetzungen in Artikelbearbeitung</li>
            <li>Zusatzinformationen für Autoren auf Dashboard</li>
          </ul>

          <v-divider class="my-3"></v-divider>

          <p>
            <b>einz consulting GmbH</b><br>
            Meiereiweg 5a<br>
            23847 Meddewade<br>
            <v-icon small>mdi-email</v-icon>
            <a href="mailto:info@einz.de"> info@einz.de</a><br>
            <v-icon small>mdi-deskphone</v-icon>
            +49 (0) 4533 6 100 970
          </p>
          <p>
            made with
            <v-icon>mdi-heart</v-icon>
            by <a target="_blank" href="https://einz.de">einz</a>
          </p>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="0" @click="closeDialog">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "DialogAbout",
  props: {
    dialog: Boolean,
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
  }
}
</script>

<style scoped>

</style>
