<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-information</v-icon>
            </v-btn>

            <v-toolbar-title>Informationen</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>
        <v-skeleton-loader
                :loading="loading"
                type="list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
        >

            <v-list two-line>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-draw</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-if="!!profile.short">{{ profile.short }}</v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle>Initialien/Kürzel</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-cake-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-if="!!profile.birthday">
                            {{ profile.birthday | luxon }}
                            ({{ calculate_age(new Date(profile.birthday)) }})
                        </v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle>Geburtstag</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset v-if="this.isAdmin"></v-divider>

                <v-list-item v-if="this.isAdmin">
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-church</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-if="!!profile.church_name">{{ profile.church_name }}</v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle>Gemeinde</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="primary">{{ stateIcons[profile.status] }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title v-if="!!profile.status">{{ profile.status_name }}</v-list-item-title>
                        <v-list-item-title v-else>-</v-list-item-title>
                        <v-list-item-subtitle>Status</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon v-if="this.isAdmin">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="statusDialog = true"
                                >
                                    <v-icon>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <span>Status ändern</span>
                        </v-tooltip>
                        <UserStatusDialog
                                :assignedStatus="profile.status"
                                :userId="profile.id"
                                :dialog="statusDialog"
                                @statusUpdated="statusUpdated"
                                @closeDialog="closeStatusDialog"
                        />
                    </v-list-item-icon>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="primary">mdi-account-details-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>
                        <span v-if="profile.groups_data !== undefined && profile.groups_data.length">
                            <v-chip v-for="(element, index) in profile.groups_data" :key="index" small
                                    class="mr-1">
                                {{ element.name }}
                            </v-chip>
                        </span>
                            <span v-else>Noch keine Berechtigung</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>Rollen</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon v-if="this.isAdmin">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="groupDialog = true"
                                >
                                    <v-icon>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <span>Berechtigung ändern</span>
                        </v-tooltip>
                        <UserGroupDialog
                                :assignedGroups="profile.groups_data"
                                :dialog="groupDialog"
                                :userId="profile.id"
                                @closeDialog="closeStatusDialog"
                                @groupsUpdated="groupsUpdated"
                        />
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-skeleton-loader>
    </v-card>
</template>

<script>
    import iconMixin from '@/mixins/iconMixin'
    import permissionMixin from '@/mixins/permissionMixin'
    import UserGroupDialog from "@/components/users/UserGroupDialog";
    import UserStatusDialog from "@/components/users/UserStatusDialog";

    export default {
        name: "UserInformationCard",
        components: {
            UserGroupDialog,
            UserStatusDialog,
        },
        props: {
            profile: Object,
            loading: Boolean,
            myProfile: Boolean,
        },
        data: () => ({
            groupDialog: false,
            statusDialog: false,
        }),
        mixins: [iconMixin, permissionMixin],
        methods: {
            calculate_age(dob) {
                let diff_ms = Date.now() - dob.getTime();
                let age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            },
            statusUpdated(new_data) {
                if (new_data) {
                    this.profile.status = new_data.status;
                    this.profile.status_name = new_data.status_name;
                    this.$store.dispatch('setMessage', 'Der Status wurde erfolgreich auf "' + new_data.status_name + '" geändert.');
                } else {
                    this.$store.dispatch('setMessage', 'Beim Ändern des Status gab es ein Problem.');
                }
                if (this.myProfile) {
                    this.$store.dispatch('getUserData')
                }
                this.closeStatusDialog();
            },
            groupsUpdated(new_data) {
                console.log(new_data);
            },
            closeStatusDialog() {
                this.statusDialog = false;
                this.groupDialog = false;
            }
        },
    }
</script>

<style scoped>

</style>
