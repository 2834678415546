<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <DialogChangePassword
                :dialog="dialogPassword"
                :user="profile"
                @close="close"
        />

        <DialogUser
                v-if="profile.id !== undefined"
                :dialog="dialogUser"
                :editedItem="profile"
                formTitle="Profil bearbeiten"
                :editedIndex="profile.id"
                @close="close"
        />

        <v-main>
                <v-card class="elevation-0">
                    <v-toolbar dense color="accent" class="elevation-0">
                        <v-btn icon @click="$router.go(-1)">
                            <v-icon color="primary">mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-toolbar-title>Profil {{ profile.first_name }} {{ profile.last_name }}</v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-tooltip bottom v-if="this.isAdmin">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       v-on="on"
                                       icon
                                       @click="changePassword()"
                                >
                                    <v-icon>mdi-lock-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Passwort ändern</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="this.isAdmin">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click="changeUser()">
                                    <v-icon>
                                        mdi-account-edit-outline
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Profil bearbeiten</span>
                        </v-tooltip>
                    </v-toolbar>
                </v-card>

            <UserProfile :loading="loading" :profile="profile" :userId="userId"/>

        </v-main>
    </v-app>
</template>

<script>
    import MenuSetDefault from '@/components/layout/MenuSetDefault'
    import UserProfile from '@/components/users/UserProfile';
    import DialogChangePassword from '@/components/users/DialogChangePassword';
    import DialogUser from "@/components/users/DialogUser";
    import {RepositoryFactory} from '@/repositories/RepositoryFactory';
    import permissionMixin from '@/mixins/permissionMixin'

    const UsersRepository = RepositoryFactory.get('users');

    export default {
        name: "Profile",
        components: {
            MenuSetDefault,
            UserProfile,
            DialogChangePassword,
            DialogUser,
        },
        mixins: [permissionMixin],
        data() {
            return {
                profile: {},
                loading: true,
                dialogPassword: false,
                dialogUser: false,
                userId: parseInt(this.$route.params.userId),
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const {data} = await UsersRepository.getUser(this.userId);
                this.profile = data;
                this.loading = false;
            },
            changePassword() {
                this.dialogPassword = true
            },
            changeUser() {
                this.dialogUser = true
            },
            close () {
                this.fetch();
                this.dialogPassword = false;
                this.dialogUser = false;
            },
        },
        created() {
            if (parseInt(this.$route.params.userId) !== this.$store.state.user.id) {
                this.fetch();
            } else {
                this.$router.push({ path: '/me' });
            }
        },
        watch: {
            '$route.params.userId': function () {
                this.fetch();
            },
            '$store.state.user': function () {
                this.fetch();
            },

        },
    }
</script>

<style scoped>
    .header-action-icon {
        float: right;
        margin-top: -3px;
        margin-right: 10px;
    }
</style>
