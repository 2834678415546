<template>
    <v-dialog v-model="dialog" max-width="500px" @click:outside="close" @keydown.esc="close" :fullscreen="$vuetify.breakpoint.xsOnly">
        <v-card :loading="loading">
            <template slot="progress">
                <v-progress-linear background-color="primary" color="white" indeterminate bottom></v-progress-linear>
            </template>
            <v-card-title>
                <span class="headline" v-if="user">Passwort von {{ user.full_name }} ändern</span>
                <span class="headline" v-else>Passwort ändern</span>
            </v-card-title>
            <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" v-if="!user">
                                <v-text-field
                                    v-model="old_password"
                                    name="old_password"
                                    label="Aktuelles Passwort*"
                                    :rules="[rules.required_old]"
                                    :type="show1 ? 'text' : 'password'"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show1 = !show1"
                                    required
                                    filled
                                    tabindex="1"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="new_password"
                                        name="new_password"
                                        label="Neues Passwort*"
                                        :rules="[rules.required_new, rules.min]"
                                        hint="Mindestens 6 Zeichen"
                                        :type="show2 ? 'text' : 'password'"
                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="show2 = !show2"
                                        required
                                        filled
                                        tabindex="2"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        v-model="new_password_check"
                                        name="new_password_check"
                                        label="Neues Passwort*"
                                        :rules="[rules.required_new_check, rules.passwordMatch]"
                                        :type="show3 ? 'text' : 'password'"
                                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append="show3 = !show3"
                                        required
                                        filled
                                        tabindex="3"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Schließen</v-btn>
                    <v-btn color="primary" elevation="0" @click="save">Speichern</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const UsersRepository = RepositoryFactory.get('users');
    const ProfileRepository = RepositoryFactory.get('profile');

    export default {
        name: "DialogChangePassword",
        props: {
            dialog: Boolean,
            user: Object,
        },
        data() {
            return {
                loading: false,
                valid: true,
                old_password: '',
                new_password: '',
                new_password_check: '',
                show1: false,
                show2: false,
                show3: false,
                rules: {
                    required_old: value => !!value || 'Bitte geben Sie das aktuelle Passwort ein.',
                    required_new: value => !!value || 'Geben Sie ein neues Passwort ein.',
                    required_new_check: value => !!value || 'Bitte bestätugen Sie das neue Passwort.',
                    min: v => v.length >= 6 || 'Geben Sie bitte mindestens 6 Zeichen ein.',
                    passwordMatch: v => ( v === this.new_password) || 'Die Eingaben des neuen Passworts stimmt nicht überein.',
                },
            }
        },
        methods: {
            async save() {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    if (!this.user) {
                        ProfileRepository.changePassword(this.old_password, this.new_password)
                            .then(response => {
                                this.loading = false;
                                if (response.data.success) {
                                    this.close(response);
                                    this.$store.dispatch('setMessage', 'Ihr Passwort wurde erfolgreich geändert.');
                                }
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    this.$store.dispatch('setMessage', "Es ist ein Fehler (" + err.response.status + ") aufgetreten.<br>" + JSON.stringify(err.response.data.errors.message));
                                }
                            );
                    } else {
                        UsersRepository.resetPassword(this.user.id, this.new_password)
                            .then(response => {
                                this.loading = false;
                                if (response.data.success) {
                                    this.close(response);
                                    this.$store.dispatch('setMessage', 'Das Passwort wurde erfolgreich geändert.');
                                }
                            })
                            .catch(err => {
                                    this.close();
                                    this.loading = false;
                                    this.$store.dispatch('setMessage', "Es ist ein Fehler (" + err.response.status + ") aufgetreten.<br>" + JSON.stringify(err.response.data.errors.message));
                                }
                            );
                    }
                }
            },
            close() {
                this.$emit('close', this.editedItem);
            },
        },
    }
</script>

<style scoped>

</style>
