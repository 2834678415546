<template>
    <v-app id="inspire">

        <MenuSetDefault/>

        <v-main>
            <v-card flat tile class="elevation-0" :loading="loading">

                <v-toolbar dense color="accent" class="elevation-0">
                    <v-toolbar-title v-if="$refs.calendar">
                      <span class="mr-2">
                        {{ $refs.calendar.title }}
                      </span>
                      <v-chip
                          small
                          class="mr-1"
                          v-for="month in months" :key="month.number"
                          @click="move(month.number)"
                          :disabled="calendarMonth === month.number"
                      >
                        {{ month.name }}
                      </v-chip>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn fab text small color="primary" @click="prev" :disabled="calendarMonth === 1">
                        <v-icon color="primary">mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn fab text small color="primary" @click="next" :disabled="calendarMonth === 12">
                        <v-icon color="primary">mdi-chevron-right</v-icon>
                    </v-btn>

                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs"
                                   v-on="on"
                                   @click="showUnassigned = !showUnassigned"
                                   icon
                            >
                                <v-icon>mdi-calendar-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Beiträge zuordnen</span>
                    </v-tooltip>

                </v-toolbar>

                <template slot="progress">
                    <v-progress-linear background-color="primary" color="white" indeterminate
                                       bottom></v-progress-linear>
                </template>

                <v-container fluid>
                    <v-row class="fill-height">
                        <v-col class="pa-0">
                            <v-sheet height="800">
                                <v-calendar
                                        ref="calendar"
                                        :start="projectStart"
                                        :end="projectEnd"
                                        v-model="focus"
                                        color="primary"
                                        :events="articles"
                                        :event-color="getEventColor"
                                        :type="type"
                                        @click:event="previewItem"
                                        @click:date="selectDay"
                                        @click:day="selectDay"
                                >

                                    <template v-slot:event="props">
                                        <b class="text--primary ml-1"> {{ props.eventParsed.input.title }}</b>
                                        <br>
                                        <v-icon x-small class="mx-1 mb-1" color="white">mdi-book-cross</v-icon>
                                        {{ props.eventParsed.input.verse }}
                                        <br>
                                        <v-icon x-small class="mx-1 mb-1" color="white">mdi-shape</v-icon>
                                        {{ props.eventParsed.input.topic_name }}
                                        <br>
                                        <v-icon x-small class="mx-1 mb-1" color="white">mdi-account</v-icon>
                                        {{ props.eventParsed.input.writer_name }}
                                    </template>

                                </v-calendar>
                            </v-sheet>
                        </v-col>
                        <v-col v-if="showUnassigned" class="pa-0">

                            <v-toolbar dense color="accent" class="elevation-0">
                                <v-text-field style="max-width: 250px"
                                              v-model="search"
                                              append-icon="mdi-magnify"
                                              label="Suchen"
                                              single-line
                                              hide-details
                                ></v-text-field>

                                <v-spacer></v-spacer>

                                <v-tooltip left
                                           v-if="articles.filter(a => a.assigned_date === selectedDate).length !== 0">
                                    <template v-slot:activator="{ on, attrs}">
                                        <v-btn color="primary"
                                               @click="assignDate(articles.filter(a => a.assigned_date === selectedDate)[0], false)"
                                               v-bind="attrs" v-on="on" elevation="0" class="my-1">
                                            Zuordnung '{{ selectedDate | luxon("d. LLL") }}' löschen
                                        </v-btn>
                                    </template>
                                    <span>Datumszuordnung entfernen</span>
                                </v-tooltip>

                            </v-toolbar>

                            <v-data-table
                                    dense
                                    :headers="headers"
                                    :search="search"
                                    :items="articlesWithoutDate"
                                    :loading="loading"
                                    loading-text="Daten werden geladen... Bitte warten"
                                    class="mt-2"
                                    :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
                            >
                                <template v-slot:item.id="{ item }">
                                    <small>{{ item.id }}</small>
                                </template>

                                <template v-slot:item.status_name="{ item }">
                                    <small>{{ item.status_name }}</small>
                                </template>

                                <template v-slot:item.title="{ item }">
                                    <v-list-item class="pl-0">
                                        <v-list-item-content>
                                            <v-list-item-title><small>{{ item.title }}</small></v-list-item-title>
                                            <v-list-item-subtitle><small>{{ item.writer_name }}</small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:item.topic_name="{ item }">
                                    <v-list-item class="pl-0">
                                        <v-list-item-content>
                                            <v-list-item-title><small v-if="item.topic_name">{{ item.topic_name }}</small></v-list-item-title>
                                            <v-list-item-subtitle>
                                                <small>{{ item.event_day_name }}</small>
                                                <small v-if="item.event_day_topic_name && item.event_day_name"> | </small>
                                                <small>{{ item.event_day_topic_name }}</small>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>

                                <template v-slot:item.action="{ item }">
                                    <v-tooltip left v-if="selectedDate">
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn
                                                    @click="assignDate(item, selectedDate)"
                                                    :disabled="articles.filter(a => a.assigned_date === selectedDate).length !== 0"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    elevation="0"
                                                    class="my-1"
                                            >
                                                {{ selectedDate | luxon("d. LLL") }}
                                            </v-btn>
                                        </template>
                                        <span>Beitrag zuordnen</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on, attrs}">
                                            <v-btn icon @click="previewItemById(item.id)" v-bind="attrs" v-on="on">
                                                <v-icon>
                                                    mdi-eye-outline
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Vorschau</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-container>

                <DialogPreviewFull
                    :dialog="preview_dialog"
                    :article_id="preview_id"
                    edit_btn
                    @close="close"
                />
            </v-card>
        </v-main>
    </v-app>
</template>


<script>
  import MenuSetDefault from '@/components/layout/MenuSetDefault'
  import DialogPreviewFull from "@/components/articles/DialogPreviewFull";
  import {RepositoryFactory} from "@/repositories/RepositoryFactory";
  import bibleRefMixin from '@/mixins/bibleRefMixin'

  const ArticlesRepository = RepositoryFactory.get('articles');

  export default {
    name: "Calendar",
    components: {
      MenuSetDefault,
      DialogPreviewFull
    },
    mixins: [bibleRefMixin],
    data: () => ( {
      loading: false,
      focus: '',
      type: 'month',
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      projectStart: '2021-01-01',
      projectEnd: '2021-12-31',
      calendarYear: null,
      calendarMonth: 1,
      articles: [],
      articlesWithoutDate: [],
      project: null,
      preview_dialog: false,
      preview_id: null,
      showUnassigned: false,
      selectedDate: false,
      search: '',
      months: [
        {number: 1, name: 'JAN'},
        {number: 2, name: 'FEB'},
        {number: 3, name: 'MÄR'},
        {number: 4, name: 'APR'},
        {number: 5, name: 'MAI'},
        {number: 6, name: 'JUN'},
        {number: 7, name: 'JUL'},
        {number: 8, name: 'AUG'},
        {number: 9, name: 'SEP'},
        {number: 10, name: 'OKT'},
        {number: 11, name: 'NOV'},
        {number: 12, name: 'DEZ'},
      ],
      headers: [
        {text: 'ID', value: 'id'},
        {text: 'Status', value: 'status_name'},
        {text: 'Titel', value: 'title'},
        {text: 'Einordnung', value: 'topic_name'},
        {text: 'Anlass', value: 'event_day_name', align: ' d-none'},
        {text: 'Autor', value: 'writer_name', align: ' d-none'},
        {text: 'Anlass Thema', value: 'event_day_topic_name', align: ' d-none'},
        {text: 'Aktion', value: 'action', align: 'right'}
      ]
    } ),
    async created() {
      await this.currentProject();
      await this.fetchMonthlyArticles();
      await this.fetchArticlesWithoutDate();
    },
    mounted() {
      this.$refs.calendar.checkChange()
    },
    methods: {
      async currentProject() {
        this.project = this.$store.getters.currentProjectMeta;
        this.projectStart = this.project.year + '-01-01';
        this.projectEnd = this.project.year + '-12-31';
        this.calendarYear = this.project.year;
      },
      async fetchMonthlyArticles() {
        this.loading = true;
        const {data} = await ArticlesRepository.getByMonth(this.calendarYear, this.calendarMonth);
        this.articles = data.map(d => ( {
          ...d,
          start: d.assigned_date,
          end: d.assigned_date,
          color: 'secondary',
          verse: this.parseBibleRef(d.votd_reference, d.votd_reference_overwrite),
          timed: 0
        } ));
        this.loading = false;
      },
      async fetchArticlesWithoutDate() {
        this.loading = true;
        let {data} = await ArticlesRepository.getByProjectWithoutDate(this.project.id);
        this.articlesWithoutDate = data;
        this.loading = false;
      },
      getEventColor(event) {
        return event.color
      },
      async prev() {
        this.calendarMonth = this.calendarMonth - 1;
        await this.fetchMonthlyArticles();
        this.$refs.calendar.prev()
      },
      async next() {
        this.calendarMonth = this.calendarMonth + 1;
        await this.fetchMonthlyArticles();
        this.$refs.calendar.next()
      },
      async move(month) {
         let diff = month - this.calendarMonth
        this.calendarMonth = month
        await this.fetchMonthlyArticles();
        this.$refs.calendar.move(diff)
      },
      previewItem({event}) {
        this.preview_id = event.id;
        this.preview_dialog = true
      },
      previewItemById(id) {
        this.preview_id = id;
        this.preview_dialog = true
      },
      selectDay(day) {
        this.selectedDate = day.date;
      },
      async assignDate(article, date) {
        await ArticlesRepository.setAssignedDate(article.id, !date ? null : date);
        await this.fetchMonthlyArticles()
        await this.fetchArticlesWithoutDate()
      },
      close() {
        this.preview_dialog = false;
        this.preview_id = null;
      }
    }
  }
</script>

<style>
    .v-event {
        height: 80px !important;
    }
</style>


