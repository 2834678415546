<template>
    <v-card class="flex flex-column ma-4">
        <v-toolbar color="primary" dark>
            <v-btn icon class="mr-4">
                <v-icon>mdi-account-circle</v-icon>
            </v-btn>

            <v-toolbar-title>Stammdaten</v-toolbar-title>

            <v-spacer></v-spacer>

        </v-toolbar>

        <v-skeleton-loader
                :loading="loading"
                type="list-item-two-line, divider, list-item-two-line, list-item-two-line, divider, list-item-two-line, divider, list-item-two-line"
        >
        <v-list two-line>
            <v-list-item>
                <v-list-item-icon>
                    <v-icon v-if="!!profile.gender" color="primary">{{ genderIcons[profile.gender] }}</v-icon>
                    <v-icon v-else color="primary">mdi-account</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ profile.first_name }} {{ profile.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Benutzername: {{ profile.username }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="primary">mdi-phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title v-if="!!profile.tel_private">{{ profile.tel_private }}</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Privat</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-action></v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title v-if="!!profile.tel_business">{{ profile.tel_business }}</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle>Geschäftlich</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon class="mt-2" color="primary">mdi-email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ profile.email }}</v-list-item-title>
                    <v-list-item-subtitle>Account</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon v-if="!myProfile">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" icon @click="emailDialog = true">
                                <v-icon>mdi-email-outline</v-icon>
                            </v-btn>
                        </template>
                        <span>Email schreiben</span>
                    </v-tooltip>
                    <DialogSendMail @close="close" :dialog="emailDialog" :recipient="profile"/>
                </v-list-item-icon>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
                <v-list-item-icon>
                    <v-icon color="primary">mdi-map-marker</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title v-if="!!profile.street">{{ profile.street }}</v-list-item-title>
                    <v-list-item-title v-else>-</v-list-item-title>
                    <v-list-item-subtitle v-if="!!profile.zip_code && !!profile.city">{{ profile.zip_code }} {{ profile.city }}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-skeleton-loader>
    </v-card>
</template>

<script>
    import iconMixin from '@/mixins/iconMixin'
    import DialogSendMail from "@/components/users/DialogSendMail";

    export default {
        name: "UserAccountDataCard",
        components: {
            DialogSendMail,
        },
        props: {
            profile: Object,
            myProfile : Boolean,
            loading : Boolean,
        },
        mixins: [iconMixin],
        data() {
            return {
                emailDialog: false,
            }
        },
        methods: {
            close() {
                this.emailDialog = false;
            },
        },
    }
</script>

<style scoped>

</style>
