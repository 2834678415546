<template>
    <v-container fluid class="px-5">
        <v-row dense>
            <v-col cols="12" sm="12" md="4" class="d-flex flex-column">
                <UserAccountDataCard :loading="loading" :myProfile="myProfile" v-bind:profile="profile"/>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="d-flex flex-column">
                <UserInformationCard :loading="loading" :myProfile="myProfile" v-bind:profile="profile"/>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="d-flex flex-column">
                <UserStatisticCard :loading="loading" :myProfile="myProfile" :profile="profile" :userId="userId"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import UserAccountDataCard from "@/components/users/UserAccountDataCard";
    import UserInformationCard from "@/components/users/UserInformationCard";
    import UserStatisticCard from "@/components/users/UserStatisticCard";

    export default {
        name: "UserProfile",
        components: {
            UserAccountDataCard,
            UserInformationCard,
            UserStatisticCard,
        },
        props: {
            profile: Object,
            myProfile: Boolean,
            userId: Number,
            loading: Boolean,
        },
    }
</script>
