<template>
    <v-dialog v-model="dialog" persistent max-width="400px" :fullscreen="$vuetify.breakpoint.xsOnly" @click:outside="closeDialog" @keydown.esc="closeDialog">
<!--        <template v-slot:activator="{ on: dialog, attrs: dialogAttrs }">-->
<!--            <v-tooltip top>-->
<!--                <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">-->
<!--                    <v-btn-->
<!--                            icon-->
<!--                            v-bind="{...dialogAttrs, ...tooltipAttrs}"-->
<!--                            v-on="{...dialog, ...tooltip}"-->
<!--                    >-->
<!--                        <v-icon>mdi-cog</v-icon>-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--                <span>Status ändern</span>-->
<!--            </v-tooltip>-->
<!--        </template>-->
        <v-card>
            <v-card-title>
                <span class="headline">Benutzerstatus ändern</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-radio-group
                                v-model="selectedStatus">
                                <v-radio
                                v-for="status_option in status_options"
                                :key="status_option.key"
                                :label="status_option.value"
                                :value="status_option.key"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog()">Schließen</v-btn>
                <v-btn
                    color="primary"
                    elevation="0"
                    @click="updateStatus()"
                    :disabled = "assignedStatus === selectedStatus"
                    >Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {RepositoryFactory} from "@/repositories/RepositoryFactory";

    const UserRepository = RepositoryFactory.get('users');

    export default {
        name: "UserStatusDialog",
        props: {
            assignedStatus: String,
            userId: Number,
            dialog: Boolean,
        },
        data() {
            return {
                loading: false,
                selectedStatus: this.assignedStatus,
                status_options: {}
            }
        },
        methods: {
            async fetchStatusOptions() {
                const {data} = await UserRepository.getStatusOptions();
                this.status_options = Object.keys(data).map(key => {
                    return {'key': key, 'value': data[key]}
                });
            },
            async updateStatus() {
                const {data} = await UserRepository.updateStatus(this.userId, this.selectedStatus);
                this.$emit('statusUpdated', data);
            },
            closeDialog () {
                if(this.assignedStatus !== this.selectedStatus){
                    this.selectedStatus = this.assignedStatus;
                }
                this.$emit('closeDialog');
            }
        },
        created() {
            this.fetchStatusOptions();
        },
    }
</script>

<style scoped>

</style>
